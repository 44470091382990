import React, { ReactNode } from 'react'
import { Box } from '@mui/material'
import PageHeader from './PageHeader'
import VerticalNavigation from './VerticalNavigation'

interface SpecLayoutProps {
  children: ReactNode
}

const SpecLayout: React.FC<SpecLayoutProps> = ({ children }) => {
  return (
    <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
      <VerticalNavigation />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <PageHeader />
        <Box 
          component="main" 
          sx={{ 
            flexGrow: 1,
            overflow: 'hidden',
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export default SpecLayout