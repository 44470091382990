import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { JobInfo, XDisplayProperties } from '../types/types';

interface SessionJob {
  id: string;
  name: string;
  displayName: string | null;
  description: string | null;
}

interface JobContextType {
  selectedCustomer: string;
  selectedJob: string;
  selectedJobId: string | null;
  setSelectedCustomer: (customer: string) => void;
  setSelectedJob: (jobName: string) => void;
  jobInfo: JobInfo | null;
  setJobInfo: (info: JobInfo | null) => void;
  dProps: XDisplayProperties | null;
  setDProps: (props: XDisplayProperties | null) => void;
}

const JobContext = createContext<JobContextType | undefined>(undefined);

export const JobProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedJob, setSelectedJob] = useState('');
  const [selectedJobId, setSelectedJobId] = useState<string | null>(null);
  const [jobInfo, setJobInfo] = useState<JobInfo | null>(null);
  const [dProps, setDPropsState] = useState<XDisplayProperties | null>(() => {
    const savedDProps = localStorage.getItem('dProps');
    return savedDProps ? JSON.parse(savedDProps) : null;
  });

  useEffect(() => {
    if (!selectedCustomer || !selectedJob) return;
    
    const sessionInfoStr = localStorage.getItem('sessionInfo');
    if (!sessionInfoStr) {
      console.warn('No session info found in localStorage');
      return;
    }

    try {
      const sessionInfo = JSON.parse(sessionInfoStr);
      const customerData = sessionInfo?.sessionCusts?.find(
        (cust: any) => cust.name === selectedCustomer
      );
      
      console.log('Session info details:', {
        selectedCustomer,
        selectedJob,
        foundCustomer: customerData?.name,
        availableJobs: customerData?.sessionJobs?.map((job: SessionJob) => ({
          name: job.name,
          id: job.id,
          displayName: job.displayName
        }))
      });
      
      if (customerData?.sessionJobs) {
        const job = customerData.sessionJobs.find((job: SessionJob) => job.name === selectedJob);
        if (job) {
          console.log('Found matching job:', job);
          setSelectedJobId(job.id);
        } else {
          console.warn('No matching job found for customer. Available jobs:', 
            customerData.sessionJobs.map((j: SessionJob) => `${j.name} (${j.id})`).join(', ')
          );
          setSelectedJobId(null);
        }
      }
    } catch (error) {
      console.error('Error processing session info:', error);
      console.error('Session info content:', sessionInfoStr);
    }
  }, [selectedCustomer, selectedJob]);

  const handleSetSelectedJob = (jobName: string) => {
    console.log('Attempting to set selected job:', jobName);
    const sessionInfo = JSON.parse(localStorage.getItem('sessionInfo') || '{}');
    const customerData = sessionInfo?.sessionCusts?.find(
      (cust: any) => cust.name === selectedCustomer
    );
    const availableJobs = customerData?.sessionJobs || [];
    
    // Verify the job exists before setting it
    const jobExists = availableJobs.some((j: SessionJob) => j.name === jobName);
    
    if (!jobExists) {
      console.error(`Invalid job name: "${jobName}" for customer "${selectedCustomer}". Available jobs:`, 
        availableJobs.map((j: SessionJob) => ({
          name: j.name,
          displayName: j.displayName
        }))
      );
      return; // Don't set invalid job names
    }

    // Job exists, safe to set
    console.log('Setting valid job:', jobName);
    setSelectedJob(jobName);
  };

  // Update initialization effect to respect selected customer
  useEffect(() => {
    if (!selectedJob && selectedCustomer) {
      const sessionInfo = JSON.parse(localStorage.getItem('sessionInfo') || '{}');
      const customerData = sessionInfo?.sessionCusts?.find(
        (cust: any) => cust.name === selectedCustomer
      );
      const firstJob = customerData?.sessionJobs?.[0];
      
      if (firstJob) {
        console.log('Initializing with first available job for customer:', firstJob.name);
        handleSetSelectedJob(firstJob.name);
      }
    }
  }, [selectedCustomer]); // Run when customer changes

  const setDProps = (props: XDisplayProperties | null) => {
    setDPropsState(props);
    if (props) {
      localStorage.setItem('dProps', JSON.stringify(props));
    } else {
      localStorage.removeItem('dProps');
    }
  };

  return (
    <JobContext.Provider value={{ 
      selectedCustomer, 
      selectedJob,
      selectedJobId,
      setSelectedCustomer, 
      setSelectedJob: handleSetSelectedJob,
      jobInfo,
      setJobInfo,
      dProps,
      setDProps
    }}>
      {children}
    </JobContext.Provider>
  );
};

export const useJob = () => {
  const context = useContext(JobContext);
  if (context === undefined) {
    throw new Error('useJob must be used within a JobProvider');
  }
  return context;
};