import React, { useState, useEffect, useCallback, KeyboardEvent } from 'react';
import { Box, Paper, Typography, TextField, Button, Grid, IconButton, Tooltip, Tabs, Tab, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Stepper, Step, StepLabel, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Alert, Select, MenuItem } from '@mui/material';
import { DataGridPro, GridColDef, GridRowsProp } from '@mui/x-data-grid-pro';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { TreeItem2, TreeItem2Props } from '@mui/x-tree-view/TreeItem2';
import { useAuth } from '../contexts/AuthContext';
import { useJob } from '../contexts/JobContext';
import { GenNode } from '../types/types';
import { Folder, Description, ExpandMore, FiberManualRecord as DotIcon, Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import { StepIconProps } from '@mui/material/StepIcon';
import { styled, alpha } from '@mui/material/styles';
import { DragEvent } from 'react';
import { Button as MuiButton } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import axios from 'axios';
import debounce from 'lodash/debounce';

// Update the ExtendedGenNode type
interface ExtendedGenNode extends Omit<GenNode, 'children' | 'id' | 'parentId'> {
  id: string;
  parentId: string | null;
  children: ExtendedGenNode[] | null;
  label: string;
}

const Classification: React.FC = () => {
  const { carbonClient, isAuthenticated, isClientReady } = useAuth();
  const { selectedCustomer, selectedJob, selectedJobId } = useJob();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [variableTree, setVariableTree] = useState<ExtendedGenNode[]>([]);
  const [expandedVariableItems, setExpandedVariableItems] = useState<string[]>([]);
  const [selectedVariables, setSelectedVariables] = useState<string[]>([]);
  const [gridRows, setGridRows] = useState<GridRowsProp>([]);
  const [gridColumns, setGridColumns] = useState<GridColDef[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [variablesField, setVariablesField] = useState('');
  const [filterField, setFilterField] = useState('');
  const [selectedNode, setSelectedNode] = useState<ExtendedGenNode | null>(null);
  const [activeTab, setActiveTab] = useState<'classification' | 'chat'>('classification');
  const [chatInput, setChatInput] = useState('');
  const [chatHistory, setChatHistory] = useState<Array<{ message: string; isUser: boolean }>>([]);
  const [classifierManagementVisible, setClassifierManagementVisible] = useState(false);
  const [chatTab, setChatTab] = useState<'chat' | 'themeTagger'>('chat');
  const [activeStep, setActiveStep] = useState(0);
  const [themes, setThemes] = useState<Array<{ id: number; text: string }>>([]);
  const [editingTheme, setEditingTheme] = useState<{ id: number; text: string } | null>(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [primaryVariable, setPrimaryVariable] = useState<string | null>(null);
  const [chatThemes, setChatThemes] = useState<string[]>([]);
  const [showTagButton, setShowTagButton] = useState(false);
  const [taggingProgress, setTaggingProgress] = useState(0);
  const [isTagging, setIsTagging] = useState(false);
  const [taggingStatus, setTaggingStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const [tagRunCount, setTagRunCount] = useState(0);
  const [previewGenerated, setPreviewGenerated] = useState(false);
  const [previewedGridColumns, setPreviewedGridColumns] = useState<GridColDef[]>([]);
  const [previewedGridRows, setPreviewedGridRows] = useState<GridRowsProp>([]);
  const [allVariables, setAllVariables] = useState<string[]>([]);
  const [newVariableName, setNewVariableName] = useState('');
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [dataError, setDataError] = useState<string | null>(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [hasVariables, setHasVariables] = useState(false);

  // Add this near the top of the component after the useJob hook
  useEffect(() => {
    // Log the initial state
    console.log('TextAnalysis mounted with job:', {
      selectedJob,
      selectedJobId,
      sessionInfo: JSON.parse(localStorage.getItem('sessionInfo') || '{}')
    });
  }, []); // Run once on mount

  // Update the existing job context effect with more details
  useEffect(() => {
    console.log('Job context changed:', {
      customer: selectedCustomer,
      job: selectedJob,
      jobId: selectedJobId,
      sessionInfo: localStorage.getItem('sessionInfo') ? 'present' : 'missing'
    });
  }, [selectedCustomer, selectedJob, selectedJobId]);

  // Add effect to log job context changes
  useEffect(() => {
    console.log('Job context updated:', {
      customer: selectedCustomer,
      job: selectedJob,
      jobId: selectedJobId
    });
  }, [selectedCustomer, selectedJob, selectedJobId]);

  const fetchVariableTree = async () => {
    if (carbonClient && isClientReady && selectedCustomer && selectedJob) {
      setIsLoading(true);
      setErrorMessage(null);
      try {
        await carbonClient.openCloudJob(selectedCustomer, selectedJob);
        const specAggregate = await carbonClient.getSpecAggregate();
        if (Array.isArray(specAggregate.variableTree)) {
          setVariableTree(specAggregate.variableTree.map(mapToExtendedGenNode));
        }
      } catch (error) {
        console.error('Error fetching variable tree:', error);
        setErrorMessage(`Error fetching variable tree: ${error instanceof Error ? error.message : String(error)}`);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchVariableTree();
  }, [carbonClient, isClientReady, selectedCustomer, selectedJob]);

  const mapToExtendedGenNode = (node: GenNode): ExtendedGenNode => ({
    ...node,
    id: node.id.toString(),
    parentId: node.parentId?.toString() || null,
    children: node.children ? node.children.map(mapToExtendedGenNode) : null,
    label: node.name || node.value1 || node.value2 || 'Unnamed Node'
  });

  const handleExpandedItemsChange = useCallback((event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpandedVariableItems(nodeIds);
  }, []);

  const handleVariableClick = async (node: ExtendedGenNode) => {
    setSelectedNode(node);
    if (node.type === 'Variable' || node.type === 'Codeframe') {
      await handleVariableExpand(node.id);
    }
  };

  const handleVariableExpand = async (variableId: string) => {
    if (!carbonClient || !isClientReady) return;

    try {
      const node = findVariable(variableTree, variableId);
      if (node) {
        console.log(`Fetching variables for node:`, node);
        const nodeName = node.name || node.value1 || node.id.toString();
        console.log(`Using node name/id: ${nodeName}`);
        
        if (!node.children || node.children.length === 0) {
          const variables = await carbonClient.getVarNodes(nodeName);
          console.log(`Fetched codes for ${nodeName}:`, variables);
          if (variables.length === 0) {
            console.log(`No codes found for ${nodeName} (ID: ${variableId})`);
            setErrorMessage(`No codes found for ${nodeName}`);
          } else if (variables[0].type === 'Codeframe' && variables[0].children) {
            updateTreeWithCodeframe(variableId, variables);
          } else {
            updateTreeWithChildVariables(variableId, variables);
          }
        }
        
        setExpandedVariableItems(prev => {
          if (prev.includes(variableId)) {
            return prev.filter(id => id !== variableId);
          } else {
            return [...prev, variableId];
          }
        });

        setErrorMessage(null);
      } else {
        console.error(`Variable not found for ID: ${variableId}`);
        setErrorMessage(`Variable not found for ID: ${variableId}`);
      }
    } catch (error) {
      console.error('Error fetching node variables:', error);
      setErrorMessage(`Error fetching node variables: ${error instanceof Error ? error.message : String(error)}`);
    }
  };

  const updateTreeWithChildVariables = (parentId: string, childVariables: GenNode[]) => {
    setVariableTree((prevTree: ExtendedGenNode[]) => {
      const updateVariables = (variables: ExtendedGenNode[]): ExtendedGenNode[] => {
        return variables.map(variable => {
          if (variable.id.toString() === parentId) {
            return {
              ...variable,
              children: childVariables.map(mapToExtendedGenNode),
              isExpanded: true
            }
          }
          if (variable.children) {
            return { ...variable, children: updateVariables(variable.children) }
          }
          return variable
        })
      }
      return updateVariables(prevTree);
    })
  }

  const updateTreeWithCodeframe = (variableId: string, codeframe: GenNode[]) => {
    setVariableTree((prevTree: ExtendedGenNode[]) => {
      const updateVariables = (variables: ExtendedGenNode[]): ExtendedGenNode[] => {
        return variables.map(variable => {
          if (variable.id.toString() === variableId) {
            const codeNodes: ExtendedGenNode[] = codeframe[0].children?.map(code => ({
              ...code,
              id: `${variableId}_${code.id}`,
              type: 'Code',
              name: `${code.value1 || ''} - ${code.value2 || ''}`,
              isExpanded: false,
              children: null, // Change this from undefined to null
              label: `${code.value1 || ''} - ${code.value2 || ''}`, // Add this line
              parentId: variableId, // Add this line
            })) || [];

            return { ...variable, children: codeNodes, isExpanded: true };
          }
          if (variable.children) {
            return { ...variable, children: updateVariables(variable.children) };
          }
          return variable;
        });
      };
      return updateVariables(prevTree);
    });
  };

  const handleSelectedItemsChange = useCallback((event: React.SyntheticEvent, nodeIds: string[]) => {
    console.log('RichTreeView selection changed:', nodeIds);
    setSelectedVariables(nodeIds);
    // Remove the call to clearGridData()
  }, []);

  const findVariable = (nodes: ExtendedGenNode[], id: string): ExtendedGenNode | null => {
    for (const node of nodes) {
      if (node.id.toString() === id) {
        return node;
      }
      if (node.children) {
        const found = findVariable(node.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  // We can keep the clearGridData function in case we need it elsewhere
  const clearGridData = () => {
    setGridColumns([]);
    setGridRows([]);
  };

  const getVariableIcon = (variable: ExtendedGenNode | null) => {
    if (!variable) return <DotIcon fontSize="small" sx={{ mr: 1, color: 'action.active' }} />;

    if (variable.isFolder) return <Folder fontSize="medium" sx={{ mr: 1, color: 'lightblue' }} />;
    if (variable.type === 'Variable' || variable.type === 'Axis') {
      return (
        <Box sx={{ position: 'relative', display: 'inline-flex', mr: 1 }}>
          <Box
            sx={{
              width: 20,
              height: 20,
              borderRadius: '50%',
              background: 'radial-gradient(circle at 30% 30%, #FF6B6B, #FF0000)',
            }}
          />
          <ExpandMore sx={{ 
            position: 'absolute', 
            color: '#FFFFFF', 
            fontSize: 20, 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)'
          }} />
        </Box>
      );
    }
    if (variable.type === 'Code') {
      return (
        <Box
          sx={{
            width: 18,
            height: 18,
            borderRadius: '50%',
            mr: 1,
            background: 'radial-gradient(circle at 30% 30%, #FFFF66, #CCCC00)',
          }}
        />
      );
    }
    return <DotIcon fontSize="small" sx={{ mr: 1, color: 'action.active' }} />;
  };

  // Add this helper function to find a node by its ID
  const findNodeById = (nodes: ExtendedGenNode[], id: string): ExtendedGenNode | null => {
    for (const node of nodes) {
      if (node.id === id) return node;
      if (node.children) {
        const found = findNodeById(node.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  // Add this helper function to find the parent variable of a code
  const findParentVariable = (nodes: ExtendedGenNode[], codeId: string): ExtendedGenNode | null => {
    for (const node of nodes) {
      if (node.children) {
        const codeNode = node.children.find(child => child.id === codeId);
        if (codeNode) return node;
        const found = findParentVariable(node.children, codeId);
        if (found) return found;
      }
    }
    return null;
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>, field: 'variables' | 'filter') => {
    event.preventDefault();
    const data = event.dataTransfer.getData('text/plain');
    try {
      const node: ExtendedGenNode = JSON.parse(data);
      let value = (node.name || node.value1 || node.value2 || '').trim();

      if (field === 'variables' && node.type === 'Code') {
        const parentVariable = findParentVariable(variableTree, node.id);
        if (parentVariable) {
          value = (parentVariable.name || parentVariable.value1 || parentVariable.value2 || '').trim();
        }
      }

      if (value) {
        if (field === 'variables') {
          setVariablesField(prev => {
            const prevTrimmed = prev.trim();
            const newValue = prevTrimmed ? `${prevTrimmed},${value}` : value;
            const updatedVariables = newValue.split(',').map(v => v.trim()).filter(v => v);
            setAllVariables(updatedVariables);
            setHasVariables(updatedVariables.length > 0);
            
            if (!primaryVariable) {
              setPrimaryVariable(updatedVariables[0]);
            }
            
            return newValue;
          });
        } else {
          setFilterField(prev => {
            const prevTrimmed = prev.trim();
            return prevTrimmed ? `${prevTrimmed},${value}` : value;
          });
        }
      }
    } catch (error) {
      console.error('Error parsing dropped data:', error);
    }
  };

  const fetchDataFromAPI = async () => {
    console.log('Fetching data with job ID:', selectedJobId);
    setIsDataLoading(true);
    setDataError(null);
    
    try {
      // Check all required values
      if (!selectedCustomer) {
        throw new Error('No customer selected');
      }
      
      if (!selectedJob) {
        throw new Error('No job selected');
      }

      if (!selectedJobId) {
        throw new Error('No job ID available - please try selecting the job again');
      }

      if (!variablesField.trim()) {
        throw new Error('Please specify at least one variable');
      }

      const varnames = variablesField;
      
      // Format URL exactly like tsapi.html does
      const varnamesParam = varnames.split(',')
        .filter(x => x.trim())
        .map(x => `varnames=${encodeURIComponent(x.trim())}`)
        .join('&');

      if (!varnamesParam) {
        throw new Error('No valid variables specified');
      }

      // Always include filter parameter, even if empty
      const filtersParam = filterField.trim() 
        ? filterField.split(',')
            .filter(x => x.trim())
            .map(x => `filter=${encodeURIComponent(x.trim())}`)
            .join('&')
        : 'filter='; // Add empty filter parameter when no filters

      // Update URL construction to match the middleware implementation
      const url = `/secret/api/run-join?job_id=${selectedJobId}&${varnamesParam}&${filtersParam}`;
      
      console.log('Full request details:', {
        url,
        varnames,
        filters: filterField,
        varnamesParam,
        filtersParam,
        job_id: selectedJobId
      });
      
      const middlewareCredentials = btoa(`${process.env.REACT_APP_MIDDLEWARE_USERNAME}:${process.env.REACT_APP_MIDDLEWARE_PASSWORD}`);
      const apiKey = process.env.REACT_APP_API_KEY;
      
      const response = await axios({
        method: 'GET',
        url: url,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Accept": "application/json",
          "Authorization": `Basic ${middlewareCredentials}`,
          "X-API-Key": apiKey || ''
        }
      });

      console.log('Response details:', {
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        data: response.data
      });

      if (response.status === 200) {
        const data = response.data;
        
        if (!data) {
          throw new Error('No data received from API');
        }

        if (typeof data === 'string' && data.includes('<!DOCTYPE html>')) {
          throw new Error('Received HTML instead of JSON. The request may have been redirected.');
        }

        loadDataIntoTable(data);
        setIsDataLoaded(true);
        
        const variables = variablesField.split(',').map(v => v.trim()).filter(v => v);
        setAllVariables(variables);
        
        if (!primaryVariable && variables.length > 0) {
          setPrimaryVariable(variables[0]);
        }
      } else {
        throw new Error(`HTTP error! status: ${response.status} - ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error fetching data from API:', error);
      setDataError(error instanceof Error ? error.message : 'An unknown error occurred');
      setIsDataLoaded(false);
      clearGridData();
    } finally {
      setIsDataLoading(false);
    }
  };

  // Update the loadDataIntoTable function to be more robust
  const loadDataIntoTable = (data: any) => {
    try {
      // Ensure data is an array
      const dataArray = Array.isArray(data) ? data : [data];
      
      if (dataArray.length === 0) {
        setGridColumns([]);
        setGridRows([]);
        return;
      }

      // Get all unique keys from all objects in the data array
      const allKeys = Array.from(
        new Set(
          dataArray.reduce((keys: string[], item) => {
            if (item && typeof item === 'object') {
              return keys.concat(Object.keys(item));
            }
            return keys;
          }, [])
        )
      );

      // Define columns based on all possible keys
      const columns: GridColDef[] = allKeys.map(key => ({
        field: key, // Keep original key as field
        headerName: key,
        width: 300,
        flex: 1,
        renderCell: (params) => {
          // Safely render the cell value
          return params.row[key] || '';
        }
      }));

      // Map the data to rows, keeping original keys
      const rows: GridRowsProp = dataArray.map((item, index) => {
        if (typeof item !== 'object' || item === null) {
          return { id: index, value: String(item) };
        }
        return {
          id: index,
          ...item // Keep original data structure
        };
      });

      console.log('Processed data:', {
        columns,
        rows,
        firstRow: rows[0]
      });

      setGridColumns(columns);
      setGridRows(rows);
    } catch (error) {
      console.error('Error loading data into table:', error);
      throw new Error('Failed to process data for display');
    }
  };

  // Modify the existing useEffect to not fetch data on mount
  useEffect(() => {
    // We'll fetch data when the user clicks the refresh button or changes variables/filters
  }, []);

  // Update these handlers to not trigger data fetching
  const handleVariablesFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setVariablesField(newValue);
    const variables = newValue.split(',').map(v => v.trim()).filter(v => v);
    setAllVariables(variables);
    setHasVariables(variables.length > 0);
    
    // Update primary variable if needed
    setPrimaryVariable(prev => {
      if (!prev && variables.length > 0) {
        return variables[0];
      }
      if (prev && !variables.includes(prev)) {
        return variables[0] || null;
      }
      return prev;
    });
  };

  const handleFilterFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterField(e.target.value);
    // Remove the call to debouncedFetchData()
  };

  // Add this new function to handle manual refresh
  const handleRefreshData = () => {
    fetchDataFromAPI();
  };

  // Add this useEffect to log state changes
  useEffect(() => {
    console.log('allVariables updated:', allVariables);
    console.log('primaryVariable updated:', primaryVariable);
  }, [allVariables, primaryVariable]);

  const handleDragStart = (event: DragEvent<HTMLLIElement>, node: ExtendedGenNode) => {
    event.dataTransfer.setData('text/plain', JSON.stringify(node));
    event.dataTransfer.effectAllowed = 'copy';
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  };

  const CustomTreeItem = React.forwardRef<HTMLLIElement, TreeItem2Props>((props, ref) => {
    const { itemId, label, ...other } = props;
    const item = findVariable(variableTree, itemId);

    return (
      <TreeItem2
        ref={ref}
        {...other}
        itemId={itemId}
        label={
          <Box 
            sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
            onClick={() => item && handleVariableClick(item)}
            draggable
            onDragStart={(event: React.DragEvent<HTMLDivElement>) => {
              if (item) {
                handleDragStart(event as unknown as DragEvent<HTMLLIElement>, item);
              }
            }}
          >
            {getVariableIcon(item)}
            <Typography variant="body2" sx={{ mr: 1 }}>{label}</Typography>
            {item?.type === 'Code' && item.meta && item.meta.map((metaItem: { key: string; value: string }, index: number) => (
              <Tooltip key={index} title={`${metaItem.key}: ${metaItem.value}`}>
                <span style={{ marginLeft: '8px', fontSize: '0.8em', color: 'gray' }}>
                  {metaItem.key === 'PC' ? '%' : metaItem.key}
                </span>
              </Tooltip>
            ))}
          </Box>
        }
      />
    );
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: 'classification' | 'chat') => {
    setActiveTab(newValue);
  };

  const handleChatSubmit = () => {
    if (chatInput.trim()) {
      // Add user message to chat history
      setChatHistory(prev => [...prev, { message: chatInput, isUser: true }]);
      
      // Process chat commands
      const response = processChatCommand(chatInput.trim());
      
      // Add AI response to chat history
      setChatHistory(prev => [...prev, { message: response, isUser: false }]);

      setChatInput('');
    }
  };

  const processChatCommand = (command: string): string => {
    const lowerCommand = command.toLowerCase();
    
    if (lowerCommand === 'generate a list of 5 themes') {
      const generatedThemes = generateThemes();
      setChatThemes(generatedThemes);
      setShowTagButton(true);
      return "Here's a list of 5 themes:\n" + generatedThemes.map((theme, index) => `${index + 1}. ${theme}`).join('\n');
    } else {
      // Default response for unrecognized commands
      setShowTagButton(false);
      return `You said: ${command}`;
    }
  };

  const generateThemes = (): string[] => {
    // This is a placeholder. In a real application, this would be more sophisticated,
    // possibly calling an AI service or using a more complex algorithm.
    return [
      "Customer Satisfaction",
      "Product Quality",
      "Service Efficiency",
      "Price Competitiveness",
      "Brand Loyalty"
    ];
  };

  const handleTagThemes = () => {
    setThemes(chatThemes.map((text, id) => ({ id, text })));
    setChatTab('themeTagger');
    setShowTagButton(false);
  };

  const handleRunClassifier = (id: string) => {
    console.log(`Running classifier with ID: ${id}`);
    // Implement the logic to run the classifier
  };

  const handleChatTabChange = (event: React.SyntheticEvent, newValue: 'chat' | 'themeTagger') => {
    setChatTab(newValue);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setPreviewGenerated(false);
    setPreviewedGridColumns([]);
    setPreviewedGridRows([]);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // Updated CustomStepIcon component
  const CustomStepIcon = (props: StepIconProps) => {
    const { active, completed, icon } = props;

    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Box
          sx={{
            width: 32,
            height: 32,
            borderRadius: '50%',
            backgroundColor: active ? 'primary.main' : 'grey.300',
            color: active ? 'white' : 'grey.700', // Changed from 'text.primary' to 'grey.700'
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'bold',
          }}
        >
          {icon}
        </Box>
      </Box>
    );
  };

  // Updated styled components for the tabs
  const StyledTabs = styled(Tabs)(({ theme }) => ({
    minHeight: '40px',
    height: '40px',
    '& .MuiTabs-flexContainer': {
      height: '100%',
      display: 'flex',
    },
  }));

  const StyledTab = styled(Tab)(({ theme }) => ({
    minHeight: '40px',
    height: '40px',
    flex: 1, // This will make each tab take up equal space
    maxWidth: 'none', // Remove max-width constraint
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    padding: '0 8px', // Reduced padding to accommodate longer text if needed
    '&.Mui-selected': {
      color: theme.palette.primary.main,
    },
  }));

  const handleEditTheme = (theme: { id: number; text: string }) => {
    setEditingTheme(theme);
    setIsEditDialogOpen(true);
  };

  const handleDeleteTheme = (id: number) => {
    setThemes(themes.filter(theme => theme.id !== id));
  };

  const handleAddTheme = () => {
    const newId = Math.max(...themes.map(t => t.id), 0) + 1;
    setEditingTheme({ id: newId, text: "" });
    setIsEditDialogOpen(true);
  };

  const handleSaveTheme = () => {
    if (editingTheme) {
      setThemes(prevThemes => {
        const existingThemeIndex = prevThemes.findIndex(t => t.id === editingTheme.id);
        if (existingThemeIndex !== -1) {
          // Update existing theme
          const updatedThemes = [...prevThemes];
          updatedThemes[existingThemeIndex] = editingTheme;
          return updatedThemes;
        } else {
          // Add new theme
          return [...prevThemes, editingTheme];
        }
      });
    }
    setIsEditDialogOpen(false);
    setEditingTheme(null);
  };

  const handleTagToDatabase = () => {
    // Include newVariableName in your tagging logic here
    console.log(`Tagging to database with new variable name: ${newVariableName}`);
    setIsTagging(true);
    setTaggingStatus('idle');
    setTaggingProgress(0);

    const totalRecords = 100;
    let currentRecord = 0;

    const processNextRecord = () => {
      if (currentRecord < totalRecords) {
        currentRecord++;
        setTaggingProgress(currentRecord);

        // Simulate processing time
        setTimeout(() => {
          processNextRecord();
        }, 50);
      } else {
        setIsTagging(false);
        
        // Alternate between success and error
        if (tagRunCount % 2 === 0) {
          setTaggingStatus('success');
        } else {
          setTaggingStatus('error');
        }
        
        // Increment the run count
        setTagRunCount(prevCount => prevCount + 1);
      }
    };

    processNextRecord();
  };

  // Modify the handleClearPreview function
  const handleClearPreview = () => {
    setPreviewGenerated(false);
    setPreviewedGridColumns([]);
    setPreviewedGridRows([]);
    setNewVariableName(''); // Add this line to clear the new variable name
  };

  const handlePreviewThemes = () => {
    const newColumn: GridColDef = {
      field: 'N5AAIThemes',
      headerName: 'N5A Themes',
      width: 200,
    };

    const updatedColumns = [...gridColumns, newColumn];
    setPreviewedGridColumns(updatedColumns);

    const updatedRows = gridRows.map((row, index) => {
      if (index < 25) {
        const randomTheme = themes[Math.floor(Math.random() * themes.length)].text;
        return { ...row, N5AAIThemes: randomTheme };
      }
      return row;
    });

    setPreviewedGridRows(updatedRows);
    setPreviewGenerated(true);
  };

  // Update the SuggestedPrompts component
  const SuggestedPrompts: React.FC<{ variable: string | null; onPromptClick: (prompt: string) => void; isDataLoaded: boolean }> = ({ variable, onPromptClick, isDataLoaded }) => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, width: '100%' }}>
      <Button 
        variant="outlined" 
        size="small" 
        onClick={() => variable && onPromptClick(`Find a positive quote about ${variable}`)}
        sx={{ 
          fontSize: '0.75rem', 
          textTransform: 'none', 
          width: '49%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
        disabled={!isDataLoaded || !variable}
      >
        Find a positive quote about {variable || 'variable'}
      </Button>
      <Button 
        variant="outlined" 
        size="small" 
        onClick={() => variable && onPromptClick('Generate a list of 5 themes')}
        sx={{ 
          fontSize: '0.75rem', 
          textTransform: 'none', 
          width: '49%'
        }}
        disabled={!isDataLoaded || !variable}
      >
        Generate a list of 5 themes
      </Button>
    </Box>
  );

  // Add this function to handle clicking on a suggested prompt
  const handlePromptClick = (prompt: string) => {
    setChatInput(prompt);
  };

  // Add this new function to handle primary variable change
  const handlePrimaryVariableChange = (event: SelectChangeEvent<string>) => {
    setPrimaryVariable(event.target.value as string);
  };

  // Add a new function to handle the new variable name change
  const handleNewVariableNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewVariableName(event.target.value);
  };

  // Add this useEffect to reset isDataLoaded when customer or job changes
  useEffect(() => {
    setIsDataLoaded(false);
    setHasVariables(false);
  }, [selectedCustomer, selectedJob]);

  useEffect(() => {
    console.log('Current job details:', {
      name: selectedJob,
      id: selectedJobId,
      customer: selectedCustomer
    });
  }, [selectedJob, selectedJobId, selectedCustomer]);

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        height: 'calc(100vh - 84px)',
        mt: '84px',
        overflow: 'hidden',
        pl: 2,
        pr: 3,
        py: 2,
      }} 
      tabIndex={0}
      // Remove onKeyDown from here as we're using document-level event listener
    >
      {/* Left sidebar - Variable Tree */}
      <Paper sx={{ 
        width: 260, 
        p: 2, 
        mr: 2, // Right margin
        overflowY: 'auto', // Allow scrolling in the variable tree if needed
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Typography variant="h6" gutterBottom>Variable Tree</Typography>
        <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
          {isLoading ? (
            <Typography>Loading variable tree...</Typography>
          ) : errorMessage ? (
            <Typography color="error">{errorMessage}</Typography>
          ) : variableTree.length > 0 ? (
            <RichTreeView<ExtendedGenNode, true>
              items={variableTree}
              getItemLabel={(item) => item.label}
              slots={{
                item: (props: TreeItem2Props) => <CustomTreeItem key={props.itemId} {...props} />
              }}
              expandedItems={expandedVariableItems}
              selectedItems={selectedVariables}
              onExpandedItemsChange={handleExpandedItemsChange}
              onSelectedItemsChange={handleSelectedItemsChange}
              multiSelect={true}
              sx={{
                '& .MuiTreeItem-content': {
                  padding: '2px 0',
                },
                '& .MuiTreeItem-label': {
                  fontSize: '0.875rem',
                },
                '& .MuiTreeItem-group': {
                  marginLeft: 2,
                  paddingLeft: 1,
                  borderLeft: '1px dashed rgba(0, 0, 0, 0.2)',
                },
              }}
            />
          ) : (
            <Typography>No variables found</Typography>
          )}
        </Box>
      </Paper>

      {/* Right side - Split view for DataGrid and Chat */}
      <Box sx={{ flex: 1, display: 'flex', overflow: 'hidden' }}>
        {/* Data Grid Section - 70% */}
        <Box sx={{ flex: '0 0 70%', mr: 2, display: 'flex', flexDirection: 'column' }}>
          {/* Fields above the table */}
          <Grid container spacing={2} alignItems="center" sx={{ mb: 2, pt: .2 }}>
            <Grid item xs={5}>
              <TextField
                fullWidth
                label="Variables"
                value={variablesField}
                onChange={handleVariablesFieldChange}
                size="small"
                InputProps={{
                  sx: {
                    height: '40px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: (theme) => alpha(theme.palette.primary.main, 0.5),
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    },
                  },
                }}
                sx={{
                  backgroundColor: 'white',
                  '& .MuiInputLabel-root': {
                    transform: 'translate(14px, 11px) scale(1)',
                    '&.MuiInputLabel-shrink': {
                      transform: 'translate(14px, -6px) scale(0.75)',
                    },
                  },
                }}
                onDragOver={handleDragOver}
                onDrop={(event) => handleDrop(event, 'variables')}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                label="Filter"
                value={filterField}
                onChange={handleFilterFieldChange}
                size="small"
                InputProps={{
                  sx: {
                    height: '40px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: (theme) => alpha(theme.palette.primary.main, 0.5),
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'primary.main',
                    },
                  },
                }}
                sx={{
                  backgroundColor: 'white',
                  '& .MuiInputLabel-root': {
                    transform: 'translate(14px, 11px) scale(1)',
                    '&.MuiInputLabel-shrink': {
                      transform: 'translate(14px, -6px) scale(0.75)',
                    },
                  },
                }}
                onDragOver={handleDragOver}
                onDrop={(event) => handleDrop(event, 'filter')}
              />
            </Grid>
            <Grid item xs={2}>
              <Button 
                variant="contained" 
                onClick={handleRefreshData}
                disabled={isDataLoading || !selectedCustomer || !selectedJob || !hasVariables}
                startIcon={<RefreshIcon />}
              >
                {isDataLoading ? <CircularProgress size={24} /> : (isDataLoaded ? 'Refresh Data' : 'Load Data')}
              </Button>
            </Grid>
            {dataError && (
              <Grid item xs={12}>
                <Alert severity="error">{dataError}</Alert>
              </Grid>
            )}
          </Grid>

          {/* DataGrid */}
          <Paper sx={{ flex: 1, overflow: 'hidden' }}>
            <DataGridPro
              rows={gridRows}
              columns={gridColumns}
              loading={isDataLoading}
              pagination
              checkboxSelection
              disableRowSelectionOnClick
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 25, page: 0 },
                },
              }}
              pageSizeOptions={[25, 50, 100]}
            />
          </Paper>
        </Box>

        {/* Chat Section - 30% */}
        <Box sx={{ flex: '0 0 30%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
          <Box sx={{ pr: 2, pb: 2, pt: .2 }}> {/* Added padding to the right and bottom */}
            <StyledTabs 
              value={chatTab} 
              onChange={handleChatTabChange} 
              sx={{ 
                '& .MuiTabs-indicator': {
                  backgroundColor: 'primary.main',
                  height: '2px',
                },
                border: 1,
                borderColor: (theme) => alpha(theme.palette.primary.main, 0.5),
                borderRadius: '4px',
                overflow: 'hidden',
                width: '100%',
              }}
            >
              <StyledTab label="Chat" value="chat" />
              <StyledTab label="Theme Tagger" value="themeTagger" />
            </StyledTabs>
          </Box>

          <Box sx={{ flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column', pr: 2 }}> {/* Added padding to the right */}
            {chatTab === 'chat' && (
              <>
                {/* Chat History */}
                <Box sx={{ 
                  flex: 1, 
                  overflowY: 'auto', 
                  mb: 2, 
                  p: 2, 
                  border: '1px solid #ccc', 
                  borderRadius: 1, 
                  bgcolor: '#f9f9f9',
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                  {/* Primary Variable Display */}
                  <Paper sx={{ 
                    p: 1, 
                    mb: 2, 
                    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
                    border: 1,
                    borderColor: 'primary.main',
                    borderRadius: '4px',
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                  }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body2" fontWeight="medium" sx={{ mr: 1 }}>
                        Analysis Variable:
                      </Typography>
                      {isDataLoaded && allVariables.length > 0 ? (
                        <Select
                          value={primaryVariable || ''}
                          onChange={handlePrimaryVariableChange}
                          displayEmpty
                          variant="standard"
                          sx={{
                            minWidth: 120,
                            '&:before': { borderBottom: 'none' },
                            '&:after': { borderBottom: 'none' },
                            '& .MuiSelect-select': { py: 0 },
                          }}
                        >
                          {allVariables.map((variable, index) => (
                            <MenuItem key={index} value={variable}>{variable}</MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <Typography variant="body2" fontWeight="medium">
                          {isDataLoaded ? 'No variables available' : 'Load data to select variable'}
                        </Typography>
                      )}
                    </Box>
                  </Paper>

                  {/* Chat messages */}
                  {chatHistory.map((chat, index) => (
                    <Box key={index} sx={{ mb: 1, textAlign: chat.isUser ? 'right' : 'left' }}>
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          display: 'inline-block',
                          bgcolor: chat.isUser ? '#e3f2fd' : '#f0f0f0',
                          p: 1, 
                          borderRadius: 1
                        }}
                      >
                        {chat.message}
                      </Typography>
                    </Box>
                  ))}
                  {showTagButton && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                      <MuiButton variant="contained" onClick={handleTagThemes}>
                        Tag
                      </MuiButton>
                    </Box>
                  )}
                </Box>

                {/* Suggested Prompts */}
                <SuggestedPrompts variable={primaryVariable} onPromptClick={handlePromptClick} isDataLoaded={isDataLoaded} />

                {/* Chat Input and Buttons */}
                <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={isDataLoaded ? "Type your message here..." : "Load data to start chatting"}
                    value={chatInput}
                    onChange={(e) => setChatInput(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && isDataLoaded && handleChatSubmit()}
                    size="small"
                    disabled={!isDataLoaded}
                  />
                  <Button 
                    variant="contained" 
                    onClick={handleChatSubmit} 
                    sx={{ minWidth: 'auto', px: 2 }}
                    disabled={!isDataLoaded}
                  >
                    Chat
                  </Button>
                </Box>
              </>
            )}

            {chatTab === 'themeTagger' && (
              <Box sx={{ p: 2, border: '1px solid #ccc', borderRadius: 1, bgcolor: '#f9f9f9', flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                <Box sx={{ mb: 2 }}>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    <Step>
                      <StepLabel StepIconComponent={CustomStepIcon}>Refine Themes</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel StepIconComponent={CustomStepIcon}>Tag to Database</StepLabel>
                    </Step>
                  </Stepper>
                </Box>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                  {activeStep === 0 && (
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                      <TableContainer sx={{ maxHeight: 'calc(100% - 48px)', overflow: 'auto' }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'background.paper', width: '70%' }}>Theme</TableCell>
                              <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'background.paper', width: '30%' }} align="right">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {themes.map((theme, index) => (
                              <TableRow key={index}>
                                <TableCell sx={{ width: '70%' }}>{theme.text}</TableCell>
                                <TableCell sx={{ width: '30%' }}>
                                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <IconButton onClick={() => handleEditTheme(theme)} size="small">
                                      <EditIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton onClick={() => handleDeleteTheme(theme.id)} size="small">
                                      <DeleteIcon fontSize="small" />
                                    </IconButton>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Button
                        startIcon={<AddIcon />}
                        onClick={handleAddTheme}
                        sx={{ mt: 2 }}
                      >
                        Add New Theme
                      </Button>
                    </Box>
                  )}
                  {activeStep === 1 && (
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography variant="h6" gutterBottom>
                        Tag Themes to Database
                      </Typography>
                      {isTagging ? (
                        <>
                          <Typography variant="body1" gutterBottom>
                            {taggingProgress}/100 records processed
                          </Typography>
                          <CircularProgress sx={{ my: 2 }} />
                        </>
                      ) : (
                        // New flex container for alerts, buttons, and text field
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '300px', gap: 2 }}>
                          {taggingStatus === 'success' && (
                            <Alert severity="success" sx={{ width: '100%' }}>
                              Tagging process completed successfully!
                            </Alert>
                          )}
                          {taggingStatus === 'error' && (
                            <Alert severity="error" sx={{ width: '100%' }}>
                              An error occurred during the tagging process. Please try again.
                            </Alert>
                          )}
                          <Button
                            variant="contained"
                            color="info"
                            onClick={previewGenerated ? handleClearPreview : handlePreviewThemes}
                            disabled={isTagging}
                            fullWidth
                          >
                            {previewGenerated ? 'Clear Preview' : 'Preview Themes'}
                          </Button>
                          <TextField
                            label="New Variable Name"
                            value={newVariableName}
                            onChange={handleNewVariableNameChange}
                            disabled={!previewGenerated}
                            fullWidth
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleTagToDatabase}
                            disabled={isTagging || !previewGenerated || !newVariableName.trim()}
                            fullWidth
                          >
                            Tag to Database
                          </Button>
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {activeStep === 0 ? (
                    <Button onClick={handleNext}>Next</Button>
                  ) : (
                    <Button
                      onClick={handleReset}
                      disabled={isTagging}
                    >
                      Reset
                    </Button>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {/* Edit Theme Dialog */}
      <Dialog open={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)}>
        <DialogTitle>{editingTheme?.id ? 'Edit Theme' : 'Add New Theme'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Theme"
            fullWidth
            value={editingTheme?.text || ''}
            onChange={(e) => setEditingTheme(prev => prev ? {...prev, text: e.target.value} : null)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSaveTheme}>Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Classification;
