import axios, { AxiosInstance } from 'axios'
import {
  SessionInfo,
  RunSpecRequest,
  GenericResponse,
  ChangePasswordRequest,
  UpdateAccountRequest,
  OpenCloudJobResponse,
  XlsxResponse,
  MultiOxtRequest,
  MultiOxtResponse,
  QuickUpdateRequest,
  ValidateExpRequest,
  AzDashboard,
  UpsertDashboardRequest,
  DashboardRequest,
  TocNode,
  GenNode,
  ServiceInfo,
  SessionStatus,
  CloseJobResponse,
  LogoffResponse,
  SpecAggregate,
  TableSpec,
  GenTabRequest,
  XDisplayProperties, // Add this import if not already present
  ICarbonClient // Make sure this import is present
} from '../types/types'

const BASE_URL = 'https://bayesprice.azurewebsites.net/carbontest/'

export class CarbonClient implements ICarbonClient {
  private axiosInstance: AxiosInstance
  private sessionInfo: SessionInfo | null = null
  private isJobOpen: boolean = false
  private currentCustomer: string | null = null
  private currentJob: string | null = null

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    })

    // Add an interceptor to always include the session ID if available
    this.axiosInstance.interceptors.request.use((config) => {
      if (this.sessionInfo && this.sessionInfo.sessionId) {
        config.headers['x-session-id'] = this.sessionInfo.sessionId
      }
      return config
    })
  }

  setSessionInfo(sessionInfo: SessionInfo | null) {
    this.sessionInfo = sessionInfo
  }

  isAuthenticated(): boolean {
    return this.isAuthenticated !== null
  }

  // Comment: This method aligns with the /session/start/authenticate/name endpoint in the swagger spec
  async authenticate(name: string, password: string): Promise<SessionInfo> {
    const response = await this.axiosInstance.post('session/start/authenticate/name', {
      name,
      password,
      skipCache: true,
    })
    const sessionInfo = response.data
    this.setSessionInfo(sessionInfo)
    return sessionInfo
  }

  // Update openCloudJob method
  async openCloudJob(customerName: string, jobName: string): Promise<OpenCloudJobResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    
    try {
      const response = await this.axiosInstance.post<OpenCloudJobResponse>('job/open', {
        customerName,
        jobName,
      });
      this.isJobOpen = true;
      this.currentCustomer = customerName;
      this.currentJob = jobName;
      console.log(`Job opened: ${customerName} - ${jobName}`);
      return response.data;
    } catch (error) {
      console.error('Error opening cloud job:', error);
      throw error;
    }
  }

  // Comment: Method to close the job
  async closeJob(): Promise<CloseJobResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.delete('job/close')
    this.isJobOpen = false
    return response.data
  }

  // Comment: Method to logoff the session
  async logoff(): Promise<LogoffResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.delete('session/end/logoff')
    return response.data
  }

  async getTocNodes(): Promise<TocNode[]> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated')
    }
    
    try {
      const response = await this.axiosInstance.get(`job/toc/full/true`)
      
      return this.mapToGenNodes(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Error response:', error.response?.data)
        throw new Error(`Failed to fetch TOC nodes: ${error.response?.data?.message || error.message}`)
      }
      throw error
    }
  }

  // Update the mapToGenNodes method
  private mapToGenNodes(nodes: any[], parentId: string | null = null): TocNode[] {
    return nodes.map(node => {
      // Determine the node type
      const nodeType = this.getNodeType(node.type)

      // Create the TocNode object
      const tocNode: TocNode = {
        name: node.value1 || '',
        type: nodeType,
        id: node.id,
        parentId: parentId || null,
        isExpanded: false,
        isFolder: nodeType === 'Folder',
        value1: node.value1,
        value2: node.value2,
        meta: node.meta || null,
        children: node.children ? this.mapToGenNodes(node.children, node.id) : null,
        level: 0,
        anyChildren: !!node.children
      }

      // Set additional properties based on node type
      switch (nodeType) {
        case 'Section':
        case 'Folder':
        case 'Table':
        case 'User':
          tocNode.value1 = node.value1 || ''
          tocNode.value2 = node.value2 || ''
          break
        default:
          // For unknown types, we'll keep the default values
          break
      }

      return tocNode
    })
  }

  // Comment: Helper method to determine the node type
  private getNodeType(type: string): string {
    // Define known types
    const knownTypes = ['Section', 'Folder', 'Table', 'User']
    
    // If the type is known, return it; otherwise, return 'Unknown'
    return knownTypes.includes(type) ? type : 'Unknown'
  }

  // Update setVartreeName method
  async setVartreeName(treeName: string, jobName: string, customerName: string): Promise<boolean> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.post('job/vartree/set', {
      treeName,
      jobName,
      customerName
    })
    return response.data
  }

  // Comment: This method aligns with the /job/vartree/nodes endpoint in the swagger spec
  async variableTreeAsNodes(): Promise<TocNode[]> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.get('job/vartree/nodes')
    return response.data
  }

  // Update method signature to match ICarbonClient interface
  async runSpec(specData: RunSpecRequest): Promise<GenericResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    console.log('Sending runSpec request to:', `${this.axiosInstance.defaults.baseURL}job/spec`);
    console.log('Request data:', JSON.stringify(specData, null, 2));
    try {
      const response = await this.axiosInstance.post<GenericResponse>('job/spec', specData);
      console.log('RunSpec response:', response.data);
      
      // Save the spec to localStorage after successful run
      localStorage.setItem('currentSpec', JSON.stringify(specData));
      
      return response.data;
    } catch (error) {
      console.error('Error in runSpec:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response data:', error.response?.data);
        console.error('Response status:', error.response?.status);
      }
      throw error;
    }
  }

  // Update loadReport method
  async LoadReportAsync(fullName: string): Promise<GenericResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      console.log(`Attempting to load report: ${fullName}`);
      const response = await this.axiosInstance.post<GenericResponse>('job/report/load', { name: fullName });
      console.log('Load report response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error loading report:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Error response:', error.response.data);
        return error.response.data as GenericResponse;
      }
      throw new Error(`Failed to load report: ${(error as Error).message}`);
    }
  }

  // Comment: This method aligns with the /job/report/xlsx endpoint in the swagger spec
  async getReportXlsx(reportName: string): Promise<XlsxResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    
    try {
      const response = await this.axiosInstance.get<XlsxResponse>('job/report/xlsx', { params: { reportName } });
      return response.data;
    } catch (error) {
      console.error('Error fetching XLSX:', error);
      throw error;
    }
  }

  // Update multiOxt method
  async multiOxt(request: MultiOxtRequest): Promise<MultiOxtResponse> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.post('job/report/multioxt', request)
    return response.data
  }

  // Update quickUpdate method
  async quickUpdate(request: QuickUpdateRequest): Promise<XlsxResponse> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.post('job/report/quickupdate', request)
    return response.data
  }

  // Implement listSessions method
  async listSessions(): Promise<SessionStatus[]> {
    const response = await this.axiosInstance.get('session/list')
    return response.data
  }

  // Update the method signature to match ICarbonClient interface
  async changePassword(request: ChangePasswordRequest): Promise<GenericResponse> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    // Implement the API call using the request object
    const response = await this.axiosInstance.post('account/password/change', request)
    return response.data
  }

  async updateAccount(request: UpdateAccountRequest): Promise<GenericResponse> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    // Implement the API call using the request object
    const response = await this.axiosInstance.post('account/update', request)
    return response.data
  }

  async getServiceInfo(): Promise<ServiceInfo> {
    const response = await this.axiosInstance.get('service/info')
    return response.data
  }

  async listDashboards(customerName: string, jobName: string): Promise<AzDashboard[]> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.get(`dashboard/list/${customerName}/${jobName}`)
    return response.data
  }

  // Comment: Implement getDashboard method
  async getDashboard(request: DashboardRequest): Promise<AzDashboard> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.post('dashboard/get', request)
    return response.data
  }

  // Comment: Implement deleteDashboard method
  async deleteDashboard(request: DashboardRequest): Promise<boolean> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.post('dashboard/delete', request)
    return response.data
  }

  // Comment: Implement upsertDashboard method
  async upsertDashboard(request: UpsertDashboardRequest): Promise<AzDashboard> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.post('dashboard/upsert', request)
    return response.data
  }

  // Comment: Implement validateExpression method
  async validateExpression(request: ValidateExpRequest): Promise<GenericResponse> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.post('job/validate/expression', request)
    return response.data
  }

  // Implement listVartrees method
  async listVartrees(): Promise<string[]> {
    if (!this.isAuthenticated) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.get('job/vartree/list')
    return response.data
  }

  // Comment: Add getCustomers method to retrieve customer names from sessionInfo
  async getCustomers(): Promise<string[]> {
    if (!this.sessionInfo) {
      throw new Error('Not authenticated')
    }
    return this.sessionInfo.sessionCusts.map(cust => cust.name)
  }

  // Comment: Add getJobs method to retrieve job names for a specific customer
  async getJobs(customerName: string): Promise<string[]> {
    if (!this.sessionInfo) {
      throw new Error('Not authenticated')
    }
    const customer = this.sessionInfo.sessionCusts.find(cust => cust.name === customerName)
    if (!customer) {
      throw new Error(`Customer ${customerName} not found`)
    }
    return customer.sessionJobs.map(job => job.name)
  }

  // Add this method to your CarbonClient class
  async getVarNodes(nodeName: string): Promise<GenNode[]> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    if (!nodeName) {
      console.error('Node name is empty');
      return [];
    }
    try {
      console.log(`Fetching variables for node: ${nodeName}`);
      const response = await this.axiosInstance.get<GenNode[]>(`job/varnodes/${encodeURIComponent(nodeName)}`);
      console.log(`Response for ${nodeName}:`, response.data);
      return response.data;
    } catch (error) {
      console.error(`Error fetching variables for node ${nodeName}:`, error);
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.message || error.message;
        console.error(`API Error: ${errorMessage}`);
        console.error('Full error response:', error.response);
        if (error.response?.status === 404 || error.response?.status === 500) {
          console.log(`No variables found for node ${nodeName}, returning empty array`);
          return [];
        }
      }
      throw error;
    }
  }

  async getAxisNodes(nodeName: string): Promise<GenNode[]> {
    const response = await this.axiosInstance.get<GenNode[]>(`job/axisnodes/${nodeName}`);
    return response.data;
  }

  async getFunctionNodes(nodeName: string): Promise<GenNode[]> {
    const response = await this.axiosInstance.get<GenNode[]>(`job/functionnodes/${nodeName}`);
    return response.data;
  }

  async getSpecAggregate(): Promise<SpecAggregate> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    const response = await this.axiosInstance.get<SpecAggregate>('job/spec/edit');
    return response.data;
  }

  // Add these methods to your CarbonClient class
  async getVariableTree(): Promise<GenNode[]> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated')
    }
    const response = await this.axiosInstance.get<GenNode[]>('job/vartree/nodes');
    return response.data;
  }

  async getAxisTree(): Promise<GenNode[]> {
    const response = await this.axiosInstance.get<GenNode[]>('job/axistree/nodes');
    return response.data;
  }

  async getFunctionTree(): Promise<GenNode[]> {
    const response = await this.axiosInstance.get<GenNode[]>('job/function/nodes');
    return response.data;
  }

  public async initialize(): Promise<void> {
    // Implement the initialization logic here
    // For example:
    try {
      // Perform any necessary setup
      console.log('CarbonClient initialized successfully');
    } catch (error) {
      console.error('Failed to initialize CarbonClient:', error);
      throw error;
    }
  }

  async startJob(jobType: 'sentiment' | 'complaint-theme', data: any): Promise<any> {
    const response = await this.axiosInstance.post(`jobs/${jobType}`, data);
    return response.data;
  }

  async getMetadata(jobId: string): Promise<any> {
    const response = await this.axiosInstance.get(`Surveys/${jobId}/Metadata`);
    return response.data;
  }

  async getInterviews(jobId: string): Promise<any> {
    const response = await this.axiosInstance.get(`Surveys/${jobId}/Interviews`);
    return response.data;
  }

  async deleteJob(jobId: string): Promise<void> {
    await this.axiosInstance.delete(`Surveys/${jobId}`);
  }

  // Add this method to the CarbonClient class
  async updateReportDisplay(options: QuickUpdateRequest): Promise<XlsxResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    const response = await this.axiosInstance.post<XlsxResponse>('job/report/quickupdate', options);
    return response.data;
  }

  // Add this public method to check if a job is open
  public isJobCurrentlyOpen(): boolean {
    return this.isJobOpen && this.currentCustomer !== null && this.currentJob !== null;
  }

  async getCurrentSpec(): Promise<RunSpecRequest | null> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const response = await this.axiosInstance.get<RunSpecRequest>('job/spec/current');
      if (!response.data) {
        const savedSpec = localStorage.getItem('currentSpec');
        if (savedSpec) {
          return JSON.parse(savedSpec);
        }
        return null;
      }
      return {
        ...response.data,
        name: response.data.name || 'Unnamed Spec'
      };
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        console.warn('Current spec not found on server, checking localStorage');
        const savedSpec = localStorage.getItem('currentSpec');
        if (savedSpec) {
          return JSON.parse(savedSpec);
        }
        return null;
      }
      throw error;
    }
  }

  public getBaseUrl(): string {
    return BASE_URL;
  }

  public getHeaders(): Record<string, string> {
    return {
      'Content-Type': 'application/json',
      ...(this.sessionInfo?.sessionId ? { 'x-session-id': this.sessionInfo.sessionId } : {})
    };
  }

  async runSpecification(request: RunSpecRequest): Promise<GenericResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    console.log('Sending runSpecification request to:', `${this.axiosInstance.defaults.baseURL}job/spec`);
    console.log('Request data:', JSON.stringify(request, null, 2));
    try {
      const response = await this.axiosInstance.post<GenericResponse>('job/spec', request);
      console.log('RunSpecification response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error in runSpecification:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response data:', error.response?.data);
        console.error('Response status:', error.response?.status);
      }
      throw error;
    }
  }

  async saveCurrentSpec(spec: RunSpecRequest): Promise<void> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      await this.axiosInstance.post('job/spec/save', spec);
    } catch (error) {
      console.error('Error saving current spec:', error);
      throw error;
    }
  }

  public validateSpec = async (spec: RunSpecRequest['spec']): Promise<GenericResponse> => {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    console.log('Sending validateSpec request to:', `${this.axiosInstance.defaults.baseURL}job/spec/validate/spec`);
    console.log('Request data:', JSON.stringify(spec, null, 2));
    try {
      const response = await this.axiosInstance.post<GenericResponse>('job/spec/validate/spec', spec);
      console.log('ValidateSpec response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error in validateSpec:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response data:', error.response?.data);
        console.error('Response status:', error.response?.status);
      }
      throw error;
    }
  }

  // Add this method to your CarbonClient class
  async getNewSpec(): Promise<RunSpecRequest> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const response = await this.axiosInstance.get<RunSpecRequest>('job/spec/new');
      console.log('New spec response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error getting new spec:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response data:', error.response?.data);
        console.error('Response status:', error.response?.status);
      }
      throw error;
    }
  }

  public async saveReport(name: string, sub: string): Promise<GenericResponse> {
    if (!this.isAuthenticated()) {
      console.error('Authentication check failed in saveReport');
      throw new Error('Not authenticated');
    }
    console.log('Sending saveReport request with name:', name, 'and sub (folder):', sub);
    try {
      const response = await this.axiosInstance.post<GenericResponse>('job/report/save', { name, sub });
      console.log('SaveReport response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error in saveReport:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response data:', error.response?.data);
        console.error('Response status:', error.response?.status);
        console.error('Response headers:', error.response?.headers);
        throw new Error(`SaveReport failed: ${error.response?.status} - ${JSON.stringify(error.response?.data)}`);
      }
      throw new Error(`SaveReport failed: ${(error as Error).message}`);
    }
  }

  async setOutputFormat(format: string): Promise<void> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      await this.axiosInstance.post('job/report/format', { format });
    } catch (error) {
      console.error('Error setting output format:', error);
      throw error;
    }
  }

  private createDefaultGenTabRequest(reportName: string): GenTabRequest {
    return {
      name: reportName,
      top: '',
      side: '',
      sProps: {
        caseFilter: null,
        initAsMissing: false,
        excludeNE: false,
        padHierarchics: false,
        arithOverStats: false,
        topInsert: null,
        sideInsert: null,
        level: null,
        fullStats: false // Add this line
      },
      dProps: {}
    };
  }

  async getReportHtml(reportName: string): Promise<string> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const genTabRequest = this.createDefaultGenTabRequest(reportName);
      const response = await this.axiosInstance.post<string>('job/gentab', genTabRequest);
      return response.data;
    } catch (error) {
      console.error('Error getting HTML report:', error);
      throw error;
    }
  }

  async GenTab(request: GenTabRequest, format: string | number = 'HTML'): Promise<any> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      let url: string;
      if (format === 'JSON') {
        url = `${this.getBaseUrl()}/report/gentab/pandas/1`;
      } else {
        url = `${this.getBaseUrl()}/report/gentab/text/${format}`;
      }
      const response = await this.axiosInstance.post<any>(url, request);
      return response.data;
    } catch (error) {
      console.error('Error generating report:', error);
      throw error;
    }
  }

  async getReportText(reportName: string, format: string): Promise<string> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const genTabRequest = this.createDefaultGenTabRequest(reportName);
      const response = await this.axiosInstance.post<string>(`report/gentab/text/${format}`, genTabRequest);
      return response.data;
    } catch (error) {
      console.error(`Error generating ${format} report:`, error);
      throw error;
    }
  }

  async getReportPandas(reportName: string, shape: number): Promise<string> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const response = await this.axiosInstance.post<string>(`report/gentab/pandas/${shape}`, {
        name: reportName
      });
      return response.data;
    } catch (error) {
      console.error('Error generating Pandas report:', error);
      throw error;
    }
  }

  private async ensureJobOpen(customerName: string, jobName: string): Promise<void> {
    if (!this.isJobOpen) {
      await this.openCloudJob(customerName, jobName);
      this.isJobOpen = true;
    }
  }

  async getProperties(customerName: string, jobName: string): Promise<XDisplayProperties> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    await this.ensureJobOpen(customerName, jobName);
    try {
      const response = await this.axiosInstance.get<XDisplayProperties>('job/props');
      return response.data;
    } catch (error) {
      console.error('Error fetching properties:', error);
      throw error;
    }
  }

  async generateXlsx(): Promise<XlsxResponse> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      console.log('Generating XLSX report');
      const response = await this.axiosInstance.get<XlsxResponse>('job/report/xlsx');
      console.log('Generate XLSX response:', response.data);
      if (response.data.reportName) {
        console.log(`XLSX generated for report: ${response.data.reportName}`);
      } else {
        console.warn('XLSX generated, but no report name provided in the response');
      }
      return response.data;
    } catch (error) {
      console.error('Error generating XLSX report:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Error response:', error.response.data);
        throw new Error(`Failed to generate XLSX report: ${error.response.data.message || error.message}`);
      }
      throw new Error(`Failed to generate XLSX report: ${(error as Error).message}`);
    }
  }

  async GenTabAsHTML(top: string, side: string, flt?: string, wgt?: string): Promise<string> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      console.log('Sending GenTabAsHTML request with params:', { top, side, flt, wgt });
      console.log('Session ID:', this.sessionInfo?.sessionId);
      console.log('Is job open:', this.isJobOpen);
      console.log('Current customer:', this.currentCustomer);
      console.log('Current job:', this.currentJob);

      const payload: { top: string; side: string; flt?: string; wgt?: string } = { top, side };
      if (flt) payload.flt = flt;
      if (wgt) payload.wgt = wgt;

      const response = await this.axiosInstance.post<any>('report/gentab/html', payload);
      console.log('GenTabAsHTML raw response:', response.data);
      
      if (Array.isArray(response.data)) {
        return response.data.join('\n');
      } else if (typeof response.data === 'string') {
        return response.data;
      } else if (response.data && typeof response.data === 'object') {
        if (response.data.message) {
          throw new Error(response.data.message);
        } else {
          return JSON.stringify(response.data);
        }
      } else {
        console.warn('Unexpected response type from GenTabAsHTML:', typeof response.data);
        return JSON.stringify(response.data);
      }
    } catch (error) {
      console.error('Error in GenTabAsHTML:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response data:', error.response?.data);
        console.error('Response status:', error.response?.status);
        console.error('Response headers:', error.response?.headers);
        if (error.response?.data && error.response.data.message) {
          throw new Error(`GenTabAsHTML failed: ${error.response.data.message}`);
        }
      }
      throw new Error(`GenTabAsHTML failed: ${(error as Error).message}`);
    }
  }

  async AxisSyntaxToNodes(syntax: string): Promise<GenNode[]> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const response = await this.axiosInstance.post<GenNode[]>('report/axis/syntaxtonodes', { syntax });
      return response.data;
    } catch (error) {
      console.error('Error in AxisSyntaxToNodes:', error);
      throw error;
    }
  }

  async AxisNodesToSyntax(gn: GenNode[]): Promise<string> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const response = await this.axiosInstance.post<string>('report/axis/nodestosyntax', { nodes: gn });
      return response.data;
    } catch (error) {
      console.error('Error in AxisNodesToSyntax:', error);
      throw error;
    }
  }

  async CurrentSyntax(): Promise<string> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const response = await this.axiosInstance.get<string>('report/syntax/current');
      return response.data;
    } catch (error) {
      console.error('Error in CurrentSyntax:', error);
      throw error;
    }
  }

  async ValidateSyntax(syntax: string): Promise<string> {
    if (!this.isAuthenticated()) {
      throw new Error('Not authenticated');
    }
    try {
      const response = await this.axiosInstance.post<string>('report/syntax/validate', { syntax });
      return response.data;
    } catch (error) {
      console.error('Error in ValidateSyntax:', error);
      throw error;
    }
  }

  async getChildNodes(nodeId: string): Promise<TocNode[]> {
    // Implement the logic to fetch child nodes from the server
    // This is a placeholder implementation
    const response = await this.axiosInstance.get(`/toc/children/${nodeId}`);
    return response.data;
  }
}