import React from 'react'
import SpecLayout from '../components/layout/SpecLayout'

const NewSpecPage: React.FC = () => {
  return (
    <SpecLayout>
      {/* Page content goes here */}
    </SpecLayout>
  )
}

export default NewSpecPage
