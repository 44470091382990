import React, { useState } from 'react';
import { Box, Paper, Typography, Button, Grid, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from '@mui/material';
import { DataGridPremium, GridColDef, GridRowsProp } from '@mui/x-data-grid-premium';

interface Run {
  id: number;
  variables: string;
  themes: string;
  status: string;
  actions: string[];
}

const Runs: React.FC = () => {
  const [runs, setRuns] = useState<Run[]>([
    { id: 1, variables: 'n5a, x, y', themes: 'Cost, Service, ...', status: 'Running', actions: ['cancel'] },
    { id: 2, variables: 'n5a, x, y', themes: 'Cost, Service, ...', status: 'Finished: written to n5a_themes. Took 17 minutes', actions: ['Run on new data', 'Clone'] },
  ]);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleAction = (action: string, runId: number) => {
    console.log(`Action ${action} triggered for run ${runId}`);
    if (action === 'Run on new data') {
      setIsProcessing(true);
      // Simulate processing
      setTimeout(() => {
        setIsProcessing(false);
      }, 2000);
    }
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      height: 'calc(100vh - 84px)',
      mt: '84px',
      overflow: 'hidden',
      pl: 2,
      pr: 3,
      py: 2,
    }}>
      <Typography variant="h4" gutterBottom>Bayes Price Runs</Typography>
      
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Variables</TableCell>
                    <TableCell>Themes</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {runs.map((run) => (
                    <TableRow key={run.id}>
                      <TableCell>{run.variables}</TableCell>
                      <TableCell>{run.themes}</TableCell>
                      <TableCell>{run.status}</TableCell>
                      <TableCell>
                        {run.actions.map((action) => (
                          <Button
                            key={action}
                            variant="outlined"
                            size="small"
                            onClick={() => handleAction(action, run.id)}
                            sx={{ mr: 1 }}
                          >
                            {action}
                          </Button>
                        ))}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>

      {isProcessing && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default Runs;