import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  Paper,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useJob } from '../contexts/JobContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RichTreeViewPro, TreeItem } from '@mui/x-tree-view-pro';
import { alpha } from '@mui/material/styles';
import { GenTabRequest } from '../types/types';

interface TreeItem {
  id: string;
  label: string;
  children?: TreeItem[];
}

const HtmlTable: React.FC = () => {
  const { selectedCustomer, selectedJob, setSelectedCustomer, setSelectedJob } = useJob();
  const { carbonClient, isAuthenticated, isClientReady } = useAuth();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [customers, setCustomers] = useState<string[]>([]);
  const [jobs, setJobs] = useState<string[]>([]);
  const [reportName, setReportName] = useState<string>('');
  const [top, setTop] = useState<string>('');
  const [side, setSide] = useState<string>('');
  const [filter, setFilter] = useState<string>('');
  const [weight, setWeight] = useState<string>('');
  const [exportFormat, setExportFormat] = useState<string>('HTML');
  const [htmlReport, setHtmlReport] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const exportFormats = ['HTML', 'TSV', 'CSV', 'SSV', 'XML', 'OXT', 'OXTNums', 'MultiCube', 'JSON'];

  const handleCustomerChange = (e: SelectChangeEvent<string>) => {
    setSelectedCustomer(e.target.value);
    setSelectedJob('');
  };

  const handleJobChange = (e: SelectChangeEvent<string>) => {
    setSelectedJob(e.target.value);
  };

  const handleExportFormatChange = (e: SelectChangeEvent<string>) => {
    setExportFormat(e.target.value);
  };

  const fetchData = async () => {
    if (isAuthenticated && carbonClient && isClientReady && selectedCustomer && selectedJob) {
      try {
        await carbonClient.openCloudJob(selectedCustomer, selectedJob);
      } catch (error) {
        console.error('Error fetching data:', error);
        setErrorMessage(`Error fetching data: ${(error as Error).message}`);
      }
    } else if (!isAuthenticated && isClientReady) {
      setErrorMessage("Not authenticated. Please log in and try again.");
    }
  };

  const handleGenerateReport = async () => {
    if (!carbonClient || !isClientReady) {
      setErrorMessage('Carbon client is not ready');
      return;
    }

    try {
      setIsLoading(true);
      setErrorMessage(null);

      const genTabRequest: GenTabRequest = {
        name: reportName || 'HTML Table Report',
        top,
        side,
        filter,
        weight,
        sProps: {
          caseFilter: null,
          initAsMissing: false,
          excludeNE: false,
          padHierarchics: false,
          arithOverStats: false,
          topInsert: null,
          sideInsert: null,
          level: null,
          fullStats: false
        },
        dProps: {}
      };

      console.log('generateReport - Request Payload:', JSON.stringify(genTabRequest, null, 2));

      const result = await carbonClient.GenTab(genTabRequest, exportFormat);

      console.log('generateReport - Response:', result);
      setHtmlReport(result);
    } catch (error) {
      console.error('Error generating report:', error);
      setErrorMessage(`Error generating report: ${(error as Error).message}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCustomer && selectedJob) {
      fetchData();
    }
  }, [selectedCustomer, selectedJob, isAuthenticated, carbonClient, isClientReady]);

  useEffect(() => {
    const fetchCustomers = async () => {
      if (carbonClient && isClientReady) {
        try {
          const customerList = await carbonClient.getCustomers();
          setCustomers(customerList);
        } catch (error) {
          console.error('Error fetching customers:', error);
          setErrorMessage(`Error fetching customers: ${(error as Error).message}`);
        }
      }
    };

    fetchCustomers();
  }, [carbonClient, isClientReady]);

  useEffect(() => {
    const fetchJobs = async () => {
      if (carbonClient && isClientReady && selectedCustomer) {
        try {
          const jobList = await carbonClient.getJobs(selectedCustomer);
          setJobs(jobList);
        } catch (error) {
          console.error('Error fetching jobs:', error);
          setErrorMessage(`Error fetching jobs: ${(error as Error).message}`);
        }
      }
    };

    fetchJobs();
  }, [carbonClient, isClientReady, selectedCustomer]);

  const reportStyles = `
    .Tab1 { background-color: #ffffff; border-collapse: collapse; width: 100%; }
    .Tab1 td { padding: 8px; border: 1px solid #c0c0c0; text-align: center; }
    .Key { background-color: #f0f0f0; }
    .NameTitle, .TopTitle, .SideTitle, .FiltTitle, .WghtTitle, .StatusTitle {
      font-family: Arial, sans-serif;
      font-size: 12pt;
      color: #333;
      margin-bottom: 5px;
      text-align: left;
    }
    .TopGroup, .TopLabel, .SideGroup, .SideLabel {
      font-family: Arial, sans-serif;
      font-size: 11pt;
      font-weight: bold;
      text-align: center;
      background-color: #f0f0f0;
    }
    .SideLabel { text-align: right; }
    .Freq {
      font-family: Arial, sans-serif;
      font-size: 10pt;
      text-align: center;
      background-color: #fff;
      width: 100px; /* Set a fixed width for frequency columns */
    }
    .ColPC { background-color: #e6f3ff; }
    .RowPC { background-color: #fff0f0; }
    .Sig1 { color: #FF0000; }
    .Sig2 { color: #0000FF; }
    .Sig3 { color: #008000; }
    .SideGroup { width: 20%; }
    .SideLabel { width: 20%; }
  `;

  const renderReport = () => {
    if (!htmlReport) return null;

    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlReport, 'text/html');

    const titleDivs = doc.querySelectorAll('.NameTitle, .TopTitle, .SideTitle, .FiltTitle, .WghtTitle, .StatusTitle');
    const titleContent = Array.from(titleDivs).map(div => div.outerHTML).join('');

    const tableContent = doc.querySelector('.Tab1')?.outerHTML || '';

    // Extract the table name from the NameTitle field
    const nameTitle = doc.querySelector('.NameTitle');
    const tableName = nameTitle ? nameTitle.textContent?.replace('Name:', '').trim() : 'Untitled Table';

    return (
      <>
        <Accordion defaultExpanded={false}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              Table Summary: {tableName}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div dangerouslySetInnerHTML={{ __html: titleContent }} />
          </AccordionDetails>
        </Accordion>
        <Box sx={{ mt: 2, '& table': { width: '100%' } }}>
          <div dangerouslySetInnerHTML={{ __html: tableContent }} />
        </Box>
      </>
    );
  };

  const initialTreeItems: TreeItem[] = [
    {
      id: '1',
      label: 'Item 1',
      children: [
        { id: '1-1', label: 'Sub-item 1-1' },
        { id: '1-2', label: 'Sub-item 1-2' },
      ],
    },
    {
      id: '2',
      label: 'Item 2',
      children: [
        { id: '2-1', label: 'Sub-item 2-1' },
        { id: '2-2', label: 'Sub-item 2-2' },
      ],
    },
    {
      id: '3',
      label: 'Item 3',
      children: [
        { id: '3-1', label: 'Sub-item 3-1' },
        { id: '3-2', label: 'Sub-item 3-2' },
      ],
    },
    {
      id: '4',
      label: 'Item 4',
      children: [
        { id: '4-1', label: 'Sub-item 4-1' },
        { id: '4-2', label: 'Sub-item 4-2' },
      ],
    },
    {
      id: '5',
      label: 'Item 5',
      children: [
        { id: '5-1', label: 'Sub-item 5-1' },
        { id: '5-2', label: 'Sub-item 5-2' },
      ],
    },
  ];

  const [treeItems, setTreeItems] = useState<TreeItem[]>(initialTreeItems);
  const [dragItem, setDragItem] = useState<TreeItem | null>({ id: 'drag-item', label: 'Drag me!' });
  const [dragOverItemId, setDragOverItemId] = useState<string | null>(null);

  const resetTree = () => {
    setTreeItems(initialTreeItems);
    setDragItem({ id: 'drag-item', label: 'Drag me!' });
  };

  const handleItemsChange = (newItems: TreeItem[]) => {
    setTreeItems(newItems);
    // Remove the drag item if it's been added to the tree
    if (newItems.some(item => item.id === dragItem?.id)) {
      setDragItem(null);
    }
  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    const droppedItemId = event.dataTransfer.getData('text/plain');
    if (droppedItemId === dragItem?.id) {
      const newTreeItems = [...treeItems, dragItem];
      setTreeItems(newTreeItems);
      setDragItem(null);
    }
  };

  const handleTreeDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    const targetId = (event.target as HTMLElement).closest('[data-id]')?.getAttribute('data-id') || null;
    setDragOverItemId(targetId);
  };

  const handleTreeDrop = (event: React.DragEvent) => {
    event.preventDefault();
    const droppedItemId = event.dataTransfer.getData('text/plain');
    const targetId = (event.target as HTMLElement).closest('[data-id]')?.getAttribute('data-id') || null;
    setDragOverItemId(null);

    if (droppedItemId === dragItem?.id && targetId) {
      const newTreeItems = insertItemIntoTree(treeItems, targetId, dragItem);
      setTreeItems(newTreeItems);
      setDragItem(null);
    } else if (droppedItemId === dragItem?.id) {
      // If dropped on an empty area, add to the end of the root level
      setTreeItems([...treeItems, dragItem]);
      setDragItem(null);
    }
  };

  const insertItemIntoTree = (items: TreeItem[], targetId: string, newItem: TreeItem): TreeItem[] => {
    return items.map(item => {
      if (item.id === targetId) {
        // Insert the new item before the target item
        return [newItem, item];
      }
      if (item.children) {
        const newChildren = insertItemIntoTree(item.children, targetId, newItem);
        if (newChildren !== item.children) {
          return { ...item, children: newChildren };
        }
      }
      return item;
    }).flat();
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      height: 'calc(100vh - 84px)',
      mt: '84px',
      overflow: 'hidden',
      pl: 2,
      pr: 3,
      py: 2,
    }}>
      {/* Left sidebar - Customer, Job, and Report Generation */}
      <Paper sx={{ 
        width: 300, 
        p: 2, 
        mr: 2,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Typography variant="h6" gutterBottom>Report Configuration</Typography>
        <FormControl fullWidth size="small" sx={{ mb: 2 }}>
          <InputLabel>Customer</InputLabel>
          <Select
            value={selectedCustomer || ''}
            label="Customer"
            onChange={handleCustomerChange}
          >
            {customers.map((customer) => (
              <MenuItem key={customer} value={customer}>{customer}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth size="small" sx={{ mb: 2 }}>
          <InputLabel>Job</InputLabel>
          <Select
            value={selectedJob || ''}
            label="Job"
            onChange={handleJobChange}
            disabled={!selectedCustomer}
          >
            {jobs.map((job) => (
              <MenuItem key={job} value={job}>{job}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label="Report Name"
          value={reportName}
          onChange={(e) => setReportName(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Top"
          value={top}
          onChange={(e) => setTop(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Side"
          value={side}
          onChange={(e) => setSide(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Filter"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Weight"
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Export Format</InputLabel>
          <Select
            value={exportFormat}
            onChange={handleExportFormatChange}
            label="Export Format"
          >
            {exportFormats.map(format => (
              <MenuItem key={format} value={format}>{format}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          onClick={handleGenerateReport}
          disabled={!selectedCustomer || !selectedJob || !top || !side}
          sx={{ mt: 2 }}
          fullWidth
        >
          Generate Report
        </Button>
      </Paper>

      {/* Right side - Report Display and Tree View */}
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        {/* Top section - Report Display */}
        <Paper sx={{ 
          flex: 1,
          p: 2, 
          mb: 2, 
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column'
        }}>
          {isLoading && <Typography>Loading...</Typography>}
          {errorMessage && (
            <Typography color="error" sx={{ mb: 2 }}>
              Error: {errorMessage}
            </Typography>
          )}
          {htmlReport ? (
            <>
              {exportFormat === 'HTML' ? (
                <Box sx={{ '& *': { fontFamily: 'Arial, sans-serif' } }}>
                  <style>{reportStyles}</style>
                  {renderReport()}
                </Box>
              ) : (
                <pre>{htmlReport}</pre>
              )}
            </>
          ) : (
            <Typography>No report generated yet. Use the form to generate a report.</Typography>
          )}
        </Paper>

        {/* Bottom section - Tree View */}
        <Paper sx={{ 
          height: '40%', 
          p: 2, 
          overflowY: 'auto'
        }}>
          <Typography variant="h6" sx={{ mb: 2 }}>Example RichTreeViewPro</Typography>
          {dragItem && (
            <Paper
              sx={{
                p: 1,
                mb: 2,
                cursor: 'move',
                display: 'inline-block',
                '&:hover': { backgroundColor: 'action.hover' }
              }}
              draggable
              onDragStart={(e) => e.dataTransfer.setData('text/plain', dragItem.id)}
            >
              {dragItem.label}
            </Paper>
          )}
          <Button onClick={resetTree} variant="outlined" sx={{ mb: 2, ml: 2 }}>
            Reset Tree
          </Button>
          <Box
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            sx={{ border: '1px dashed grey', p: 2 }}
          >
            <RichTreeViewPro
              items={treeItems}
              defaultExpandedItems={['1', '2', '3', '4', '5']}
              getItemLabel={(item) => item.label}
              itemsReordering
              experimentalFeatures={{
                indentationAtItemLevel: true,
                itemsReordering: true
              }}
              sx={{
                '& [data-id]': {
                  '&:hover': { backgroundColor: 'action.hover' },
                  ...(dragOverItemId && {
                    [`&[data-id="${dragOverItemId}"]`]: {
                      backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        height: '2px',
                        backgroundColor: 'primary.main',
                      },
                    },
                  }),
                },
              }}
              onDragOver={handleTreeDragOver}
              onDrop={handleTreeDrop}
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default HtmlTable;