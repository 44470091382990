import React from 'react'
import HomePage from '../pages/Home'
import ContactPage from '../pages/Contact'
import LoginPage from '../pages/Login'
import ReportPage from '../pages/Report'
import SpecificationPage from '../pages/Specification'
import TSAPIHome from '../pages/TSAPIHome'
import ExperimentsPage from '../pages/Experiments'
import HtmlTablePage from '../pages/HtmlTable'
import NewSpecPage from '../pages/NewSpec'
import TextAnalysisPage from '../pages/TextAnalysis'
import RunsPage from '../pages/Runs'
import CoPilotPage from '../pages/CoPilot'
import { PlayArrow } from '@mui/icons-material'

import {
  Home,
  ListAlt,
  Science,
  ContactSupport,
  TableChart,
  Report,
  Api,
  Settings,
  Logout,
  Login
} from '@mui/icons-material'

export interface IApplicationRoute {
  name: string
  path: string
  component: React.ComponentType
  meta: Record<string, any>
  showInMenu?: boolean
  protected?: boolean
  icon?: React.ComponentType
}

const routes: IApplicationRoute[] = [
  {
    name: 'home',
    path: '/',
    component: HomePage,
    meta: {
      title: 'Home',
    },
    showInMenu: true,
    protected: true,
    icon: Home
  },
  {
    name: 'contact',
    path: '/contact',
    component: ContactPage,
    meta: {
      title: 'Contact',
    },
    protected: true,
    showInMenu: false,
    icon: ContactSupport
  },
  {
    name: 'login',
    path: '/login',
    component: LoginPage,
    meta: { title: 'Login' },
    protected: false,
    showInMenu: false,
    icon: Login
  },
  {
    name: 'report',
    path: '/report',
    component: ReportPage,
    meta: { title: 'Report' },
    protected: true,
    showInMenu: true,
    icon: Report
  },
  {
    name: 'specification',
    path: '/specification',
    component: SpecificationPage,
    meta: { title: 'Specification' },
    protected: true,
    showInMenu: true,
    icon: ListAlt
  },
  {
    name: 'tsapi',
    path: '/tsapi',
    component: TSAPIHome,
    meta: { title: 'TSAPI' },
    protected: true,
    showInMenu: false,
    icon: Api
  },
  {
    name: 'new-spec',
    path: '/new-spec',
    component: NewSpecPage,
    meta: { title: 'New Spec' },
    protected: true,
    showInMenu: true,
    icon: ListAlt
  },
  {
    name: 'experiments',
    path: '/experiments',
    component: ExperimentsPage,
    meta: { title: 'Experiments' },
    protected: true,
    showInMenu: true,
    icon: Science
  },
  {
    name: 'html-table',
    path: '/html-table',
    component: HtmlTablePage,
    meta: { title: 'HTML Table' },
    protected: true,
    showInMenu: true,
    icon: TableChart
  },
  {
    name: 'text-analysis',
    path: '/text-analysis',
    component: TextAnalysisPage,
    meta: { title: 'Text Analysis' },
    protected: true,
    showInMenu: true,
    icon: TableChart
  },
  {
    name: 'copilot',
    path: '/copilot',
    component: CoPilotPage,
    meta: { title: 'CoPilot' },
    protected: true,
    showInMenu: true,
    icon: TableChart
  },
  {
    name: 'runs',
    path: '/runs',
    component: RunsPage,
    meta: { title: 'Runs' },
    protected: true,
    showInMenu: true,
    icon: PlayArrow
  }
]

export default routes