import React, { useEffect, useState } from 'react'
import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useAuth } from '../contexts/AuthContext'
import { useJob } from '../contexts/JobContext'
import { useSpec } from '../contexts/SpecContext'
import { XDisplayProperties } from '../types/types'

interface DebugModalProps {
  open: boolean
  onClose: () => void
}

const DebugModal: React.FC<DebugModalProps> = ({ open, onClose }) => {
  const { carbonClient } = useAuth()
  const { selectedCustomer, selectedJob, jobInfo, dProps } = useJob()
  const { currentSpec, specAggregate } = useSpec()
  const [debugData, setDebugData] = useState<any>(null)
  const [loading, setLoading] = useState(true)
  const [localStorageData, setLocalStorageData] = useState<Record<string, any>>({})

  useEffect(() => {
    if (open) {
      fetchDebugData()
      fetchLocalStorageData()
    }
  }, [open, selectedCustomer, selectedJob, jobInfo, dProps, currentSpec, specAggregate])

  const fetchDebugData = async () => {
    setLoading(true)
    try {
      // Load dProps from localStorage
      const savedDProps = localStorage.getItem('dProps');
      const loadedDProps: XDisplayProperties | null = savedDProps ? JSON.parse(savedDProps) : null;

      const debugInfo = {
        displayTable: currentSpec?.dProps || {},
        dProps: loadedDProps || dProps || {}, // Use loaded dProps if available, otherwise use from context
        sProps: currentSpec?.spec?.specProperties || {},
        job: selectedJob || '',
        customer: selectedCustomer || '',
        tableSpec: currentSpec?.spec || {},
        jobInfo: jobInfo || {},
        specAggregate: specAggregate || {}, // This now comes directly from the context, not localStorage
      }
      
      console.log('Debug info:', debugInfo)
      setDebugData(debugInfo)
    } catch (error) {
      console.error('Error fetching debug data:', error)
      setDebugData(null)
    } finally {
      setLoading(false)
    }
  }

  const fetchLocalStorageData = () => {
    const data: Record<string, any> = {};
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key) {
        try {
          data[key] = JSON.parse(localStorage.getItem(key) || '');
        } catch {
          data[key] = localStorage.getItem(key);
        }
      }
    }
    setLocalStorageData(data);
  }

  const renderDebugSection = (title: string, data: any) => (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
          <code>
            {JSON.stringify(data, null, 2)}
          </code>
        </pre>
      </AccordionDetails>
    </Accordion>
  )

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: 800,
          maxHeight: '90vh',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          overflowY: 'auto',
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Debug Information
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : debugData ? (
          <>
            {renderDebugSection('DisplayTable', debugData.displayTable)}
            {renderDebugSection('dProps', localStorage.getItem('dProps'))}
            {renderDebugSection('sProps', debugData.sProps)}
            {renderDebugSection('Job', debugData.job)}
            {renderDebugSection('Customer', debugData.customer)}
            {renderDebugSection('Current Spec', localStorage.getItem('currentSpec'))}
            {renderDebugSection('JobInfo', debugData.jobInfo)}
            {renderDebugSection('SpecAggregate', debugData.specAggregate)}
            {renderDebugSection('LocalStorage', localStorageData)}
          </>
        ) : (
          <Typography color="error">Failed to load debug data</Typography>
        )}
        <Button onClick={onClose} sx={{ mt: 2 }}>
          Close
        </Button>
      </Box>
    </Modal>
  )
}

export default DebugModal