import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import theme from './config/theme'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { AuthProvider, useAuth } from './contexts/AuthContext'
import { TSAPIProvider } from './contexts/TSAPIContext'
import { CarbonClient } from './services/CarbonClient'
import Layout from './components/layout/MainLayout'
import SpecLayout from './components/layout/SpecLayout'
import routes from './routes/routes'
import TSAPIHome from './pages/TSAPIHome'
import { JobProvider } from './contexts/JobContext'
import { SpecProvider } from './contexts/SpecContext'
import './styles/q7themes.css'

// Comment: Creating a ProtectedRoute component to handle authentication
const ProtectedRoute: React.FC<{ element: React.ReactElement }> = ({ element }) => {
  const { isAuthenticated } = useAuth()
  return isAuthenticated ? element : <Navigate to="/login" replace />
}

const App: React.FC = () => {
  const carbonClient = new CarbonClient()

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AuthProvider>
          <JobProvider>
            <SpecProvider>
              <TSAPIProvider carbonClient={carbonClient}>
                <Routes>
                  <Route
                    path="*"
                    element={
                      <SpecLayout>
                        <Routes>
                          {routes.map((route) => (
                            <Route
                              key={route.name}
                              path={route.path}
                              element={
                                route.protected ? (
                                  <ProtectedRoute element={<route.component />} />
                                ) : (
                                  <route.component />
                                )
                              }
                            />
                          ))}
                          <Route path="/tsapi" element={<TSAPIHome />} />
                          <Route path="*" element={<div>Page Not Found</div>} />
                        </Routes>
                      </SpecLayout>
                    }
                  />
                </Routes>
              </TSAPIProvider>
            </SpecProvider>
          </JobProvider>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  )
}

export default App
