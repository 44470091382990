import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography, Box, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ParsedXlsxData } from '../utils/xlsxUtils';

interface XlsxTableProps {
  data: ParsedXlsxData;
  reportTitle: string;
  showFreq: boolean;
  showColPct: boolean;
  showRowPct: boolean;
  showSig: boolean;
  isUpdating: boolean;
  optionsChanged: boolean;
  onOptionToggle: (option: 'freq' | 'colPct' | 'rowPct' | 'sig') => void;
  onUpdateReport: () => void;
}

export function XlsxTable({ 
  data, 
  reportTitle, 
  showFreq, 
  showColPct, 
  showRowPct, 
  showSig, 
  isUpdating, 
  optionsChanged,
  onOptionToggle, 
  onUpdateReport 
}: XlsxTableProps) {
  const { data: tableData, metadata } = data;
  const [expanded, setExpanded] = useState<boolean>(false);

  // Function to check if a row is empty (all cells have empty values)
  const isRowEmpty = (row: any[]) => row.every(cell => cell.value === '');

  // Function to get the last non-empty row index
  const getLastNonEmptyRowIndex = (data: any[][]) => {
    for (let i = data.length - 1; i >= 0; i--) {
      if (!isRowEmpty(data[i])) {
        return i;
      }
    }
    return -1;
  };

  const lastNonEmptyRowIndex = getLastNonEmptyRowIndex(tableData);

  // Check if the first cell of the first row is empty
  const hideFirstColumnInFirstTable = tableData.length > 0 && tableData[0].length > 0 && tableData[0][0].value === '';

  // Function to render a section of the table (either the first 6 rows or the remaining rows)
  const renderTableSection = (startRow: number, endRow: number, isFirstTable: boolean) => {
    const rows = tableData.slice(startRow, Math.min(endRow, lastNonEmptyRowIndex + 1));
    const maxColumns = Math.max(...rows.map(row => row.length));

    // Function to check if a column is empty
    const isColumnEmpty = (colIndex: number) => {
      return rows.every(row => !row[colIndex] || row[colIndex].value === '');
    };

    // Find the last non-empty column for the first table
    let lastContentColumn = maxColumns - 1;
    if (isFirstTable) {
      for (let i = 0; i < maxColumns; i++) {
        if (!isColumnEmpty(i) && rows[0][i].value !== '') {
          lastContentColumn = i;
          break;
        }
      }
    }

    // Generate an array of visible column indices
    const visibleColumns = Array.from({ length: maxColumns }, (_, index) => index)
      .filter(colIndex => {
        if (isFirstTable) {
          return colIndex <= lastContentColumn && (!hideFirstColumnInFirstTable || colIndex !== 0);
        }
        return !isColumnEmpty(colIndex);
      });

    // Calculate column widths based on merged cells
    const columnWidths = new Array(maxColumns).fill(120); // Default width
    rows.forEach(row => {
      row.forEach((cell, colIndex) => {
        if (cell.colSpan > 1) {
          const width = 120 * cell.colSpan;
          for (let i = 0; i < cell.colSpan; i++) {
            columnWidths[colIndex + i] = width / cell.colSpan;
          }
        }
      });
    });

    // Adjust first column width
    columnWidths[0] = 160;

    return (
      <TableContainer 
        component={Paper} 
        elevation={0} 
        sx={{ 
          mb: 2,
          boxShadow: isFirstTable ? 'none' : undefined,
          border:  isFirstTable ? 'none' : '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: '0px',
        }}
      >
        <Table 
          sx={{ 
            minWidth: 650, 
            tableLayout: 'fixed',
            '& td, & th': isFirstTable ? {
              border: 'none',
            } : {},
          }} 
          size="small"
        >
          <colgroup>
            {visibleColumns.map((colIndex) => (
              <col key={colIndex} style={{ width: `${columnWidths[colIndex]}px` }} />
            ))}
          </colgroup>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow 
                key={rowIndex + startRow}
                // Remove hover prop entirely
              >
                {visibleColumns.map((colIndex) => {
                  const cell = row[colIndex];
                  if (!cell) return null;
                  if (isFirstTable && hideFirstColumnInFirstTable && colIndex === 0) return null;

                  const cellAlign = isFirstTable ? 'left' : (cell.rowSpan > 1 ? 'right' : 'center');
                  const isMergedCell = cell.rowSpan > 1 || cell.colSpan > 1;
                  const containsSpecialText = cell.value.includes('Total') || cell.value.includes('Count') || cell.value.includes('Weighting Variable');
                  const shouldBeBold = isFirstTable || rowIndex === 0 || isMergedCell || containsSpecialText;
                  const shouldHaveBackground = !isFirstTable && (isMergedCell || containsSpecialText);

                  return (
                    <TableCell
                      key={colIndex}
                      align={cellAlign}
                      rowSpan={cell.rowSpan}
                      colSpan={cell.colSpan}
                      sx={{
                        fontWeight: shouldBeBold ? 'bold' : 'normal',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                        hyphens: 'auto',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: colIndex === 0 ? '200px' : `${columnWidths[colIndex]}px`,
                        backgroundColor: isFirstTable
                          ? 'inherit'
                          : shouldHaveBackground
                            ? 'action.hover'
                            : (rowIndex % 2 === 0 ? 'inherit' : 'action.selected'),
                        paddingLeft: isFirstTable ? '8px' : 'inherit',
                        border: isFirstTable ? 'none' : '1px solid rgba(224, 224, 224, 1)',
                      }}
                    >
                      <Typography 
                        variant="body2" 
                        style={{ 
                          fontWeight: shouldBeBold ? 'bold' : 'normal',
                          fontSize: '0.8rem', // This line reduces the font size by about 20%
                        }}
                      >
                        {cell.value}
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  // Calculate the split point for the two tables
  const splitPoint = Math.min(6, lastNonEmptyRowIndex + 1);

  // Add this function to format the report title
  const formatReportTitle = (title: string) => {
    const parts = title.split('/');
    const lastPart = parts[parts.length - 1];
    return lastPart.replace('.cbt', '');
  };

  return (
    <Box sx={{ overflowX: 'auto', maxWidth: '100%', position: 'relative' }}>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2, mb: 2 }}>
        <Box sx={{ position: 'relative', flexGrow: 1 }}>
          <Accordion 
            expanded={expanded} 
            onChange={() => setExpanded(!expanded)}
            sx={{
              border: '1px solid rgba(224, 224, 224, 1)',
              borderRadius: '4px',
              '&:before': {
                display: 'none',
              },
              '& .MuiAccordionSummary-root': {
                minHeight: '48px',
                '&.Mui-expanded': {
                  minHeight: '48px',
                },
              },
              '& .MuiAccordionSummary-content': {
                margin: '12px 0',
                '&.Mui-expanded': {
                  margin: '12px 0',
                },
              },
              '& .MuiAccordionDetails-root': {
                position: 'absolute',
                mt: .5,
                top: '100%',
                left: 0,
                right: 0,
                zIndex: 1,
                backgroundColor: 'white',
                border: '1px solid rgba(224, 224, 224, 1)',
                borderRadius: '4px',
                boxShadow: '0px 5px 16px rgba(0, 0, 0, 0.3)',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="subtitle1">
                {formatReportTitle(reportTitle)}: Table Summary
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ p: 1 }}> {/* Add padding around the table */}
                {renderTableSection(0, splitPoint, true)}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', flexShrink: 0 }}>
          <Button 
            size="small"
            variant={showFreq ? "contained" : "outlined"}
            onClick={() => onOptionToggle('freq')}
            sx={{ fontSize: '0.875rem', padding: '4px 8px', minWidth: '80px', height: '52px' }}
          >
            Freq
          </Button>
          <Button 
            size="small"
            variant={showColPct ? "contained" : "outlined"}
            onClick={() => onOptionToggle('colPct')}
            sx={{ fontSize: '0.875rem', padding: '4px 8px', minWidth: '80px', height: '52px' }}
          >
            Col %
          </Button>
          <Button 
            size="small"
            variant={showRowPct ? "contained" : "outlined"}
            onClick={() => onOptionToggle('rowPct')}
            sx={{ fontSize: '0.875rem', padding: '4px 8px', minWidth: '80px', height: '52px' }}
          >
            Row %
          </Button>
          <Button 
            size="small"
            variant={showSig ? "contained" : "outlined"}
            onClick={() => onOptionToggle('sig')}
            sx={{ fontSize: '0.875rem', padding: '4px 8px', minWidth: '80px', height: '52px' }}
          >
            Sig
          </Button>
          <Button 
            size="small"
            variant="contained" 
            color="primary"
            onClick={onUpdateReport}
            disabled={isUpdating || !optionsChanged}
            sx={{ fontSize: '0.875rem', padding: '4px 8px', minWidth: '80px', height: '52px' }}
          >
            {isUpdating ? 'Updating...' : 'RUN'}
          </Button>
        </Box>
      </Box>
      <Box sx={{ position: 'relative', zIndex: 0 }}>
        {lastNonEmptyRowIndex >= splitPoint && renderTableSection(splitPoint, lastNonEmptyRowIndex + 1, false)}
      </Box>
    </Box>
  );
}