import React from 'react'
import { Typography } from '@mui/material'

const Contact = () => {
  return (
    <div>
      <Typography variant="h4" component="h1" gutterBottom>
        Contact Us
      </Typography>
      {/* Add your contact page content */}
    </div>
  )
}

export default Contact