import React, { useState, useEffect } from 'react';
import { useTSAPI } from '../hooks/useTSAPI';
import { useAuth } from '../contexts/AuthContext';
import { Select, MenuItem, FormControl, InputLabel, Typography, Button, TextField, Box } from '@mui/material';

const TSAPIHome: React.FC = () => {
  const { startJob, getMetadata, getInterviews, deleteJob, loading, error, jobId } = useTSAPI();
  const { carbonClient, isClientReady } = useAuth();
  const [themeQuestion, setThemeQuestion] = useState('');
  const [themeOutput, setThemeOutput] = useState('');
  const [metadataOutput, setMetadataOutput] = useState('');
  const [interviewsOutput, setInterviewsOutput] = useState('');
  const [vmStatus, setVmStatus] = useState('');
  const [customers, setCustomers] = useState<string[]>([]);
  const [jobs, setJobs] = useState<string[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedJob, setSelectedJob] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchCustomers = async () => {
      if (carbonClient && isClientReady) {
        try {
          const customerList = await carbonClient.getCustomers();
          setCustomers(customerList);
        } catch (error) {
          setErrorMessage(`Error fetching customers: ${(error as Error).message}`);
        }
      }
    };
    fetchCustomers();
  }, [carbonClient, isClientReady]);

  useEffect(() => {
    const fetchJobs = async () => {
      if (carbonClient && isClientReady && selectedCustomer) {
        try {
          const jobList = await carbonClient.getJobs(selectedCustomer);
          setJobs(jobList);
        } catch (error) {
          setErrorMessage(`Error fetching jobs: ${(error as Error).message}`);
        }
      }
    };
    fetchJobs();
  }, [carbonClient, isClientReady, selectedCustomer]);

  const handleStartThemeClassification = async () => {
    const result = await startJob('complaint-theme', { themeQuestion });
    setThemeOutput(JSON.stringify(result, null, 2));
  };

  const handleStartSentimentAnalysis = async () => {
    const result = await startJob('sentiment', {});
    setThemeOutput(JSON.stringify(result, null, 2));
  };

  const handleGetMetadata = async () => {
    const metadata = await getMetadata();
    setMetadataOutput(JSON.stringify(metadata, null, 2));
  };

  const handleGetInterviews = async () => {
    const interviews = await getInterviews();
    setInterviewsOutput(JSON.stringify(interviews, null, 2));
  };

  const handleDeleteJob = async () => {
    await deleteJob();
    setMetadataOutput('');
    setInterviewsOutput('');
  };

  const handleVMControl = async (method: 'get' | 'post' | 'delete') => {
    try {
      const response = await fetch('https://bayesprice.helix.ml/secret/api/vm', {
        method,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      });
      const text = await response.text();
      setVmStatus(text);
    } catch (error) {
      setVmStatus(`Error: ${(error as Error).message}`);
    }
  };

  return (
    <div>
      <h1>TSAPI Home</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {loading && <p>Loading...</p>}
      {errorMessage && <Typography color="error" sx={{ mb: 2 }}>Error: {errorMessage}</Typography>}
      <div>
        <FormControl fullWidth size="small" sx={{ mb: 2 }}>
          <InputLabel>Customer</InputLabel>
          <Select
            value={selectedCustomer}
            label="Customer"
            onChange={(e) => setSelectedCustomer(e.target.value)}
          >
            {customers.length > 0 ? (
              customers.map((customer) => (
                <MenuItem key={customer} value={customer}>{customer}</MenuItem>
              ))
            ) : (
              <MenuItem disabled>No customers available</MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl fullWidth size="small" sx={{ mb: 2 }}>
          <InputLabel>Job</InputLabel>
          <Select
            value={selectedJob}
            label="Job"
            onChange={(e) => setSelectedJob(e.target.value)}
            disabled={!selectedCustomer}
          >
            {jobs.length > 0 ? (
              jobs.map((job) => (
                <MenuItem key={job} value={job}>{job}</MenuItem>
              ))
            ) : (
              <MenuItem disabled>No jobs available</MenuItem>
            )}
          </Select>
        </FormControl>
      </div>
      <div>
        <h2>Theme Classification</h2>
        <TextField
          fullWidth
          label="Theme Question"
          value={themeQuestion}
          onChange={(e) => setThemeQuestion(e.target.value)}
          placeholder="Enter theme question"
        />
        <Button onClick={handleStartThemeClassification} disabled={!selectedCustomer || !selectedJob}>Start Theme Classification</Button>
        <pre>{themeOutput}</pre>
      </div>
      <div>
        <h2>Sentiment Analysis</h2>
        <Button onClick={handleStartSentimentAnalysis} disabled={!selectedCustomer || !selectedJob}>Start Sentiment Analysis</Button>
        <pre>{themeOutput}</pre>
      </div>
      <div>
        <h2>Job Control</h2>
        <p>Current Job ID: {jobId || 'None'}</p>
        <Button onClick={handleGetMetadata} disabled={!selectedCustomer || !selectedJob}>Get Metadata</Button>
        <pre>{metadataOutput}</pre>
        <Button onClick={handleGetInterviews} disabled={!selectedCustomer || !selectedJob}>Get Interviews</Button>
        <pre>{interviewsOutput}</pre>
        <Button onClick={handleDeleteJob} disabled={!selectedCustomer || !selectedJob}>Delete Job</Button>
      </div>
      <div>
        <h2>VM Control</h2>
        <Button onClick={() => handleVMControl('post')}>Start VM</Button>
        <Button onClick={() => handleVMControl('delete')}>Stop VM</Button>
        <Button onClick={() => handleVMControl('get')}>Check VM Status</Button>
        <textarea id="vm-status" style={{ width: '100%', minHeight: '600px' }} value={vmStatus} readOnly />
      </div>
    </div>
  );
};

export default TSAPIHome;