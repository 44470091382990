import { useState, useCallback } from 'react';
import { useTSAPIContext } from '../contexts/TSAPIContext'

export const useTSAPI = () => {
  const { jobId, setJobId, carbonClient } = useTSAPIContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const startJob = useCallback(async (jobType: 'sentiment' | 'complaint-theme', data: any) => {
    setLoading(true);
    setError(null);
    try {
      const response = await carbonClient.startJob(jobType, data);
      setJobId(response.jobId);
      return response;
    } catch (err) {
      setError('Failed to start job');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [carbonClient, setJobId]);

  const getMetadata = useCallback(async () => {
    if (!jobId) {
      setError('No job ID set');
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const response = await carbonClient.getMetadata(jobId);
      return response;
    } catch (err) {
      setError('Failed to fetch metadata');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [carbonClient, jobId]);

  const getInterviews = useCallback(async () => {
    if (!jobId) {
      setError('No job ID set');
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const response = await carbonClient.getInterviews(jobId);
      return response;
    } catch (err) {
      setError('Failed to fetch interviews');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [carbonClient, jobId]);

  const deleteJob = useCallback(async () => {
    if (!jobId) {
      setError('No job ID set');
      return;
    }
    setLoading(true);
    setError(null);
    try {
      await carbonClient.deleteJob(jobId);
      setJobId(null);
    } catch (err) {
      setError('Failed to delete job');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [carbonClient, jobId, setJobId]);

  return {
    startJob,
    getMetadata,
    getInterviews,
    deleteJob,
    loading,
    error,
    jobId,
  };
};