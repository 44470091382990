import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  Paper,
  SelectChangeEvent,
  FormControl,
  InputLabel,
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useJob } from '../contexts/JobContext';
import {
  UpdateAccountRequest,
  ChangePasswordRequest,
  UpsertDashboardRequest,
  DashboardRequest,
  QuickUpdateRequest,
  RunSpecRequest,
  GenTabRequest,
  GenNode
} from '../types/types';

const Experiments: React.FC = () => {
  const { carbonClient, isClientReady, isAuthenticated } = useAuth();
  const { selectedCustomer, selectedJob } = useJob();
  const [selectedMethod, setSelectedMethod] = useState('');
  const [inputParams, setInputParams] = useState<Record<string, string>>({});
  const [response, setResponse] = useState<any>(null);
  const [customers, setCustomers] = useState<string[]>([]);
  const [jobs, setJobs] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [htmlReport, setHtmlReport] = useState<string | null>(null);
  const [exportFormat, setExportFormat] = useState<string>('HTML');

  const exportFormats = ['HTML', 'TSV', 'CSV', 'SSV', 'XML', 'OXT', 'OXTNums', 'MultiCube', 'JSON'];

  const methods = [
    { name: 'getServiceInfo', params: [] },
    { name: 'listSessions', params: [] },
    { name: 'updateAccount', params: ['userId', 'userName', 'comment', 'email'] },
    { name: 'changePassword', params: ['userId', 'oldPassword', 'newPassword'] },
    { name: 'multiOxtCancel', params: ['id'] },
    { name: 'upsertDashboard', params: ['name', 'buffer', 'customerName', 'jobName', 'displayName', 'userName', 'isShared', 'comment'] },
    { name: 'deleteDashboard', params: ['customerName', 'jobName', 'dashboardName'] },
    { name: 'getDashboard', params: ['customerName', 'jobName', 'dashboardName'] },
    { name: 'multiOxtStart', params: ['reportId', 'oxtSpecs'] },
    { name: 'multiOxtQuery', params: ['id'] },
    { name: 'openCloudJob', params: ['customerName', 'jobName'] },
    { name: 'listDashboards', params: ['customerName', 'jobName'] },
    { name: 'authenticateName', params: ['username', 'password'] },
    { name: 'getProperties', params: ['customerName', 'jobName'] },
    { name: 'putProperties', params: ['properties'] },
    { name: 'unloadReport', params: [] },
    { name: 'deleteInUserToc', params: ['reportPathName'] },
    { name: 'saveReport', params: ['name', 'sub'] },
    { name: 'loadReport', params: ['fullName'] },
    { name: 'generateXlsx', params: [] },
    { name: 'quickUpdateReport', params: ['showFreq', 'showColPct', 'showRowPct', 'showSig', 'filter'] },
    { name: 'endSession', params: [] },
    { name: 'getEditSpec', params: [] },
    { name: 'getNewSpec', params: [] },
    { name: 'validateSpec', params: ['spec'] },
    { name: 'runSpecification', params: ['name', 'spec'] },
    { name: 'validateExp', params: ['funcManageExp'] },
    { name: 'functionAction', params: ['action', 'exp', 'newExp', 'label'] },
    { name: 'listAxisTreeChildren', params: ['exp'] },
    { name: 'nest', params: ['genaxnodes', 'variables'] },
    { name: 'varAsNodes', params: ['name'] },
    { name: 'generateReport', params: ['reportName', 'top', 'side', 'filter', 'weight'] },
    { name: 'GenTabAsHTML', params: ['top', 'side', 'flt', 'wgt'] },
    { name: 'AxisSyntaxToNodes', params: ['syntax'] },
    { name: 'AxisNodesToSyntax', params: ['nodes'] },
    { name: 'CurrentSyntax', params: [] },
    { name: 'ValidateSyntax', params: ['syntax'] },
  ];

  useEffect(() => {
    const fetchCustomers = async () => {
      if (carbonClient && isClientReady) {
        try {
          const customerList = await carbonClient.getCustomers();
          setCustomers(customerList);
        } catch (error) {
          console.error('Error fetching customers:', error);
          setErrorMessage(`Error fetching customers: ${(error as Error).message}`);
        }
      }
    };

    fetchCustomers();
  }, [carbonClient, isClientReady]);

  useEffect(() => {
    const fetchJobs = async () => {
      if (carbonClient && isClientReady && inputParams.customerName) {
        try {
          const jobList = await carbonClient.getJobs(inputParams.customerName);
          setJobs(jobList);
        } catch (error) {
          console.error('Error fetching jobs:', error);
          setErrorMessage(`Error fetching jobs: ${(error as Error).message}`);
        }
      }
    };

    fetchJobs();
  }, [carbonClient, isClientReady, inputParams.customerName]);

  useEffect(() => {
    const openJob = async () => {
      if (carbonClient && isClientReady && selectedCustomer && selectedJob) {
        try {
          await carbonClient.openCloudJob(selectedCustomer, selectedJob);
          console.log(`Job opened: ${selectedCustomer} - ${selectedJob}`);
        } catch (error) {
          console.error('Error opening job:', error);
          setErrorMessage(`Error opening job: ${(error as Error).message}`);
        }
      }
    };

    openJob();
  }, [carbonClient, isClientReady, selectedCustomer, selectedJob]);

  const handleMethodChange = (event: SelectChangeEvent<string>) => {
    setSelectedMethod(event.target.value);
    setInputParams({});
    setResponse(null);
    setErrorMessage(null);
    setHtmlReport(null);
  };

  const handleInputChange = (param: string, value: string) => {
    setInputParams(prev => ({ ...prev, [param]: value }));
  };

  const handleExportFormatChange = (event: SelectChangeEvent<string>) => {
    setExportFormat(event.target.value);
  };

  const handleRunMethod = async () => {
    setErrorMessage(null);
    setResponse(null);
    setHtmlReport(null);

    if (!isAuthenticated) {
      setErrorMessage('Not authenticated. Please log in first.');
      return;
    }

    if (!isClientReady) {
      setErrorMessage('Carbon client is not ready. Please try again in a moment.');
      return;
    }

    if (!selectedCustomer || !selectedJob) {
      setErrorMessage('Please select a customer and job before running a method.');
      return;
    }

    try {
      let result;
      switch (selectedMethod) {
        case 'getServiceInfo':
          result = await carbonClient.getServiceInfo();
          break;
        case 'listSessions':
          result = await carbonClient.listSessions();
          break;
        case 'updateAccount':
          const updateAccountRequest: UpdateAccountRequest = {
            userId: inputParams.userId || null,
            userName: inputParams.userName || null,
            comment: inputParams.comment || null,
            email: inputParams.email || null
          };
          result = await carbonClient.updateAccount(updateAccountRequest);
          break;
        case 'changePassword':
          const changePasswordRequest: ChangePasswordRequest = {
            userId: inputParams.userId || null,
            oldPassword: inputParams.oldPassword || null,
            newPassword: inputParams.newPassword || null
          };
          result = await carbonClient.changePassword(changePasswordRequest);
          break;
        case 'upsertDashboard':
          const upsertDashboardRequest: UpsertDashboardRequest = {
            name: inputParams.name || null,
            createdUtc: new Date().toISOString(), // You might want to adjust this
            modifiedUtc: new Date().toISOString(), // You might want to adjust this
            buffer: inputParams.buffer || null,
            customerName: inputParams.customerName || null,
            jobName: inputParams.jobName || null,
            displayName: inputParams.displayName || null,
            userName: inputParams.userName || null,
            isShared: inputParams.isShared === 'true',
            comment: inputParams.comment || null
          };
          result = await carbonClient.upsertDashboard(upsertDashboardRequest);
          break;
        case 'deleteDashboard':
          const deleteDashboardRequest: DashboardRequest = {
            customerName: inputParams.customerName || null,
            jobName: inputParams.jobName || null,
            dashboardName: inputParams.dashboardName || null
          };
          result = await carbonClient.deleteDashboard(deleteDashboardRequest);
          break;
        case 'getDashboard':
          const getDashboardRequest: DashboardRequest = {
            customerName: inputParams.customerName || null,
            jobName: inputParams.jobName || null,
            dashboardName: inputParams.dashboardName || null
          };
          result = await carbonClient.getDashboard(getDashboardRequest);
          break;
        case 'openCloudJob':
          result = await carbonClient.openCloudJob(inputParams.customerName, inputParams.jobName);
          break;
        case 'listDashboards':
          result = await carbonClient.listDashboards(inputParams.customerName, inputParams.jobName);
          break;
        case 'authenticateName':
          result = await carbonClient.authenticate(inputParams.username, inputParams.password);
          break;
        case 'getProperties':
          if (!inputParams.customerName || !inputParams.jobName) {
            throw new Error('Customer name and job name are required for getProperties');
          }
          console.log('Calling getProperties with:', inputParams.customerName, inputParams.jobName);
          result = await carbonClient.getProperties(inputParams.customerName, inputParams.jobName);
          break;
        case 'saveReport':
          result = await carbonClient.saveReport(inputParams.name, inputParams.sub);
          break;
        case 'loadReport':
          if (!inputParams.fullName) {
            throw new Error('Full report name is required for loadReport');
          }
          console.log('Attempting to load report:', inputParams.fullName);
          result = await carbonClient.LoadReportAsync(inputParams.fullName);
          if (result.code !== 0) {
            throw new Error(`Failed to load report: ${result.message}`);
          }
          console.log('Report loaded successfully');
          break;
        case 'generateXlsx':
          console.log('Attempting to generate XLSX report');
          result = await carbonClient.generateXlsx();
          if (!result.reportName) {
            setErrorMessage('Warning: XLSX generated, but no report name provided in the response');
          }
          break;
        case 'quickUpdateReport':
          const quickUpdateRequest: QuickUpdateRequest = {
            ShowFreq: inputParams.showFreq === 'true',
            ShowColPct: inputParams.showColPct === 'true',
            ShowRowPct: inputParams.showRowPct === 'true',
            ShowSig: inputParams.showSig === 'true',
            Filter: inputParams.filter || ''
          };
          result = await carbonClient.quickUpdate(quickUpdateRequest);
          break;
        case 'getEditSpec':
          result = await carbonClient.getSpecAggregate();
          break;
        case 'getNewSpec':
          result = await carbonClient.getNewSpec();
          break;
        case 'validateSpec':
          // This might need special handling for spec object
          result = await carbonClient.validateSpec(JSON.parse(inputParams.spec));
          break;
        case 'runSpecification':
          const runSpecRequest: RunSpecRequest = {
            name: inputParams.name,
            dProps: {}, // You might want to add logic to populate this
            spec: JSON.parse(inputParams.spec)
          };
          result = await carbonClient.runSpecification(runSpecRequest);
          break;
        case 'validateExp':
          result = await carbonClient.validateExpression({ expression: inputParams.funcManageExp });
          break;
        case 'generateReport':
          const genTabRequest: GenTabRequest = {
            name: inputParams.reportName || 'Test Report',
            top: inputParams.top || '',
            side: inputParams.side || '',
            filter: inputParams.filter || '',
            weight: inputParams.weight || '',
            sProps: {
              caseFilter: null,
              initAsMissing: false,
              excludeNE: false,
              padHierarchics: false,
              arithOverStats: false,
              topInsert: null,
              sideInsert: null,
              level: null,
              fullStats: false
            },
            dProps: {}
          };

          console.log('generateReport - Request Payload:', JSON.stringify(genTabRequest, null, 2));
          console.log('generateReport - Export Format:', 'HTML'); // Always use 'HTML' here

          result = await carbonClient.GenTab(genTabRequest, 'HTML'); // Always send 'HTML' as the format

          console.log('generateReport - Response:', result);

          if (exportFormat === 'JSON') {
            setResponse(JSON.stringify(result, null, 2));
          } else {
            setHtmlReport(result);
            setResponse(result); // Set both HTML report and response
          }
          break;
        case 'GenTabAsHTML':
          console.log('Calling GenTabAsHTML with params:', inputParams);
          result = await carbonClient.GenTabAsHTML(
            inputParams.top || '',
            inputParams.side || '',
            inputParams.flt,
            inputParams.wgt
          );
          console.log('GenTabAsHTML result:', result);
          if (Array.isArray(result)) {
            setHtmlReport(result.join('\n'));
          } else {
            setHtmlReport(result);
          }
          setResponse(null); // Clear the JSON response when showing HTML
          break;
        case 'AxisSyntaxToNodes':
          result = await carbonClient.AxisSyntaxToNodes(inputParams.syntax || '');
          break;
        case 'AxisNodesToSyntax':
          // Note: This is a simplified version. You might need to parse the input to create GenNode[]
          result = await carbonClient.AxisNodesToSyntax(JSON.parse(inputParams.nodes || '[]'));
          break;
        case 'CurrentSyntax':
          result = await carbonClient.CurrentSyntax();
          break;
        case 'ValidateSyntax':
          result = await carbonClient.ValidateSyntax(inputParams.syntax || '');
          break;
        default:
          throw new Error('Method not implemented');
      }
      if (selectedMethod !== 'GenTabAsHTML') {
        setResponse(result);
      }
    } catch (error) {
      console.error('Error running method:', error);
      if (error instanceof Error) {
        setErrorMessage(`Error: ${error.message}`);
      } else if (typeof error === 'object' && error !== null) {
        setErrorMessage(`Error: ${JSON.stringify(error)}`);
      } else {
        setErrorMessage(`An unknown error occurred`);
      }
    }
  };

  const renderHtmlReport = () => {
    if (htmlReport) {
      return (
        <Paper sx={{ p: 2, mt: 2 }}>
          <Typography variant="h6">HTML Report</Typography>
          <div dangerouslySetInnerHTML={{ __html: htmlReport }} />
        </Paper>
      );
    }
    return null;
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      height: 'calc(100vh - 84px)',
      mt: '84px',
      overflow: 'hidden',
      pl: 2,
      pr: 3,
      py: 2,
    }}>
      {/* Left sidebar - Method selection */}
      <Paper sx={{ 
        width: 260, 
        p: 2, 
        mr: 2,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Typography variant="h6" gutterBottom>Methods</Typography>
        <Select
          value={selectedMethod}
          onChange={handleMethodChange}
          displayEmpty
          fullWidth
        >
          <MenuItem value="" disabled>Select a method</MenuItem>
          {methods.map(method => (
            <MenuItem key={method.name} value={method.name}>{method.name}</MenuItem>
          ))}
        </Select>
      </Paper>

      {/* Right side - Method parameters and results */}
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        {/* Top section - Method parameters */}
        <Paper sx={{ p: 2, mb: 2, overflowY: 'auto' }}>
          {selectedMethod && (
            <>
              <Typography variant="h6">{selectedMethod}</Typography>
              {methods.find(m => m.name === selectedMethod)?.params.map(param => (
                param === 'customerName' ? (
                  <FormControl fullWidth key={param} margin="normal">
                    <InputLabel>{param}</InputLabel>
                    <Select
                      value={inputParams[param] || ''}
                      onChange={(e) => handleInputChange(param, e.target.value)}
                      label={param}
                    >
                      {customers.map(customer => (
                        <MenuItem key={customer} value={customer}>{customer}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : param === 'jobName' ? (
                  <FormControl fullWidth key={param} margin="normal">
                    <InputLabel>{param}</InputLabel>
                    <Select
                      value={inputParams[param] || ''}
                      onChange={(e) => handleInputChange(param, e.target.value)}
                      label={param}
                      disabled={!inputParams.customerName}
                    >
                      {jobs.map(job => (
                        <MenuItem key={job} value={job}>{job}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    key={param}
                    label={param}
                    value={inputParams[param] || ''}
                    onChange={(e) => handleInputChange(param, e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                )
              ))}
              {selectedMethod === 'generateReport' && (
                <FormControl fullWidth margin="normal">
                  <InputLabel>Export Format</InputLabel>
                  <Select
                    value={exportFormat}
                    onChange={handleExportFormatChange}
                    label="Export Format"
                  >
                    {exportFormats.map(format => (
                      <MenuItem key={format} value={format}>{format}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <Button variant="contained" onClick={handleRunMethod} sx={{ mt: 2 }}>
                Run Method
              </Button>
            </>
          )}
        </Paper>

        {/* Bottom section - Results */}
        <Paper sx={{ 
          flex: 1, 
          p: 2, 
          overflowY: 'auto', 
          display: 'flex',
          flexDirection: 'column'
        }}>
          {!isAuthenticated && (
            <Typography color="error">Please log in to use this feature.</Typography>
          )}
          {!isClientReady && (
            <Typography color="warning">Carbon client is initializing. Please wait...</Typography>
          )}
          {errorMessage && (
            <Paper sx={{ p: 2, mt: 2, bgcolor: 'error.light' }}>
              <Typography color="error" variant="h6">Error</Typography>
              <Typography color="error">{errorMessage}</Typography>
            </Paper>
          )}
          {response && (
            <>
              <Typography variant="h6">Response</Typography>
              <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                {JSON.stringify(response, null, 2)}
              </pre>
            </>
          )}
          {renderHtmlReport()}
        </Paper>
      </Box>
    </Box>
  );
};

export default Experiments;