import React, { createContext, useState, useContext, ReactNode, useEffect, useCallback } from 'react';
import { RunSpecRequest, SpecAggregate } from '../types/types';

interface SpecContextType {
  currentSpec: RunSpecRequest | null;
  setCurrentSpec: (spec: RunSpecRequest) => void;
  specAggregate: SpecAggregate | null;
  setSpecAggregate: (specAggregate: SpecAggregate | null) => void;
}

const SpecContext = createContext<SpecContextType | undefined>(undefined);

export const SpecProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [currentSpec, setCurrentSpecState] = useState<RunSpecRequest | null>(() => {
    const savedSpec = localStorage.getItem('currentSpec');
    return savedSpec ? JSON.parse(savedSpec) : null;
  });
  const [specAggregate, setSpecAggregateState] = useState<SpecAggregate | null>(null);

  const setCurrentSpec = useCallback((spec: RunSpecRequest) => {
    setCurrentSpecState((prevSpec) => {
      if (JSON.stringify(prevSpec) !== JSON.stringify(spec)) {
        console.log('Setting current spec:', spec);
        localStorage.setItem('currentSpec', JSON.stringify(spec));
        return spec;
      }
      return prevSpec;
    });
  }, []);

  const setSpecAggregate = useCallback((specAgg: SpecAggregate | null) => {
    setSpecAggregateState(specAgg);
  }, []);

  // Remove specAggregate from localStorage on component mount
  useEffect(() => {
    localStorage.removeItem('specAggregate');
  }, []);

  return (
    <SpecContext.Provider value={{ currentSpec, setCurrentSpec, specAggregate, setSpecAggregate }}>
      {children}
    </SpecContext.Provider>
  );
};

export const useSpec = () => {
  const context = useContext(SpecContext);
  if (context === undefined) {
    throw new Error('useSpec must be used within a SpecProvider');
  }
  return context;
};