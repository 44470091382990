import React, { createContext, useContext, useState, ReactNode } from 'react';
import { CarbonClient } from '../services/CarbonClient';

interface TSAPIContextType {
  jobId: string | null;
  setJobId: (id: string | null) => void;
  carbonClient: CarbonClient;
}

const TSAPIContext = createContext<TSAPIContextType | undefined>(undefined);

export const TSAPIProvider: React.FC<{ children: ReactNode; carbonClient: CarbonClient }> = ({ children, carbonClient }) => {
  const [jobId, setJobId] = useState<string | null>(null);

  return (
    <TSAPIContext.Provider value={{ jobId, setJobId, carbonClient }}>
      {children}
    </TSAPIContext.Provider>
  );
};

export const useTSAPIContext = () => {
  const context = useContext(TSAPIContext);
  if (context === undefined) {
    throw new Error('useTSAPIContext must be used within a TSAPIProvider');
  }
  return context;
};