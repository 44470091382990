// Comment: Importing necessary components and hooks from React and Material-UI
import React, { useState, useEffect, ErrorInfo, useCallback } from 'react';
import { Box, Paper, Typography, TextField, Button, Divider, IconButton, Tooltip, Select, MenuItem, FormControl, InputLabel, List, ListItem, ListItemText, Modal } from '@mui/material'
import { Folder, Description, ArrowUpward, FiberManualRecord, ArrowDownward, Delete, Percent, Functions, List as ListIcon, Code, ExpandMore, ChevronRight, FiberManualRecord as DotIcon } from '@mui/icons-material'
import { RichTreeView, RichTreeViewProps } from '@mui/x-tree-view/RichTreeView';
import { TreeItem2, TreeItem2Props } from '@mui/x-tree-view/TreeItem2';
import { TreeViewProps } from '@mui/x-tree-view/TreeView';
import { useAuth } from '../contexts/AuthContext'
import { GenNode, SpecAggregate, RunSpecRequest, GenericResponse } from '../types/types'
import axios from 'axios';
import { useJob } from '../contexts/JobContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSpec } from '../contexts/SpecContext';

// Update the ExtendedGenNode type definition
type ExtendedGenNode = Omit<GenNode, 'children'> & {
  id: string;
  isParentInfo?: boolean;
  isPC?: boolean;
  isBase?: boolean;
  children?: ExtendedGenNode[];
};

// Add this function near the top of your file, outside of the component
const mapToExtendedGenNode = (node: GenNode): ExtendedGenNode => ({
  ...node,
  id: node.id.toString(),
  parentId: node.parentId?.toString() || null,
  children: node.children ? node.children.map(mapToExtendedGenNode) : undefined
});

// Add this function definition before the Specification component
const mapNodeToGenNode = (node: ExtendedGenNode): GenNode => ({
  type: node.type,
  id: node.id,
  parentId: node.parentId,
  name: node.name || node.value1 || '',
  isExpanded: node.isExpanded,
  isFolder: node.isFolder,
  value1: node.value1 || '',
  value2: node.value2 || '',
  meta: node.meta || [],
  level: node.level || 0,
  anyChildren: node.anyChildren || false,
  children: node.children ? node.children.map(mapNodeToGenNode) : null
});

class ErrorBoundary extends React.Component<{ children: React.ReactNode }, { hasError: boolean }> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

const Specification: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { carbonClient, isAuthenticated, sessionInfo, isClientReady } = useAuth()
  const { selectedCustomer, selectedJob } = useJob();
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  
  const [customers, setCustomers] = useState<string[]>([])
  const [jobs, setJobs] = useState<string[]>([])
  
  const [specAggregate, setSpecAggregate] = useState<SpecAggregate | null>(null)
  const [variableTree, setVariableTree] = useState<ExtendedGenNode[]>([])
  const [axisTree, setAxisTree] = useState<GenNode[]>([])
  const [functionTree, setFunctionTree] = useState<GenNode[]>([])
  
  const [expandedVariableItems, setExpandedVariableItems] = useState<string[]>([])
  const [selectedVariables, setSelectedVariables] = useState<string[]>([])

  const [topNodes, setTopNodes] = useState<ExtendedGenNode[]>([]);
  const [sideNodes, setSideNodes] = useState<ExtendedGenNode[]>([]);

  const [selectedTopNode, setSelectedTopNode] = useState<ExtendedGenNode | null>(null);
  const [selectedSideNode, setSelectedSideNode] = useState<ExtendedGenNode | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedNodeVariables, setSelectedNodeVariables] = useState<string[]>([]);

  const [currentSpec, setCurrentSpec] = useState<RunSpecRequest | null>(null);

  const [isFetching, setIsFetching] = useState(false);

  const [isValidateModalOpen, setIsValidateModalOpen] = useState(false);
  const [validateResponse, setValidateResponse] = useState<GenericResponse | null>(null);

  const [isRunSpecModalOpen, setIsRunSpecModalOpen] = useState(false);
  const [runSpecResponse, setRunSpecResponse] = useState<GenericResponse | null>(null);

  const [filter, setFilter] = useState('');
  const [weight, setWeight] = useState('');

  const [reportTitle, setReportTitle] = useState<string>(() => {
    const state = location.state as { reportName?: string } | null;
    return state?.reportName || 'Untitled Report';
  });

  const [debugInfo, setDebugInfo] = useState<{
    displayTable: any;
    dProps: any;
    sProps: any;
    job: string;
    customer: string;
    tableSpec: any;
  }>({
    displayTable: null,
    dProps: null,
    sProps: null,
    job: '',
    customer: '',
    tableSpec: null,
  });

  const loadSpec = (spec: RunSpecRequest) => {
    setCurrentSpec(spec);
    console.log('Loading spec:', spec);
    
    if (spec.spec) {
      const mappedTopNodes = spec.spec.topAxis.map(mapToExtendedGenNode);
      const mappedSideNodes = spec.spec.sideAxis.map(mapToExtendedGenNode);
      
      console.log('Mapped top nodes:', mappedTopNodes);
      console.log('Mapped side nodes:', mappedSideNodes);
      
      setTopNodes(prevNodes => {
        if (JSON.stringify(prevNodes) === JSON.stringify(mappedTopNodes)) {
          console.log('No changes in top nodes, skipping update');
          return prevNodes;
        }
        console.log('Updating top nodes');
        return mappedTopNodes;
      });
      setSideNodes(prevNodes => {
        if (JSON.stringify(prevNodes) === JSON.stringify(mappedSideNodes)) {
          console.log('No changes in side nodes, skipping update');
          return prevNodes;
        }
        console.log('Updating side nodes');
        return mappedSideNodes;
      });
      
      // Set filter and weight
      setFilter(spec.spec.filter || '');
      setWeight(spec.spec.weight || '');
    } else {
      console.warn('Loaded spec does not have valid axis data');
    }
  };

  useEffect(() => {
    console.log('Component mounted');
    const loadSavedSpec = () => {
      const savedSpec = localStorage.getItem('currentSpec');
      if (savedSpec) {
        try {
          const parsedSpec = JSON.parse(savedSpec);
          console.log('Parsed spec from localStorage:', parsedSpec);

          if (parsedSpec.spec && parsedSpec.spec.topAxis && parsedSpec.spec.sideAxis) {
            loadSpec(parsedSpec as RunSpecRequest);
            // Update the report title if it's not already set from the location state
            if (!location.state?.reportName) {
              setReportTitle(parsedSpec.name || 'Untitled Report');
            }
          } else if (parsedSpec.topAxis && parsedSpec.sideAxis) {
            loadSpec({
              name: parsedSpec.name || 'Converted Spec',
              dProps: parsedSpec.dProps || {},
              spec: {
                topAxis: parsedSpec.topAxis,
                sideAxis: parsedSpec.sideAxis,
                topLock: parsedSpec.topLock || false,
                sideLock: parsedSpec.sideLock || false,
                useFilter: parsedSpec.useFilter || false,
                filter: parsedSpec.filter || '',
                useWeight: parsedSpec.useWeight || false,
                weight: parsedSpec.weight || '',
                specProperties: parsedSpec.specProperties || {
                  caseFilter: null,
                  initAsMissing: false,
                  excludeNE: false,
                  padHierarchics: false,
                  arithOverStats: false,
                  topInsert: null,
                  sideInsert: null,
                  level: null,
                  fullStats: false // Add this line
                }
              }
            });
            // Update the report title if it's not already set from the location state
            if (!location.state?.reportName) {
              setReportTitle(parsedSpec.name || 'Converted Spec');
            }
          } else {
            console.warn('Unrecognized spec structure in localStorage:', parsedSpec);
            setErrorMessage('Unrecognized saved specification structure');
          }
        } catch (error) {
          console.error('Error parsing saved spec:', error);
          setErrorMessage('Failed to load saved specification');
        }
      }
    };

    loadSavedSpec();

    return () => {
      console.log('Component unmounting');
    };
  }, [location.state]);

  useEffect(() => {
    console.log('Top nodes updated:', topNodes);
  }, [topNodes]);

  useEffect(() => {
    console.log('Side nodes updated:', sideNodes);
  }, [sideNodes]);

  useEffect(() => {
    console.log('variableTree updated:', variableTree);
  }, [variableTree]);

  const fetchSpecAggregate = async () => {
    if (isFetching) {
      console.log('Already fetching data, skipping');
      return;
    }
    setIsFetching(true);
    if (carbonClient && isClientReady && selectedCustomer && selectedJob) {
      setIsLoading(true);
      setErrorMessage(null);
      try {
        console.log(`Attempting to open cloud job: ${selectedCustomer}/${selectedJob}`);
        await carbonClient.openCloudJob(selectedCustomer, selectedJob);
        console.log('Cloud job opened successfully');

        console.log('Fetching spec aggregate');
        const specAggregate = await carbonClient.getSpecAggregate();
        
        console.log('Received spec aggregate:', specAggregate);
        if (!Array.isArray(specAggregate.variableTree)) {
          console.error('Expected variableTree to be an array, got:', typeof specAggregate.variableTree);
          return;
        }
        const mappedVariableTree = specAggregate.variableTree.map(mapToExtendedGenNode);
        setVariableTree(prevTree => {
          if (JSON.stringify(prevTree) === JSON.stringify(mappedVariableTree)) {
            console.log('No changes in variableTree, skipping update');
            return prevTree;
          }
          console.log('Updating variableTree');
          return mappedVariableTree;
        });
        
        if (specAggregate.axisTree) setAxisTree(specAggregate.axisTree);
        if (specAggregate.functionTree) setFunctionTree(specAggregate.functionTree);

        if (specAggregate.spec) {
          loadSpec(specAggregate.spec);
        } else {
          console.log('No spec provided in specAggregate, keeping existing top and side nodes');
        }

      } catch (error) {
        console.error('Error fetching spec aggregate:', error);
        setErrorMessage(`Error fetching spec aggregate: ${error instanceof Error ? error.message : String(error)}`);
      } finally {
        setIsLoading(false);
        setIsFetching(false);
      }
    }
  }

  useEffect(() => {
    if (carbonClient && isClientReady && selectedCustomer && selectedJob) {
      fetchSpecAggregate();
    }
  }, [carbonClient, isClientReady, selectedCustomer, selectedJob]);

  const isAxiosError = (error: any): error is { response: { status: number } } => {
    return error && error.response && typeof error.response.status === 'number';
  };

  const handleVariableExpand = async (variableId: string) => {
    if (!carbonClient || !isClientReady) return;

    try {
      const node = findVariable(variableTree, variableId);
      if (node) {
        console.log(`Fetching variables for node:`, node);
        const nodeName = node.name || node.value1 || node.id.toString();
        console.log(`Using node name/id: ${nodeName}`);
        
        if (node.children && node.children.length > 0) {
          console.log(`Node has children, displaying them as variables`);
          setSelectedNodeVariables(node.children.map(child => child.name || child.value1 || '').filter(Boolean));
          setErrorMessage(null);
        } else {
          const variables = await carbonClient.getVarNodes(nodeName);
          console.log(`Fetched codes for ${nodeName}:`, variables);
          if (variables.length === 0) {
            console.log(`No codes found for ${nodeName} (ID: ${variableId})`);
            setSelectedNodeVariables([]);
            setErrorMessage(`No codes found for ${nodeName}`);
          } else if (variables[0].type === 'Codeframe' && variables[0].children) {
            // Handle Codeframe with children
            setSelectedNodeVariables(variables[0].children.map(child => child.value2 || child.value1 || '').filter(Boolean));
            updateTreeWithCodeframe(variableId, variables);
            setErrorMessage(null);
          } else {
            setSelectedNodeVariables(variables.map(v => v.name || v.value1 || '').filter(Boolean));
            setErrorMessage(null);
          }
        }
        setExpandedVariableItems(prev => [...new Set([...prev, variableId])]);
      } else {
        console.error(`Variable not found for ID: ${variableId}`);
        setErrorMessage(`Variable not found for ID: ${variableId}`);
      }
    } catch (error) {
      console.error('Error fetching node variables:', error);
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.message || error.message;
        console.error(`API Error: ${errorMessage}`);
        setErrorMessage(`Error fetching node variables: ${errorMessage}`);
      } else if (error instanceof Error) {
        setErrorMessage(`Error fetching node variables: ${error.message}`);
      } else {
        setErrorMessage(`Unexpected error: ${String(error)}`);
      }
    }
  };

  const updateTreeWithChildVariables = (parentId: string, childVariables: GenNode[]) => {
    setVariableTree((prevTree: ExtendedGenNode[]) => {
      const updateVariables = (variables: ExtendedGenNode[]): ExtendedGenNode[] => {
        return variables.map(variable => {
          if (variable.id.toString() === parentId) {
            return {
              ...variable,
              children: childVariables.map(mapToExtendedGenNode),
              isExpanded: true
            }
          }
          if (variable.children) {
            return { ...variable, children: updateVariables(variable.children) }
          }
          return variable
        })
      }
      const updatedTree = updateVariables(prevTree);
      if (JSON.stringify(updatedTree) === JSON.stringify(prevTree)) {
        console.log('No changes in variableTree, skipping update');
        return prevTree;
      }
      console.log('Updating variableTree with new children');
      return updatedTree;
    })
  }

  const updateTreeWithCodeframe = (variableId: string, codeframe: GenNode[]) => {
    setVariableTree((prevTree: ExtendedGenNode[]) => {
      const updateVariables = (variables: ExtendedGenNode[]): ExtendedGenNode[] => {
        return variables.map(variable => {
          if (variable.id.toString() === variableId) {
            const codeNodes: ExtendedGenNode[] = codeframe[0].children?.map(code => ({
              ...code,
              id: `${variableId}_${code.id}`,
              type: 'Code',
              name: `${code.value1 || ''} - ${code.value2 || ''}`,
              isExpanded: false,
              children: undefined,
            })) || [];

            return { ...variable, children: codeNodes, isExpanded: true };
          }
          if (variable.children) {
            return { ...variable, children: updateVariables(variable.children) };
          }
          return variable;
        });
      };
      const updatedTree = updateVariables(prevTree);
      if (JSON.stringify(updatedTree) === JSON.stringify(prevTree)) {
        console.log('No changes in variableTree, skipping update');
        return prevTree;
      }
      console.log('Updating variableTree with codeframe');
      return updatedTree;
    });
  };

  const findVariable = (nodes: ExtendedGenNode[], id: string): ExtendedGenNode | null => {
    for (const node of nodes) {
      if (node.id.toString() === id) {
        return node;
      }
      if (node.children) {
        const found = findVariable(node.children, id);
        if (found) return found;
      }
    }
    return null;
  }

  const handleDragStart = (event: React.DragEvent<HTMLLIElement>, node: ExtendedGenNode, treeType: 'variable' | 'axis' | 'function') => {
    console.log('Drag started:', { node, treeType });
    const selectedNodes = selectedVariables.map(id => findVariable(variableTree, id)).filter(Boolean);
    const dragData = JSON.stringify({ nodeIds: selectedNodes.map(n => n!.id), treeType });
    console.log('Drag data set:', dragData);
    event.dataTransfer.setData('text/plain', dragData);
    event.dataTransfer.effectAllowed = 'move';
  };

  const renderTreeItems = (variables: ExtendedGenNode[], treeType: 'variable' | 'axis' | 'function'): ExtendedGenNode[] => {
    return variables.map(variable => {
      const baseNode = {
        ...variable,
        id: variable.id.toString(),
        label: variable.name || variable.value1 || '',
        content: (
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {getVariableIcon(variable, treeType)}
            <span>{variable.name || variable.value1 || ''}</span>
            {variable.type === 'Code' && variable.meta && variable.meta.map((metaItem: { key: string; value: string }, index: number) => (
              <Tooltip key={index} title={`${metaItem.key}: ${metaItem.value}`}>
                <span style={{ marginLeft: '8px', fontSize: '0.8em', color: 'gray' }}>
                  {metaItem.key === 'PC' ? '%' : metaItem.key}
                </span>
              </Tooltip>
            ))}
          </Box>
        ),
      };

      if (variable.children) {
        return {
          ...baseNode,
          children: renderTreeItems(variable.children, treeType)
        };
      }

      return baseNode;
    });
  };

  const CustomTreeItem = React.forwardRef<HTMLLIElement, TreeItem2Props>((props, ref) => {
    const { itemId, label, ...other } = props;
    const item = findVariable(variableTree, itemId);

    return (
      <TreeItem2
        ref={ref}
        {...other}
        itemId={itemId}
        label={
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {getVariableIcon(item, 'variable')}
            <Typography variant="body2" sx={{ mr: 1 }}>{label || item?.name || item?.value1 || ''}</Typography>
            {item?.type === 'Code' && item.meta && item.meta.map((metaItem: { key: string; value: string }, index: number) => (
              <Tooltip key={index} title={`${metaItem.key}: ${metaItem.value}`}>
                <span style={{ marginLeft: '8px', fontSize: '0.8em', color: 'gray' }}>
                  {metaItem.key === 'PC' ? '%' : metaItem.key}
                </span>
              </Tooltip>
            ))}
          </Box>
        }
        draggable="true"
        onDragStart={(event) => {
          event.stopPropagation();
          if (item) {
            handleDragStart(event as React.DragEvent<HTMLLIElement>, item, 'variable');
          }
        }}
      />
    );
});
  const getVariableIcon = (variable: ExtendedGenNode | null, treeType: 'variable' | 'axis' | 'function') => {
    if (!variable) return <ListIcon fontSize="small" sx={{ mr: 1, color: 'action.active' }} />;

    if (variable.isFolder) return <Folder fontSize="medium" sx={{ mr: 1, color: 'lightblue' }} />;
    if (variable.type === 'Variable' || variable.type === 'Axis') {
      return (
        <Box sx={{ position: 'relative', display: 'inline-flex', mr: 1 }}>
          <Box
            sx={{
              width: 20,
              height: 20,
              borderRadius: '50%',
              background: 'radial-gradient(circle at 30% 30%, #FF6B6B, #FF0000)',
            }}
          />
          <ExpandMore sx={{ 
            position: 'absolute', 
            color: '#FFFFFF', 
            fontSize: 20, 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)'
          }} />
        </Box>
      );
    }
    if (variable.type === 'Code') {
      return (
        <Box
          sx={{
            width: 18,
            height: 18,
            borderRadius: '50%',
            mr: 1,
            background: 'radial-gradient(circle at 30% 30%, #FFFF66, #CCCC00)',
          }}
        />
      );
    }
    if (treeType === 'function') return <Functions fontSize="small" sx={{ mr: 1, color: 'action.active' }} />;
    return <ListIcon fontSize="small" sx={{ mr: 1, color: 'action.active' }} />;
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>, target: 'top' | 'side') => {
    event.preventDefault();
    console.log('Drop event occurred on target:', target);
    
    const data = event.dataTransfer.getData('text/plain');
    console.log('Dropped data:', data);
    
    if (!data) {
      console.error('No data received in drop event');
      setErrorMessage('Error: No data received from drag operation');
      return;
    }
    
    try {
      const { nodeIds, treeType } = JSON.parse(data) as { nodeIds: string[], treeType: 'variable' | 'axis' | 'function' };
      console.log('Parsed drop data:', { nodeIds, treeType, target });

      const nodes = nodeIds.map(id => findVariable(variableTree, id.toString())).filter(Boolean) as ExtendedGenNode[];
      console.log('Found nodes:', nodes);

      if (nodes.length === 0) {
        console.log('No nodes found to drop');
        setErrorMessage('No items found for drop');
        return;
      }

      const validNodes = nodes.filter(node => validateDrop(node, target, treeType));
      if (validNodes.length > 0) {
        const nodesToAdd = await Promise.all(validNodes.map(node => fetchNodesForDrop(node)));
        const flattenedNodesToAdd = nodesToAdd.flat().filter(Boolean);
        
        if (flattenedNodesToAdd.length > 0) {
          if (target === 'top') {
            setTopNodes((prev: ExtendedGenNode[]) => mergeNodes(prev, flattenedNodesToAdd));
          } else {
            setSideNodes((prev: ExtendedGenNode[]) => mergeNodes(prev, flattenedNodesToAdd));
          }
          setErrorMessage(null);
          console.log(`Node(s) added to ${target} nodes:`, flattenedNodesToAdd);
        } else {
          console.log('No valid nodes to add after fetching');
          setErrorMessage('No valid nodes to add');
        }
      } else {
        console.log('No valid nodes to drop');
        setErrorMessage(`Invalid drop: None of the selected items can be dropped in ${target}`);
      }
    } catch (error) {
      console.error('Error processing dropped data:', error);
      setErrorMessage(`Error processing dropped items: ${error instanceof Error ? error.message : String(error)}`);
    }
  };

  const mergeNodes = (existingNodes: ExtendedGenNode[], newNodes: ExtendedGenNode[]): ExtendedGenNode[] => {
    const result = [...existingNodes];

    newNodes.forEach(newNode => {
      const existingIndex = result.findIndex(n => n.id === newNode.id);
      if (existingIndex !== -1) {
        result[existingIndex] = {
          ...result[existingIndex],
          ...newNode,
          children: mergeNodes(result[existingIndex].children || [], newNode.children || [])
        };
      } else {
        const parentIndex = result.findIndex(n => n.id === newNode.parentId);
        if (parentIndex !== -1) {
          result[parentIndex] = {
            ...result[parentIndex],
            children: mergeNodes(result[parentIndex].children || [], [newNode])
          };
        } else {
          result.push(newNode);
        }
      }
    });

    return result;
  };

  const fetchNodesForDrop = async (node: ExtendedGenNode): Promise<ExtendedGenNode[]> => {
    if (!carbonClient || !isClientReady) {
      throw new Error('Carbon client is not ready');
    }

    let nodesToAdd: ExtendedGenNode[] = [];

    try {
      const nodeName = node.name || node.value1 || node.id.toString();
      console.log(`Fetching nodes for: ${nodeName}`);

      switch (node.type) {
        case 'Folder':
        case 'Variable':
        case 'Axis':
        case 'Codeframe':
          // For Folder, Variable, Axis, and Codeframe nodes, fetch their children
          const fetchedNodes = await carbonClient.getVarNodes(nodeName);
          if (fetchedNodes.length > 0) {
            nodesToAdd = [{
              ...fetchedNodes[0],
              id: fetchedNodes[0].id.toString(), // Ensure id is a string
              name: `${fetchedNodes[0].value1 || ''} ${fetchedNodes[0].value2 || ''}`.trim(),
              children: processNodes(fetchedNodes[0].children || [], fetchedNodes[0] as ExtendedGenNode),
              isExpanded: true
            } as ExtendedGenNode]; // Add type assertion here
          }
          break;
        case 'Function':
          // For Function nodes, add them directly
          nodesToAdd = [node];
          break;
        case 'Code':
        case 'Arith':
        case 'Net':
        case 'Stat':
        case 'Base':
          // For leaf code nodes, create a parent Codeframe with only this code
          const parentNode = findParentNode(variableTree, node);
          if (parentNode) {
            nodesToAdd = [{
              ...parentNode,
              children: [node],
              isExpanded: true
            }];
          } else {
            nodesToAdd = [node];
          }
          break;
        default:
          console.warn(`Unhandled node type: ${node.type}`);
          nodesToAdd = [node];
      }
    } catch (error) {
      console.error('Error fetching nodes for drop:', error);
      nodesToAdd = [node];
    }

    return nodesToAdd;
  };

  // Helper function to find the parent node in the tree
  const findParentNode = (nodes: ExtendedGenNode[], childNode: ExtendedGenNode): ExtendedGenNode | null => {
    for (const node of nodes) {
      if (node.children) {
        if (node.children.some(child => child.id === childNode.id)) {
          return node;
        }
        const foundInChildren = findParentNode(node.children, childNode);
        if (foundInChildren) return foundInChildren;
      }
    }
    return null;
  };

  const processNodes = (nodes: GenNode[], parentNode: ExtendedGenNode): ExtendedGenNode[] => {
    return nodes.map((node, index) => ({
      ...node,
      id: (node.id?.toString() || `${parentNode.id}-${index}`),
      parentId: parentNode.id.toString(), // Ensure parentId is a string
      name: `${node.value1 || ''} ${node.value2 || ''}`.trim(),
      isExpanded: node.type === 'Variable',
      children: node.children ? processNodes(node.children, node as ExtendedGenNode) : undefined,
    } as ExtendedGenNode));
  };

  const validateDrop = (node: ExtendedGenNode, target: 'top' | 'side', treeType: 'variable' | 'axis' | 'function'): boolean => {
    const validTypes = [
      'Folder', 'Variable', 'Axis', 'Function', 'Code', 'Arith', 'Net', 'Stat', 'Base',
      'Codeframe', 'Level', 'Filter', 'Weight', 'Spacer', 'TabAxis'
    ];
  
    return validTypes.includes(node.type);
  };

  const handleMoveUp = (target: 'top' | 'side', parentId: string | null, index: number) => {
    const updateNodes = (nodes: ExtendedGenNode[]): ExtendedGenNode[] => {
      if (parentId) {
        return nodes.map(node => {
          if (node.id.toString() === parentId && node.children) {
            const newChildren = [...node.children];
            if (index > 0) {
              [newChildren[index - 1], newChildren[index]] = [newChildren[index], newChildren[index - 1]];
            }
            return { ...node, children: newChildren };
          }
          if (node.children) {
            return { ...node, children: updateNodes(node.children) };
          }
          return node;
        });
      } else {
        const newNodes = [...nodes];
        if (index > 0) {
          [newNodes[index - 1], newNodes[index]] = [newNodes[index], newNodes[index - 1]];
        }
        return newNodes;
      }
    };

    if (target === 'top') {
      setTopNodes(updateNodes);
    } else {
      setSideNodes(updateNodes);
    }
  }

  const handleMoveDown = (target: 'top' | 'side', parentId: string | null, index: number) => {
    const updateNodes = (nodes: ExtendedGenNode[]): ExtendedGenNode[] => {
      if (parentId) {
        return nodes.map(node => {
          if (node.id.toString() === parentId && node.children) {
            const newChildren = [...node.children];
            if (index < newChildren.length - 1) {
              [newChildren[index], newChildren[index + 1]] = [newChildren[index + 1], newChildren[index]];
            }
            return { ...node, children: newChildren };
          }
          if (node.children) {
            return { ...node, children: updateNodes(node.children) };
          }
          return node;
        });
      } else {
        const newNodes = [...nodes];
        if (index < newNodes.length - 1) {
          [newNodes[index], newNodes[index + 1]] = [newNodes[index + 1], newNodes[index]];
        }
        return newNodes;
      }
    };

    if (target === 'top') {
      setTopNodes(updateNodes);
    } else {
      setSideNodes(updateNodes);
    }
  }

  const handleDelete = (target: 'top' | 'side', parentId: string | null, index: number) => {
    const updateNodes = (nodes: ExtendedGenNode[]): ExtendedGenNode[] => {
      if (parentId) {
        return nodes.map(node => {
          if (node.id.toString() === parentId && node.children) {
            const newChildren = node.children.filter((_, i) => i !== index);
            return { ...node, children: newChildren };
          }
          if (node.children) {
            return { ...node, children: updateNodes(node.children) };
          }
          return node;
        });
      } else {
        return nodes.filter((_, i) => i !== index);
      }
    };

    if (target === 'top') {
      setTopNodes(updateNodes);
    } else {
      setSideNodes(updateNodes);
    }
  }

  const runSpec = async () => {
    if (!carbonClient || !isClientReady) {
      setErrorMessage('Carbon client is not ready')
      return
    }

    try {
      setIsLoading(true);

      const specData: RunSpecRequest = {
        name: reportTitle || 'Untitled Report', // Use the report title here
        dProps: {
          titles: {
            name: { font: { name: 'Arial', size: 12 }, visible: true },
            top: { font: { name: 'Arial', size: 12 }, visible: true },
            side: { font: { name: 'Arial', size: 12 }, visible: true },
            filter: { font: { name: 'Arial', size: 12 }, visible: true },
            weight: { font: { name: 'Arial', size: 12 }, visible: true },
            status: { font: { name: 'Arial', size: 12 }, visible: true },
            labelling: { name: true, desc: true, script: true, codes: true }
          },
          columns: {
            groups: { font: { name: 'Arial', size: 12 }, visible: true, size: 0 },
            labels: { font: { name: 'Arial', size: 12 }, visible: true, width: 100, height: 30 },
            letters: { font: { name: 'Arial', size: 12 } },
            sort: { active: false, increasing: true, ungrouped: false, band: 0, type: 0, key: 0 },
            hide: { active: false, missing: false, empty: false, vectors: '' },
            baseCount: true
          },
          rows: {
            groups: { font: { name: 'Arial', size: 12 }, visible: true, size: 0 },
            labels: { font: { name: 'Arial', size: 12 }, visible: true, width: 100, height: 30 },
            letters: { font: { name: 'Arial', size: 12 } },
            sort: { active: false, increasing: true, ungrouped: false, band: 0, type: 0, key: 0 },
            hide: { active: false, missing: false, empty: false, vectors: '' },
            baseCount: true
          },
          cells: {
            key: { font: { name: 'Arial', size: 12 }, visible: true },
            bases: { font: { name: 'Arial', size: 12 } },
            frequencies: { font: { name: 'Arial', size: 12 }, visible: true },
            columnPercents: { font: { name: 'Arial', size: 12 }, visible: true },
            rowPercents: { font: { name: 'Arial', size: 12 }, visible: true },
            stat1: { font: { name: 'Arial', size: 12 }, visible: true },
            stat2: { font: { name: 'Arial', size: 12 }, visible: true },
            percentSign: { visible: true },
            missingAsZero: false,
            missingAsBlank: false,
            zeroAsBlank: false,
            blankAsChar: false,
            blankChar: ' ',
            percentsAsProportions: false,
            showRedundant100: false
          },
          significance: {
            visible: false,
            type: 0,
            propStat: 0,
            meanStat: 0,
            statsParam: '',
            letterSequence: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
            letters: 0,
            appendLetters: false,
            headcount: 0,
            meanPoolVariance: false,
            propPooledEst: false,
            continuityCorr: false,
            skipBase30: false,
            skipCell5: false,
            letters64: false,
            oneTailed: false,
            hiLoTest: false,
            sigLevel1: { font: { name: 'Arial', size: 12 }, threshold: 0.05 },
            sigLevel2: { font: { name: 'Arial', size: 12 }, threshold: 0.01 },
            sigLevel3: { font: { name: 'Arial', size: 12 }, threshold: 0.001 }
          },
          decimals: {
            frequencies: 0,
            percents: 0,
            statistics: 2,
            expressions: 2
          },
          output: {
            format: 0
          },
          corner: {
            priority: 0
          }
        },
        spec: {
          topAxis: topNodes.map(mapNodeToGenNode),
          sideAxis: sideNodes.map(mapNodeToGenNode),
          topLock: currentSpec?.spec?.topLock || false,
          sideLock: currentSpec?.spec?.sideLock || false,
          useFilter: filter !== '',
          filter: filter,
          useWeight: weight !== '',
          weight: weight,
          specProperties: currentSpec?.spec?.specProperties || {
            caseFilter: null,
            initAsMissing: false,
            excludeNE: false,
            padHierarchics: false,
            arithOverStats: false,
            topInsert: null,
            sideInsert: null,
            level: null,
            fullStats: false // Add this line
          }
        }
      }

      console.log('Running spec with data:', JSON.stringify(specData, null, 2));
      
      console.log('Attempting to run spec...');

      const response = await carbonClient.runSpec(specData);
      console.log('Spec run response:', response);
      
      setRunSpecResponse(response);
      setIsRunSpecModalOpen(true);

      // The spec is now saved in localStorage by the runSpec method in CarbonClient
      
      // Navigate to the Report page
      navigate('/report', { state: { updatedSpec: true } });
    } catch (error) {
      console.error('Error running spec:', error);
      if (axios.isAxiosError(error)) {
        console.error('Axios error details:', {
          message: error.message,
          status: error.response?.status,
          data: error.response?.data,
          config: error.config
        });
        setErrorMessage(`Error running spec: ${error.message}. Status: ${error.response?.status}. Data: ${JSON.stringify(error.response?.data)}`);
      } else {
        setErrorMessage(`Error running spec: ${(error as Error).message}`);
      }
      console.log('Current spec:', currentSpec);
      console.log('Top nodes:', topNodes);
      console.log('Side nodes:', sideNodes);
    } finally {
      setIsLoading(false);
    }
  }

  const renderNode = (node: ExtendedGenNode, index: number, target: 'top' | 'side', parentNode: ExtendedGenNode | null = null) => {
    if (!node) {
      console.error(`Attempted to render undefined node at index ${index} for ${target}`);
      return null;
    }

    const nodes = target === 'top' ? topNodes : sideNodes;
    let siblings: ExtendedGenNode[] = parentNode ? parentNode.children || [] : nodes;
    let nodeIndex = siblings.findIndex(n => n.id.toString() === node.id.toString());

    const isFirst = nodeIndex === 0;
    const isLast = nodeIndex === siblings.length - 1;
    const isSingle = siblings.length === 1;

    // Format the node name
    const formattedName = node.type === 'Code' 
      ? `${node.value1 || ''} - ${node.value2 || ''}`
      : node.name || node.value1 || node.value2 || '';

    return (
      <Box key={node.id.toString() || `${target}-${index}`}>
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            mb: 1, 
            ml: parentNode ? 3 : 0,
            bgcolor: (target === 'top' ? selectedTopNode : selectedSideNode)?.id === node.id ? 'lightblue' : 'transparent',
            cursor: 'pointer'
          }}
          onClick={() => target === 'top' ? setSelectedTopNode(node) : setSelectedSideNode(node)}
        >
          {getVariableIcon(node, 'variable')}
          <Typography sx={{ mr: 1 }}>{formattedName}</Typography>
          {node.isPC && <Percent fontSize="small" sx={{ mr: 1 }} />}
          {node.isBase && <Typography variant="caption" sx={{ mr: 1 }}>(Base)</Typography>}
          <IconButton 
            onClick={(e) => { e.stopPropagation(); handleMoveUp(target, parentNode ? parentNode.id.toString() : null, nodeIndex); }} 
            disabled={isSingle || isFirst}
            size="small"
          >
            <ArrowUpward fontSize="small" />
          </IconButton>
          <IconButton 
            onClick={(e) => { e.stopPropagation(); handleMoveDown(target, parentNode ? parentNode.id.toString() : null, nodeIndex); }} 
            disabled={isSingle || isLast}
            size="small"
          >
            <ArrowDownward fontSize="small" />
          </IconButton>
          <IconButton onClick={(e) => { e.stopPropagation(); handleDelete(target, parentNode ? parentNode.id.toString() : null, nodeIndex); }} size="small">
            <Delete fontSize="small" />
          </IconButton>
        </Box>
        {node.children && node.children.length > 0 && (
          <Box sx={{ ml: 3 }}>
            {node.children.map((childNode, childIndex) => renderNode(childNode, childIndex, target, node))}
          </Box>
        )}
      </Box>
    )
  }

  const handleNest = (target: 'top' | 'side') => {
    const selectedNode = target === 'top' ? selectedTopNode : selectedSideNode;
    const nodes = target === 'top' ? topNodes : sideNodes;
    if (selectedNode) {
      const index = nodes.findIndex(node => node.id === selectedNode.id);
      if (index > 0) {
        const newNodes = [...nodes];
        newNodes[index - 1] = {
          ...newNodes[index - 1],
          children: [...(newNodes[index - 1].children || []), newNodes[index]]
        };
        newNodes.splice(index, 1);
        target === 'top' ? setTopNodes(newNodes) : setSideNodes(newNodes);
      }
    }
  };

  const handleSetPercentage = (target: 'top' | 'side') => {
    const selectedNode = target === 'top' ? selectedTopNode : selectedSideNode;
    if (selectedNode) {
      const newNodes = (target === 'top' ? topNodes : sideNodes).map((node: ExtendedGenNode) =>
        node.id === selectedNode.id ? { ...node, isPC: true } : node
      );
      target === 'top' ? setTopNodes(newNodes) : setSideNodes(newNodes);
    }
  };

  const handleSetBase = (target: 'top' | 'side') => {
    const selectedNode = target === 'top' ? selectedTopNode : selectedSideNode;
    if (selectedNode) {
      const newNodes = (target === 'top' ? topNodes : sideNodes).map((node: ExtendedGenNode) =>
        node.id === selectedNode.id ? { ...node, isBase: true } : { ...node, isBase: false }
      );
      target === 'top' ? setTopNodes(newNodes) : setSideNodes(newNodes);
    }
  };

  const fetchNodeCodes = async (nodeId: string) => {
    if (!carbonClient || !isClientReady) return;

    try {
      const node = findVariable(variableTree, nodeId);
      if (node) {
        console.log(`Fetching variables for node:`, node);
        const nodeName = node.name || node.value1 || node.id.toString();
        console.log(`Using node name/id: ${nodeName}`);
        
        if (node.children && node.children.length > 0) {
          console.log(`Node has children, displaying them as variables`);
          setSelectedNodeVariables(node.children.map(child => child.name || child.value1 || '').filter(Boolean));
          setErrorMessage(null);
        } else {
          const variables = await carbonClient.getVarNodes(nodeName);
          console.log(`Fetched codes for ${nodeName}:`, variables);
          if (variables.length === 0) {
            console.log(`No codes found for ${nodeName} (ID: ${nodeId})`);
            setSelectedNodeVariables([]);
            setErrorMessage(`No codes found for ${nodeName}`);
          } else {
            setSelectedNodeVariables(variables.map(v => v.name || v.value1 || '').filter(Boolean));
            setErrorMessage(null);
          }
        }
      } else {
        console.error(`Node not found for ID: ${nodeId}`);
        setErrorMessage(`Node not found for ID: ${nodeId}`);
      }
    } catch (error) {
      console.error('Error fetching node variables:', error);
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.message || error.message;
        console.error(`API Error: ${errorMessage}`);
        setErrorMessage(`Error fetching node variables: ${errorMessage}`);
      } else if (error instanceof Error) {
        setErrorMessage(`Error fetching node variables: ${error.message}`);
      } else {
        setErrorMessage(`Unexpected error: ${String(error)}`);
      }
    }
  };

  useEffect(() => {
    console.log('Top nodes updated:', topNodes);
  }, [topNodes]);

  useEffect(() => {
    console.log('Side nodes updated:', sideNodes);
  }, [sideNodes]);

  const handleExpandedItemsChange = useCallback((event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpandedVariableItems(nodeIds);
    nodeIds.filter(id => !expandedVariableItems.includes(id)).forEach(id => {
      const node = findVariable(variableTree, id);
      if (node && (node.type === 'Variable' || node.type === 'Codeframe') && !node.children) {
        handleVariableExpand(id);
      }
    });
  }, [expandedVariableItems, variableTree]);

  const handleSelectedItemsChange = useCallback((event: React.SyntheticEvent, nodeIds: string[]) => {
    console.log('RichTreeView selection changed:', nodeIds);
    setSelectedVariables(nodeIds);
    if (nodeIds.length > 0) {
      const selectedNode = findVariable(variableTree, nodeIds[0]);
      console.log('Selected node:', selectedNode);
      if (selectedNode && selectedNode.type === 'Variable' && !selectedNode.children) {
        handleVariableExpand(nodeIds[0]);
      }
    } else {
      setSelectedNodeVariables([]);
    }
  }, [variableTree]);

  const mapNodeToGenNode = (node: ExtendedGenNode): GenNode => ({
    type: node.type,
    id: node.id.toString(),
    parentId: node.parentId?.toString() || null,
    isExpanded: node.isExpanded,
    isFolder: node.isFolder,
    value1: node.value1 || '',
    value2: node.value2 || '',
    meta: node.meta || [],
    level: node.level || 0,
    anyChildren: node.anyChildren || false,
    children: node.children ? node.children.map(mapNodeToGenNode) : null,
    name: node.name || node.value1 || ''
  });

  const handleValidateSpec = async () => {
    if (!carbonClient || !isClientReady) {
      setErrorMessage('Carbon client is not ready');
      return;
    }

    console.log('CarbonClient:', carbonClient);
    console.log('CarbonClient methods:', Object.getOwnPropertyNames(Object.getPrototypeOf(carbonClient)));
    console.log('validateSpec method:', carbonClient.validateSpec);

    if (typeof carbonClient.validateSpec !== 'function') {
      setErrorMessage('validateSpec method is not available on carbonClient');
      return;
    }

    try {
      setIsLoading(true);

      const specData: RunSpecRequest['spec'] = {
        topAxis: topNodes.map(mapNodeToGenNode),
        sideAxis: sideNodes.map(mapNodeToGenNode),
        topLock: currentSpec?.spec?.topLock || false,
        sideLock: currentSpec?.spec?.sideLock || false,
        useFilter: filter !== '',
        filter: filter,
        useWeight: weight !== '',
        weight: weight,
        specProperties: currentSpec?.spec?.specProperties || {
          caseFilter: null,
          initAsMissing: false,
          excludeNE: false,
          padHierarchics: false,
          arithOverStats: false,
          topInsert: null,
          sideInsert: null,
          level: null,
          fullStats: false // Add this line
        }
      };

      console.log('Validating spec with data:', JSON.stringify(specData, null, 2));
      
      console.log('Attempting to validate spec...');

      const response = await carbonClient.validateSpec(specData);
      console.log('Spec validation response:', response);
      
      setValidateResponse(response);
      setIsValidateModalOpen(true);
    } catch (error) {
      console.error('Error validating spec:', error);
      if (axios.isAxiosError(error)) {
        console.error('Axios error details:', {
          message: error.message,
          status: error.response?.status,
          data: error.response?.data,
          config: error.config
        });
        setErrorMessage(`Error validating spec: ${error.message}. Status: ${error.response?.status}. Data: ${JSON.stringify(error.response?.data)}`);
      } else {
        setErrorMessage(`Error validating spec: ${(error as Error).message}`);
      }
      console.log('Current spec:', currentSpec);
      console.log('Top nodes:', topNodes);
      console.log('Side nodes:', sideNodes);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ErrorBoundary>
      <Box sx={{ 
        display: 'flex', 
        height: 'calc(100vh - 84px)',
        mt: '84px',
        overflow: 'hidden',
        pl: 2,
        pr: 3,
        py: 2,
      }}>
        {/* Left sidebar - Variable Tree */}
        <Paper sx={{ 
          width: 260, 
          p: 2, 
          mr: 2,
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Typography variant="h6" gutterBottom>Variable Tree</Typography>
          {isLoading ? (
            <Typography>Loading variable tree...</Typography>
          ) : errorMessage ? (
            <Typography color="error">{errorMessage}</Typography>
          ) : variableTree.length > 0 ? (
            <RichTreeView<ExtendedGenNode, true>
              items={renderTreeItems(variableTree, 'variable')}
              slots={{
                item: (props: TreeItem2Props) => <CustomTreeItem key={props.itemId} {...props} />
              }}
              expandedItems={expandedVariableItems}
              selectedItems={selectedVariables}
              onExpandedItemsChange={handleExpandedItemsChange}
              onSelectedItemsChange={handleSelectedItemsChange}
              multiSelect={true}
              draggable
              sx={{
                flexGrow: 1,
                overflowY: 'auto',
                '& .MuiTreeItem-content': {
                  padding: '2px 0',
                },
                '& .MuiTreeItem-label': {
                  fontSize: '0.875rem',
                },
                '& .MuiTreeItem-group': {
                  marginLeft: 2,
                  paddingLeft: 1,
                  borderLeft: '1px dashed rgba(0, 0, 0, 0.2)',
                },
              }}
            />
          ) : (
            <Typography>No variables found</Typography>
          )}
        </Paper>

        {/* Right side - Specification content */}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
          {/* Top toolbar */}
          <Paper sx={{ p: 2, mb: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button variant="contained" onClick={handleValidateSpec} disabled={isLoading}>
                  Validate Spec
                </Button>
                <Button variant="contained" onClick={runSpec} disabled={isLoading}>
                  Run Spec
                </Button>
              </Box>
              <Box sx={{ display: 'flex', gap: 2, flexGrow: 1, maxWidth: '50%', justifyContent: 'flex-end' }}>
                <TextField
                  label="Filter"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  size="small"
                  sx={{ flexGrow: 1 }}
                />
                <TextField
                  label="Weight"
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                  size="small"
                  sx={{ flexGrow: 1 }}
                />
              </Box>
            </Box>
          </Paper>

          {/* Main content area */}
          <Paper sx={{ 
            flex: 1, 
            p: 2, 
            overflowY: 'auto', 
            display: 'flex',
            flexDirection: 'column'
          }}>
            {/* Report Title */}
            <TextField
              label="Report Title"
              value={reportTitle}
              onChange={(e) => setReportTitle(e.target.value)}
              fullWidth
              margin="normal"
              sx={{ mb: 2 }}
            />

            {/* Top section */}
            <Typography variant="h6" gutterBottom>Top</Typography>
            <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
              <Button variant="outlined" onClick={() => handleNest('top')} disabled={!selectedTopNode}>Nest</Button>
              <Button variant="outlined" onClick={() => handleSetPercentage('top')} disabled={!selectedTopNode}>Set %</Button>
              <Button variant="outlined" onClick={() => handleSetBase('top')} disabled={!selectedTopNode}>Set Base</Button>
            </Box>
            <Box
              sx={{
                height: '30vh', // Set a fixed height
                border: '1px dashed grey',
                p: 2,
                mb: 3,
                overflowY: 'auto', // Enable vertical scrolling
              }}
              onDragOver={(e) => {
                e.preventDefault();
                e.dataTransfer.dropEffect = 'move';
                console.log('Dragging over top section');
              }}
              onDrop={(e) => handleDrop(e, 'top')}
            >
              {topNodes.length > 0 ? (
                topNodes.map((node, index) => renderNode(node, index, 'top', null))
              ) : (
                <Typography>No top nodes available</Typography>
              )}
            </Box>

            {/* Bottom section */}
            <Typography variant="h6" gutterBottom>Side</Typography>
            <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
              <Button variant="outlined" onClick={() => handleNest('side')} disabled={!selectedSideNode}>Nest</Button>
              <Button variant="outlined" onClick={() => handleSetPercentage('side')} disabled={!selectedSideNode}>Set %</Button>
              <Button variant="outlined" onClick={() => handleSetBase('side')} disabled={!selectedSideNode}>Set Base</Button>
            </Box>
            <Box
              sx={{
                height: '30vh', // Set a fixed height
                border: '1px dashed grey',
                p: 2,
                mb: 3,
                overflowY: 'auto', // Enable vertical scrolling
              }}
              onDragOver={(e) => {
                e.preventDefault();
                e.dataTransfer.dropEffect = 'move';
                console.log('Dragging over side section');
              }}
              onDrop={(e) => handleDrop(e, 'side')}
            >
              {sideNodes.length > 0 ? (
                sideNodes.map((node, index) => renderNode(node, index, 'side', null))
              ) : (
                <Typography>No side nodes available</Typography>
              )}
            </Box>
          </Paper>
        </Box>
      </Box>

      {/* Validate Spec Modal */}
      <Modal
        open={isValidateModalOpen}
        onClose={() => setIsValidateModalOpen(false)}
        aria-labelledby="validate-spec-modal"
        aria-describedby="validate-spec-response"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="validate-spec-modal" variant="h6" component="h2">
            Spec Validation Result
          </Typography>
          <Typography id="validate-spec-response" sx={{ mt: 2 }}>
            {validateResponse ? JSON.stringify(validateResponse, null, 2) : 'No response'}
          </Typography>
        </Box>
      </Modal>

      {/* Run Spec Modal */}
      <Modal
        open={isRunSpecModalOpen}
        onClose={() => setIsRunSpecModalOpen(false)}
        aria-labelledby="run-spec-modal"
        aria-describedby="run-spec-response"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="run-spec-modal" variant="h6" component="h2">
            Run Spec Result
          </Typography>
          <Typography id="run-spec-response" sx={{ mt: 2 }}>
            {runSpecResponse ? JSON.stringify(runSpecResponse, null, 2) : 'No response'}
          </Typography>
        </Box>
      </Modal>
    </ErrorBoundary>
  )
}

export default Specification
