// Comment: Importing necessary dependencies
import React, { useState, useEffect, useCallback } from 'react'
import { Box, Paper, Typography, TextField, Button, Divider, IconButton, Tooltip, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from '@mui/material'
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { List, ListItem, ListItemText } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { TocNode } from '../types/types'
import { useAuth } from '../contexts/AuthContext'
import { XlsxResponse } from '../types/types'
import * as XLSX from 'xlsx'
import { parseXlsxData, ParsedXlsxData } from '../utils/xlsxUtils';
import { XlsxTable } from '../components/XlsxTable';
import { useJob } from '../contexts/JobContext';
import { useSpec } from '../contexts/SpecContext';
import { useNavigate } from 'react-router-dom';
import { RunSpecRequest, SpecAggregate } from '../types/types';
import { useLocation } from 'react-router-dom';
import { QuickUpdateRequest } from '../types/types';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useCarbonClient } from '../hooks/useCarbonClient';
import { GenericResponse } from '../types/types';

// Comment: Defining the Report component
const Report: React.FC = () => {
  const { selectedCustomer, selectedJob, setSelectedCustomer, setSelectedJob, jobInfo, setJobInfo, setDProps } = useJob();
  const { setCurrentSpec, setSpecAggregate } = useSpec();
  const navigate = useNavigate();
  const location = useLocation();
  const { carbonClient, isAuthenticated, sessionInfo, isClientReady } = useAuth();

  // Add this state near your other state declarations
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  })
  
  // Add this state at the top of your component
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

// In the return statement, add this before the SpreadSheets component
{errorMessage && (
  <Typography color="error" sx={{ mb: 2 }}>
    Error: {errorMessage}
  </Typography>
)}

  // Comment: Add state for TOC nodes
  const [tocNodes, setTocNodes] = useState<TocNode[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  // Comment: Add state for customer and job selection
  const [expandedItems, setExpandedItems] = useState<string[]>([])

  // Comment: Add state for report selection and data
  const [selectedReport, setSelectedReport] = useState<string | null>(null)
  const [reportData, setReportData] = useState<XlsxResponse | null>(null)
  const [metadata, setMetadata] = useState<{ name: string; top: string; side: string }>({
    name: '',
    top: '',
    side: '',
  })
  const [tableData, setTableData] = useState<string[][]>([]);
  const [parsedXlsxData, setParsedXlsxData] = useState<ParsedXlsxData | null>(null);
  const [reportTitle, setReportTitle] = useState<string>('');

  // Add this state if not already present
  const [filteredNodes, setFilteredNodes] = useState<TocNode[]>([])

  const handleExpandedItemsChange = (
    event: React.SyntheticEvent,
    itemIds: string[],
  ) => {
    setExpandedItems(itemIds);
  };

  // Comment: Add a function to clear the search term
  const handleClearSearch = () => {
    setSearchTerm('')
  }

  // Add this function near the top of your component, after the state declarations
  const fetchData = async () => {
    if (isAuthenticated && carbonClient && selectedCustomer && selectedJob) {
      try {
        const openJobResponse = await carbonClient.openCloudJob(selectedCustomer, selectedJob);
        console.log('Open job response:', openJobResponse);
        
        if (openJobResponse.dProps) {
          console.log('dProps received in openCloudJob response:', openJobResponse.dProps);
        } else {
          console.warn('No dProps in openCloudJob response');
        }

        const nodes = await carbonClient.getTocNodes()
        
        // Filter and restructure the nodes
        const filteredNodes = nodes.filter(node => node.name === 'Exec' || node.name === 'User')
        const userNode = filteredNodes.find(node => node.name === 'User')
        if (userNode && userNode.children) {
          userNode.children = userNode.children.filter(child => 
            child.name === sessionInfo?.email
          )
        }
        setFilteredNodes(filteredNodes)

        // Check localStorage after job is opened
        const storedDProps = localStorage.getItem('dProps');
        console.log('dProps in localStorage after job open:', storedDProps);

      } catch (error) {
        console.error('Error fetching data:', error)
        setErrorMessage(`Error fetching data: ${(error as Error).message}`)
      }
    } else if (!isAuthenticated) {
      setErrorMessage("Not authenticated. Please log in and try again.")
    }
  }

  // Update the useEffect hook to call fetchData
  useEffect(() => {
    fetchData();
  }, [isAuthenticated, carbonClient, selectedCustomer, selectedJob, sessionInfo]);

  // Comment: Effect to update expanded nodes when filter changes
  useEffect(() => {
    if (searchTerm) {
      const newExpanded = findExpandedNodes(filteredNodes, searchTerm)
      setExpandedItems(newExpanded)
    } else {
      setExpandedItems([])
    }
  }, [searchTerm, filteredNodes])

  // Comment: Helper function to find nodes that should be expanded
  const findExpandedNodes = (nodes: TocNode[], filter: string): string[] => {
    return nodes.reduce<string[]>((acc, node) => {
      const matchesFilter = node.name?.toLowerCase().includes(filter.toLowerCase())
      const childMatches = node.children ? findExpandedNodes(node.children, filter) : []
      
      if (matchesFilter || childMatches.length > 0) {
        acc.push(node.id.toString())
        acc.push(...childMatches)
      }
      
      return acc
    }, [])
  }

  // Comment: New function to flatten the tree structure
  const flattenTree = (nodes: TocNode[], filter: string): TocNode[] => {
    return nodes.reduce<TocNode[]>((acc, node) => {
      if (node.name?.toLowerCase().includes(filter.toLowerCase())) {
        acc.push(node)
      }
      if (node.children) {
        acc.push(...flattenTree(node.children, filter))
      }
      return acc
    }, [])
  }

  // Update the getFullPath function to always prepend "Tables"
  const getFullPath = (nodes: TocNode[], nodeId: string): string | null => {
    const findPath = (currentNodes: TocNode[], id: string, path: string[]): string | null => {
      for (const node of currentNodes) {
        if (node.id.toString() === id) {
          return ['Tables', ...path, node.name || ''].join('/')
        }
        if (node.children) {
          const result = findPath(node.children, id, [...path, node.name || ''])
          if (result) return result
        }
      }
      return null
    }
    return findPath(nodes, nodeId, [])
  }

  // Rename this function to avoid conflict with the imported function
  const handleXlsxData = async (data: Uint8Array, xlsxResponse: XlsxResponse) => {
    console.log('Parsing XLSX data...');
    const parsedData = parseXlsxData(data);
    console.log('Parsed XLSX data:', parsedData);
    setParsedXlsxData(parsedData);
    setReportTitle(xlsxResponse.reportName || 'Unnamed Report');
  };

  // Add this function to log the table spec
  const logTableSpec = (spec: Partial<RunSpecRequest['spec']> | Partial<SpecAggregate['spec']>) => {
    console.log('Table Spec:', spec);
    // Add any additional logging logic here
  };

  // Update the handleReportSelect function
  const handleReportSelect = async (nodeId: string) => {
    setErrorMessage(null);
    try {
      if (!carbonClient) {
        throw new Error('Carbon client is not initialized');
      }

      const fullPath = getFullPath(filteredNodes, nodeId);
      if (!fullPath) {
        throw new Error('Unable to determine full path for the selected report');
      }
      
      console.log(`Attempting to load report: ${fullPath}`);
      
      // Step 1: Load the report
      const loadResponse: GenericResponse = await carbonClient.LoadReportAsync(fullPath);
      
      // Check if the load was successful based on the code property
      if (loadResponse.code !== 0) {
        throw new Error(`Failed to load report: ${loadResponse.message}`);
      }

      console.log('Report loaded successfully');

      // Step 2: Generate XLSX
      console.log('Generating XLSX for loaded report');
      const xlsxResponse: XlsxResponse = await carbonClient.generateXlsx();

      if (!xlsxResponse.reportName) {
        throw new Error('No report name returned in XLSX response');
      }

      setReportData(xlsxResponse);

      // Fetch spec separately if not included in xlsxResponse
      try {
        const specAggregateData = await carbonClient.getSpecAggregate();
        console.log('Spec fetched successfully:', specAggregateData);
        setSpecAggregate(specAggregateData);
        
        if (specAggregateData && specAggregateData.spec) {
          setCurrentSpec(specAggregateData.spec);
        } else {
          console.log('No spec found in specAggregateData');
          // Create a default RunSpecRequest
          const defaultSpec: RunSpecRequest = {
            name: 'Default Spec',
            dProps: {},
            spec: {
              topAxis: [],
              sideAxis: [],
              topLock: false,
              sideLock: false,
              useFilter: false,
              filter: null,
              useWeight: false,
              weight: null,
              specProperties: {
                caseFilter: null,
                initAsMissing: false,
                excludeNE: false,
                padHierarchics: false,
                arithOverStats: false,
                topInsert: null,
                sideInsert: null,
                level: null,
                fullStats: false // Add this line
              }
            }
          };
          setCurrentSpec(defaultSpec);
          console.log('Using default spec:', JSON.stringify(defaultSpec, null, 2));
          logTableSpec(defaultSpec.spec);
        }
      } catch (error) {
        console.error('Error fetching spec:', error);
      }

      // Step 3: Fetch the XLSX data
      if (xlsxResponse?.excelUri) {
        console.log('Fetching XLSX data...');
        const response = await fetch(xlsxResponse.excelUri);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const arrayBuffer = await response.arrayBuffer();
        
        // Convert ArrayBuffer to Uint8Array
        const uint8Array = new Uint8Array(arrayBuffer);
        
        // Parse XLSX data
        await handleXlsxData(uint8Array, xlsxResponse);
        
        console.log('XLSX data fetched and parsed successfully');
      } else {
        throw new Error('Excel URI is missing from the response');
      }

      setSelectedReport(fullPath);
    } catch (error) {
      console.error('Error loading report:', error);
      setErrorMessage((error as Error).message);
    }
  };

  // Update the renderTreeItems function
  const renderTreeItems = (nodes: TocNode[]): React.ReactNode[] => {
    return nodes.map(node => {
      // Remove '.cbt' from the node name
      const displayName = node.name ? node.name.replace(/\.cbt$/, '') : '';

      return (
        <TreeItem
          key={node.id}
          itemId={node.id.toString()}
          label={displayName}
          onClick={() => {
            if (node.type === 'Table') {
              console.log('Selected node:', node);
              handleReportSelect(node.id.toString());
            }
          }}
        >
          {node.children && renderTreeItems(node.children)}
        </TreeItem>
      );
    });
  }

  // Update the renderFilteredTreeItems function
  const renderFilteredTreeItems = (nodes: TocNode[], filter: string): React.ReactNode[] => {
    const flattenedNodes: React.ReactNode[] = [];

    const flattenTree = (currentNodes: TocNode[], parentName: string = '') => {
      currentNodes.forEach(node => {
        // Remove '.cbt' from the node name
        const nodeName = (node.name || '').replace(/\.cbt$/, '');
        const matchesFilter = nodeName.toLowerCase().includes(filter.toLowerCase());
        const fullName = parentName ? `${parentName} / ${nodeName}` : nodeName;

        if (matchesFilter) {
          flattenedNodes.push(
            <TreeItem 
              key={node.id} 
              itemId={node.id.toString()} 
              label={fullName}
              onClick={() => {
                if (node.type === 'Table') {
                  handleReportSelect(node.id.toString());
                }
              }}
            />
          );
        }
        
        if (node.children) {
          flattenTree(node.children, fullName);
        }
      });
    };

    flattenTree(nodes);
    return flattenedNodes;
  }

  // Update the renderFilteredItems function
  const renderFilteredItems = (nodes: TocNode[], filter: string) => {
    return (
      <TreeView
        expandedItems={expandedItems}
        onExpandedItemsChange={handleExpandedItemsChange}
      >
        {filter ? renderFilteredTreeItems(nodes, filter) : renderTreeItems(nodes)}
      </TreeView>
    )
  }

  // Add this constant to determine if the filter should be disabled
  const isFilterDisabled = !selectedCustomer || !selectedJob

  // Add new state for display options
  const [showFreq, setShowFreq] = useState(true);
  const [showColPct, setShowColPct] = useState(false);
  const [showRowPct, setShowRowPct] = useState(false);
  const [showSig, setShowSig] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [optionsChanged, setOptionsChanged] = useState(false);

  const handleOptionToggle = (option: 'freq' | 'colPct' | 'rowPct' | 'sig') => {
    setOptionsChanged(true);
    switch (option) {
      case 'freq':
        setShowFreq(!showFreq);
        break;
      case 'colPct':
        setShowColPct(!showColPct);
        break;
      case 'rowPct':
        setShowRowPct(!showRowPct);
        break;
      case 'sig':
        setShowSig(!showSig);
        break;
    }
  };

  // Update the updateReport function
  const updateReport = useCallback(async () => {
    if (!carbonClient || !selectedReport) return;
    
    setIsUpdating(true);
    setErrorMessage(null);
    
    try {
      if (typeof carbonClient.updateReportDisplay !== 'function') {
        throw new Error('updateReportDisplay method is not available');
      }

      const response = await carbonClient.updateReportDisplay({
        ShowFreq: showFreq,
        ShowColPct: showColPct,
        ShowRowPct: showRowPct,
        ShowSig: showSig,
        Filter: ""
      });
      
      console.log('Full updateReportDisplay response:', JSON.stringify(response, null, 2));

      if ('spec' in response && response.spec) {
        console.log('Logging updated table spec:');
        logTableSpec(response.spec);
      } else {
        console.log('No spec found in response. Response structure:', Object.keys(response));
      }

      if (response.excelUri) {
        const fetchResponse = await fetch(response.excelUri);
        if (!fetchResponse.ok) {
          throw new Error(`HTTP error! status: ${fetchResponse.status}`);
        }
        const arrayBuffer = await fetchResponse.arrayBuffer();
        const uint8Array = new Uint8Array(arrayBuffer);
        
        await handleXlsxData(uint8Array, response);
        setReportData(response); // Update the reportData state
        setOptionsChanged(false);
      } else {
        throw new Error('Excel URI is missing from the response');
      }
    } catch (error) {
      console.error('Error updating report:', error);
      setErrorMessage(`Error updating report: ${(error as Error).message}`);
    } finally {
      setIsUpdating(false);
    }
  }, [carbonClient, selectedReport, showFreq, showColPct, showRowPct, showSig]);

  // Add this state for error handling
  const [localStorageError, setLocalStorageError] = useState<string | null>(null);

  // Update the useEffect hook to include error handling
  useEffect(() => {
    try {
      const savedSpec = localStorage.getItem('currentSpec');
      const savedSpecAggregate = localStorage.getItem('specAggregate');

      if (savedSpec) {
        const parsedSpec = JSON.parse(savedSpec);
        setCurrentSpec(parsedSpec);
      }

      if (savedSpecAggregate) {
        const parsedSpecAggregate = JSON.parse(savedSpecAggregate);
        setSpecAggregate(parsedSpecAggregate);
      }
    } catch (error) {
      console.error('Error parsing data from localStorage:', error);
      setLocalStorageError('Failed to load saved data. Please try refreshing the page.');
    }
  }, [setCurrentSpec, setSpecAggregate]);

  const handleNewSpec = async () => {
    if (!carbonClient || !isClientReady) {
      setErrorMessage('Carbon client is not ready');
      return;
    }

    try {
      setIsLoading(true);
      const newSpec = await carbonClient.getNewSpec();
      
      // Save the new spec to localStorage
      localStorage.setItem('currentSpec', JSON.stringify(newSpec));
      
      // Navigate to the Specification page
      navigate('/specification');
    } catch (error) {
      console.error('Error getting new spec:', error);
      setErrorMessage(`Error getting new spec: ${(error as Error).message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Add this state near the top of your component, with other state declarations
  const [isLoading, setIsLoading] = useState(false);

  const [isNewSpecRun, setIsNewSpecRun] = useState(false);

  useEffect(() => {
    if (location.state && location.state.updatedSpec) {
      setIsNewSpecRun(true);
    }
  }, [location]);

  useEffect(() => {
    const loadUpdatedSpec = async () => {
      if (carbonClient && isClientReady && isNewSpecRun) {
        try {
          setIsLoading(true);
          setErrorMessage(null);

          console.log('Loading updated spec...');

          // Fetch the current spec
          const currentSpec = await carbonClient.getCurrentSpec();
          if (!currentSpec) {
            throw new Error('No current spec found');
          }
          console.log('Current spec:', currentSpec);

          // Generate XLSX for the current spec
          const specName = currentSpec.name || 'Unnamed Spec';
          console.log('Generating XLSX for spec:', specName);
          const xlsxResponse = await carbonClient.getReportXlsx(specName);
          setReportData(xlsxResponse);
          console.log('Updated report data:', xlsxResponse);

          if (xlsxResponse?.excelUri) {
            console.log('Fetching Excel data from:', xlsxResponse.excelUri);
            const response = await fetch(xlsxResponse.excelUri);
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const arrayBuffer = await response.arrayBuffer();
            const uint8Array = new Uint8Array(arrayBuffer);
            
            // Parse XLSX data
            await handleXlsxData(uint8Array, xlsxResponse);
          } else {
            throw new Error('Excel URI is missing from the response');
          }

          setIsNewSpecRun(false);
        } catch (error) {
          console.error('Error fetching updated report data:', error);
          setErrorMessage(`Error fetching updated report data: ${(error as Error).message}`);
        } finally {
          setIsLoading(false);
        }
      }
    };

    loadUpdatedSpec();
  }, [carbonClient, isClientReady, isNewSpecRun]);

  // Add a new state for the "Save As" dialog
  const [isSaveAsDialogOpen, setIsSaveAsDialogOpen] = useState(false);
  const [newReportName, setNewReportName] = useState('');
  const [newReportFolder, setNewReportFolder] = useState('');

  // Add a handler for the "Save As" button
  const handleSaveAs = () => {
    setIsSaveAsDialogOpen(true);
  };

  // Update the handleSaveAsConfirm function
  const handleSaveAsConfirm = async () => {
    console.log('handleSaveAsConfirm called');
    if (!carbonClient) {
      console.error('Carbon client is not available');
      setErrorMessage('Carbon client is not available');
      return;
    }
    if (!isClientReady) {
      console.error('Carbon client is not ready');
      setErrorMessage('Carbon client is not ready');
      return;
    }
    console.log('Carbon client methods:', Object.getOwnPropertyNames(Object.getPrototypeOf(carbonClient)));
    console.log('Carbon client properties:', Object.keys(carbonClient));
    if (typeof carbonClient.saveReport !== 'function') {
      console.error('saveReport method is not available on carbonClient');
      setErrorMessage('saveReport method is not available');
      return;
    }
    if (!reportData) {
      console.error('No report data available');
      setErrorMessage('No report data available');
      return;
    }

    try {
      setIsLoading(true);
      setErrorMessage(null);

      console.log('Attempting to save report with name:', newReportName, 'in folder:', newReportFolder);

      // Call the Carbon client to save the report with a new name and folder
      const saveReportResponse = await carbonClient.saveReport(newReportName, newReportFolder);

      console.log('Save report response:', saveReportResponse);

      if (saveReportResponse.code === 0) {  // Assuming 0 means success
        console.log('Report saved successfully:', saveReportResponse);
        
        // Reload the TOC to reflect the new report
        await fetchData();

        // Update the current report data with the new name
        setReportData({...reportData, reportName: newReportName});
        setReportTitle(newReportName);

        setIsSaveAsDialogOpen(false);
        setNewReportName('');
        setNewReportFolder('');
      } else {
        throw new Error(`Failed to save report: ${saveReportResponse.message}`);
      }
    } catch (error) {
      console.error('Error saving report:', error);
      setErrorMessage(`Error saving report: ${(error as Error).message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Add this near the top of your main content area
  {localStorageError && (
    <Typography color="error" sx={{ mb: 2 }}>
      {localStorageError}
    </Typography>
  )}

  // Add this near the top of your main content area
  {localStorageError && (
    <Typography color="error" sx={{ mb: 2 }}>
      {localStorageError}
    </Typography>
  )}

  // "Save As" dialog
  <Dialog open={isSaveAsDialogOpen} onClose={() => setIsSaveAsDialogOpen(false)}>
    <DialogTitle>Save Report As</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        label="New Report Name"
        fullWidth
        value={newReportName}
        onChange={(e) => setNewReportName(e.target.value)}
      />
      <TextField
        margin="dense"
        label="Folder Name"
        fullWidth
        value={newReportFolder}
        onChange={(e) => setNewReportFolder(e.target.value)}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setIsSaveAsDialogOpen(false)}>Cancel</Button>
      <Button onClick={handleSaveAsConfirm} disabled={!newReportName.trim() || !newReportFolder.trim()}>Save</Button>
    </DialogActions>
  </Dialog>

  // Comment: Rendering the layout structure of the Report page
  return (
    <Box sx={{ 
      display: 'flex', 
      height: 'calc(100vh - 84px)',
      mt: '84px',
      overflow: 'hidden',
      pl: 2,
      pr: 3,
      py: 2,
    }}>
      {/* Left sidebar - TOC */}
      <Paper sx={{ 
        width: 260, 
        p: 2, 
        mr: 2,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Typography variant="h6" gutterBottom>Table of Contents</Typography>
        <TextField
          fullWidth
          size="small"
          placeholder="Quick Filter"
          sx={{ mb: 2 }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          disabled={isFilterDisabled}
          InputProps={{
            endAdornment: (
              <Tooltip title={isFilterDisabled ? "Select a customer and job first" : "Clear Text"}>
                <span>
                  <IconButton
                    sx={{ visibility: searchTerm && !isFilterDisabled ? 'visible' : 'hidden' }}
                    onClick={handleClearSearch}
                    edge="end"
                    disabled={isFilterDisabled}
                  >
                    <ClearIcon />
                  </IconButton>
                </span>
              </Tooltip>
            ),
          }}
        />
        <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
          {renderFilteredItems(filteredNodes, searchTerm)}
        </Box>
      </Paper>

      {/* Right side - Report content */}
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        {/* Top toolbar */}
        <Paper sx={{ p: 2, mb: 2 }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button variant="contained" onClick={handleNewSpec}>New</Button>
            <Button 
              variant="contained" 
              onClick={() => navigate('/specification')}
            >
              Edit
            </Button>
            <Button 
              variant="contained" 
              disabled={!reportData}
              onClick={handleSaveAs}
            >
              Save As
            </Button>
            <Button 
              variant="contained" 
              disabled={!reportData || !reportData.excelUri}
              onClick={() => {
                if (reportData && reportData.excelUri) {
                  window.open(reportData.excelUri, '_blank');
                }
              }}
            >
              Excel Download
            </Button>
          </Box>
        </Paper>

        {/* Report content area */}
        <Paper sx={{ 
          flex: 1, 
          p: 2, 
          overflowY: 'auto', 
          display: 'flex',
          flexDirection: 'column'
        }}>
          {localStorageError && (
            <Typography color="error" sx={{ mb: 2 }}>
              {localStorageError}
            </Typography>
          )}
          {errorMessage && (
            <Typography color="error" sx={{ mb: 2 }}>
              Error: {errorMessage}
            </Typography>
          )}
          {isLoading ? (
            <Typography>Loading updated report data...</Typography>
          ) : parsedXlsxData ? (
            <XlsxTable 
              data={parsedXlsxData} 
              reportTitle={reportTitle || selectedReport || ''}
              showFreq={showFreq}
              showColPct={showColPct}
              showRowPct={showRowPct}
              showSig={showSig}
              isUpdating={isUpdating}
              optionsChanged={optionsChanged}
              onOptionToggle={handleOptionToggle}
              onUpdateReport={updateReport}
            />
          ) : (
            <Typography>No report data available. Please run a spec or select a report.</Typography>
          )}
        </Paper>
      </Box>

      {/* "Save As" dialog */}
      <Dialog open={isSaveAsDialogOpen} onClose={() => setIsSaveAsDialogOpen(false)}>
        <DialogTitle>Save Report As</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="New Report Name"
            fullWidth
            value={newReportName}
            onChange={(e) => setNewReportName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Folder Name"
            fullWidth
            value={newReportFolder}
            onChange={(e) => setNewReportFolder(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsSaveAsDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSaveAsConfirm} disabled={!newReportName.trim() || !newReportFolder.trim()}>Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Report;