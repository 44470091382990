// Comment: Importing necessary dependencies and types
import React, { createContext, useState, useContext, ReactNode, useEffect, useRef } from 'react'
import { CarbonClient } from '../services/CarbonClient'
import { SessionInfo } from '../types/types'
import { AuthContextType } from '../types/types'
import { useNavigate } from 'react-router-dom'

// Export the AuthContext and AuthContextType
export const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [sessionInfo, setSessionInfo] = useState<SessionInfo | null>(() => {
    const storedSessionInfo = localStorage.getItem('sessionInfo')
    return storedSessionInfo ? JSON.parse(storedSessionInfo) : null
  })
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const navigate = useNavigate()

  const [carbonClient] = useState<CarbonClient>(new CarbonClient())
  const prevSessionInfoRef = useRef<SessionInfo | null>(null)

  const [isClientReady, setIsClientReady] = useState(false)

  useEffect(() => {
    const initializeClient = async () => {
      try {
        // Remove the initialize call if it's not needed
        // await carbonClient.initialize()
        setIsClientReady(true)
      } catch (error) {
        console.error('Failed to initialize Carbon client:', error)
        setIsClientReady(false)
      }
    }

    initializeClient()
  }, [carbonClient])

  // This effect runs whenever sessionInfo changes
  useEffect(() => {
    // Check if the sessionInfo has changed
    if (JSON.stringify(prevSessionInfoRef.current) !== JSON.stringify(sessionInfo)) {
      console.log('----------')
      console.log('Authentication status:', sessionInfo !== null)
      console.log('Session info:', sessionInfo)
      console.log('----------')

      if (sessionInfo) {
        carbonClient.setSessionInfo(sessionInfo)
      } else {
        carbonClient.setSessionInfo(null)
      }

      // Update the ref to the current sessionInfo
      prevSessionInfoRef.current = sessionInfo
    }
  }, [sessionInfo, carbonClient])

  // Login function
  const login = async (name: string, password: string) => {
    try {
      setIsLoading(true)
      const newSessionInfo = await carbonClient.authenticate(name, password) as SessionInfo
      setSessionInfo(newSessionInfo)
      localStorage.setItem('sessionInfo', JSON.stringify(newSessionInfo))
      navigate('/')
    } catch (error) {
      setError('Authentication failed')
      console.error('Login failed:', error)
    } finally {
      setIsLoading(false)
    }
  }

  // Logout function
  const logout = async () => {
    try {
      // Attempt to log off on the server
      await carbonClient.logoff()
    } catch (error) {
      // Log the error, but don't throw it
      console.error('Logout failed:', error)
    } finally {
      // Regardless of server response, clear local session data
      setSessionInfo(null)
      localStorage.removeItem('sessionInfo')
      carbonClient.setSessionInfo(null)
      // Optionally, redirect to login page
      navigate('/login')
    }
  }

  return (
    <AuthContext.Provider value={{
      isAuthenticated: sessionInfo !== null, 
      sessionInfo,
      login,
      logout,
      isLoading,
      error,
      carbonClient,
      isClientReady
    }}>
      {children}
    </AuthContext.Provider>
  )
}

// Comment: Implementing the useAuth hook
export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}