import React, { useState, useEffect } from 'react'
import { AppBar, Box, Toolbar, IconButton, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, TextField, InputAdornment } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import SettingsIcon from '@mui/icons-material/Settings'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { useAuth } from '../../contexts/AuthContext'
import bayesPriceLogo from '../../assets/img/bayes-price-logo.png'
import DebugModal from '../DebugModal'
import { useJob } from '../../contexts/JobContext'
import { useCarbonClient } from '../../hooks/useCarbonClient'

const PageHeader: React.FC = () => {
  const { isAuthenticated, logout } = useAuth()
  const navigate = useNavigate()
  const [isDebugModalOpen, setIsDebugModalOpen] = useState(false)
  const { selectedCustomer, selectedJob, setSelectedCustomer, setSelectedJob } = useJob()
  const carbonClient = useCarbonClient()

  const [customers, setCustomers] = useState<string[]>([])
  const [jobs, setJobs] = useState<string[]>([])
  const [searchTerm, setSearchTerm] = useState('')

  // Handle logout
  const handleLogout = () => {
    logout()
    navigate('/')  // Redirect to home page after logout
  }

  // Fetch customers
  useEffect(() => {
    const fetchCustomers = async () => {
      if (carbonClient) {
        try {
          const customerList = await carbonClient.getCustomers()
          setCustomers(customerList)
        } catch (error) {
          console.error('Error fetching customers:', error)
        }
      }
    }

    fetchCustomers()
  }, [carbonClient])

  // Fetch jobs
  useEffect(() => {
    const fetchJobs = async () => {
      if (carbonClient && selectedCustomer) {
        try {
          const jobList = await carbonClient.getJobs(selectedCustomer)
          setJobs(jobList)
        } catch (error) {
          console.error('Error fetching jobs:', error)
        }
      }
    }

    fetchJobs()
  }, [carbonClient, selectedCustomer])

  // Handle customer change
  const handleCustomerChange = (event: SelectChangeEvent<string>) => {
    setSelectedCustomer(event.target.value)
    setSelectedJob('') // Reset job when customer changes
  }

  // Handle job change
  const handleJobChange = (event: SelectChangeEvent<string>) => {
    setSelectedJob(event.target.value)
  }

  // Clear customer selection
  const handleClearCustomer = () => {
    setSelectedCustomer('')
    setSelectedJob('')
  }

  // Clear job selection
  const handleClearJob = () => {
    setSelectedJob('')
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar sx={{ justifyContent: 'space-between', px: 2, height: 84 }}> {/* Increased height by 20px */}
        {/* Logo on the left */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={bayesPriceLogo} alt="Bayes Price Logo" style={{ height: '50px' }} />
        </Box>
        
        {/* Customer, Job selects, and Search box aligned to the right */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 2, 
          ml: 'auto',
          backgroundColor: '#F0F0F0',
          padding: '8px',
          borderRadius: '4px',
        }}>
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel>Customer</InputLabel>
            <Select
              value={selectedCustomer || ''}
              label="Customer"
              onChange={handleCustomerChange}
              sx={{ 
                width: 220, 
                maxWidth: 220, 
                backgroundColor: 'white',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                },
              }}
              endAdornment={
                selectedCustomer && (
                  <IconButton
                    size="small"
                    sx={{ marginRight: 2 }}
                    onClick={handleClearCustomer}
                  >
                    <ClearIcon />
                  </IconButton>
                )
              }
            >
              {customers.map((customer) => (
                <MenuItem key={customer} value={customer}>{customer}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel>Job</InputLabel>
            <Select
              value={selectedJob || ''}
              label="Job"
              onChange={handleJobChange}
              disabled={!selectedCustomer}
              sx={{ 
                width: 220, 
                maxWidth: 220, 
                backgroundColor: 'white',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                },
              }}
              endAdornment={
                selectedJob && (
                  <IconButton
                    size="small"
                    sx={{ marginRight: 2 }}
                    onClick={handleClearJob}
                  >
                    <ClearIcon />
                  </IconButton>
                )
              }
            >
              {jobs.map((job) => (
                <MenuItem key={job} value={job}>{job}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            size="small"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ 
              width: 200, 
              backgroundColor: 'white',
              '& .MuiOutlinedInput-root': {
                borderRadius: '4px',
                '& fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        
        {/* Gear icon for debug modal */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            color="inherit"
            onClick={() => setIsDebugModalOpen(true)}
            sx={{ ml: 2 }}
          >
            <SettingsIcon />
          </IconButton>
        </Box>
      </Toolbar>
      <DebugModal open={isDebugModalOpen} onClose={() => setIsDebugModalOpen(false)} />
    </AppBar>
  )
}

export default PageHeader