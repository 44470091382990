import * as XLSX from 'xlsx';

interface CellData {
  value: string;
  rowSpan: number;
  colSpan: number;
  isBold: boolean; // Add this property
}

export interface ParsedXlsxData {
  data: CellData[][];
  metadata: {
    name: string;
    top: string;
    side: string;
  };
}

export function parseXlsxData(data: Uint8Array): ParsedXlsxData {
  const workbook = XLSX.read(data, { type: 'array' });
  const sheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[sheetName];

  const range = XLSX.utils.decode_range(worksheet['!ref'] || 'A1');
  const parsedData: CellData[][] = [];

  // Parse metadata
  const metadata = {
    name: (worksheet['A1']?.v as string) || '',
    top: (worksheet['A2']?.v as string) || '',
    side: (worksheet['A3']?.v as string) || '',
  };

  // Find the start of the actual data
  let dataStartRow = 0;
  for (let R = range.s.r; R <= range.e.r; ++R) {
    const cell = worksheet[XLSX.utils.encode_cell({ r: R, c: 0 })];
    if (cell && cell.v === 'Acquired markers') {
      dataStartRow = R;
      break;
    }
  }

  // Parse table data
  for (let R = dataStartRow; R <= range.e.r; ++R) {
    const row: CellData[] = [];
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
      const cell = worksheet[cellAddress];
      const mergeCell = (worksheet['!merges'] || []).find(
        (m) => R >= m.s.r && R <= m.e.r && C >= m.s.c && C <= m.e.c
      );

      if (mergeCell && (R > mergeCell.s.r || C > mergeCell.s.c)) {
        // Skip cells that are part of a merge but not the top-left cell
        continue;
      }

      const cellData: CellData = {
        value: cell ? XLSX.utils.format_cell(cell) : '',
        rowSpan: mergeCell ? mergeCell.e.r - mergeCell.s.r + 1 : 1,
        colSpan: mergeCell ? mergeCell.e.c - mergeCell.s.c + 1 : 1,
        isBold: cell && cell.s && cell.s.font && cell.s.font.bold // Add this line
      };

      row.push(cellData);
    }
    parsedData.push(row);
  }

  return { data: parsedData, metadata };
}