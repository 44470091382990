// Set up a custom MUI v5 theme
// This theme will define typography sizes and colors

import { createTheme } from '@mui/material/styles'

// Define the custom theme
const theme = createTheme({
  typography: {
    // Set font sizes for different variants
    h1: {
      fontSize: '3rem',
    },
    h2: {
      fontSize: '2.5rem',
    },
    h3: {
      fontSize: '2rem',
    },
    h4: {
      fontSize: '1.75rem',
    },
    h5: {
      fontSize: '1.5rem',
    },
    h6: {
      fontSize: '1.25rem',
    },
    subtitle1: {
      fontSize: '1rem',
    },
    subtitle2: {
      fontSize: '0.875rem',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
    button: {
      fontSize: '0.875rem',
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: '0.75rem',
    },
    overline: {
      fontSize: '0.75rem',
      textTransform: 'uppercase',
    },
  },
  palette: {
    primary: {
      main: '#109DAE',
    },
    secondary: {
      main: '#dc004e',
    },
    text: {
      primary: '#212121',
      secondary: '#757575',
    },
    background: {
      default: '#f0f0f0',
      paper: '#fff',
    },
  },
})

// Export the custom theme
export default theme
