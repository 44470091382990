import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Box, useTheme, Collapse } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import StorageIcon from '@mui/icons-material/Storage';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import HomeIcon from '@mui/icons-material/Home';
import TableChartIcon from '@mui/icons-material/TableChart';
import PlayArrowIcon from '@mui/icons-material/PlayArrow'; // Add this import
import routes from '../../routes/routes';
import { useAuth } from '../../contexts/AuthContext';
import DebugModal from '../DebugModal';

interface NavItem {
  name: string;
  icon: React.ElementType;
  action: () => void;
}

interface NavGroup {
  name: string;
  icon: React.ElementType;
  items: NavItem[];
}

const VerticalNavigation: React.FC = () => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();
  const [isDebugModalOpen, setIsDebugModalOpen] = useState(false);
  const [openGroup, setOpenGroup] = useState<string | null>(null);
  const theme = useTheme();

  const handleNavigation = (path: string) => () => navigate(path);
  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleGroupHover = (groupName: string) => {
    setOpenGroup(groupName);
  };

  const handleGroupLeave = () => {
    setOpenGroup(null);
  };

  const navGroups: NavGroup[] = [
    {
      name: 'Data Explorer',
      icon: StorageIcon,
      items: [
        {
          name: 'Report',
          icon: routes.find(route => route.name === 'report')?.icon || TableChartIcon,
          action: handleNavigation('/report'),
        },
        {
          name: 'Specification',
          icon: routes.find(route => route.name === 'specification')?.icon || TableChartIcon,
          action: handleNavigation('/specification'),
        },
      ],
    },
    {
      name: 'Text Analysis',
      icon: TextFieldsIcon,
      items: [
        {
          name: 'Experiments',
          icon: routes.find(route => route.name === 'experiments')?.icon || TableChartIcon,
          action: handleNavigation('/experiments'),
        },
        {
          name: 'Text Analysis',
          icon: routes.find(route => route.name === 'text-analysis')?.icon || TableChartIcon,
          action: handleNavigation('/text-analysis'),
        },
        {
          name: 'CoPilot',
          icon: routes.find(route => route.name === 'copilot')?.icon || TableChartIcon,
          action: handleNavigation('/copilot'),
        },
        {
          name: 'Runs',
          icon: routes.find(route => route.name === 'runs')?.icon || PlayArrowIcon, // Use PlayArrowIcon here
          action: handleNavigation('/runs'),
        },
      ],
    },
  ];

  const otherNavItems: NavItem[] = [
    {
      name: 'Home',
      icon: HomeIcon,
      action: handleNavigation('/'),
    },
    {
      name: 'HTML Table',
      icon: TableChartIcon,
      action: handleNavigation('/html-table'),
    },
    {
      name: 'Debug',
      icon: SettingsIcon,
      action: () => setIsDebugModalOpen(true),
    },
    {
      name: isAuthenticated ? 'Logout' : 'Login',
      icon: isAuthenticated ? LogoutIcon : LoginIcon,
      action: isAuthenticated ? handleLogout : handleNavigation('/login'),
    },
  ];

  const commonListItemStyles = {
    padding: 0,
    '&:hover': {
      '& .nav-item-wrapper': {
        transform: 'translateX(20px)',
        zIndex: 1,
        width: 'auto',
        borderRadius: '4px',
      },
      '& .MuiListItemText-root': {
        display: 'flex',
      },
    },
  };

  const navItemWrapperStyles = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
    height: '48px',
    transition: 'all 0.3s ease-in-out',
    backgroundColor: '#FFFFFF',
    padding: '0 16px',
    borderRadius: '0 4px 4px 0',
    border: '1px solid',
    borderImageSlice: 1,
    borderImageSource: 'linear-gradient(to right, #FFFFFF, #E0E0E0)',
    '&:hover': {
      borderImageSource: 'linear-gradient(to right, #FFFFFF, #E0E0E0)',
      borderRadius: '0 4px 4px 0',
    },
  };

  const iconStyles = {
    minWidth: 'auto',
    marginRight: '12px',
  };

  const textStyles = {
    display: 'none',
    alignItems: 'center',
    height: '100%',
    whiteSpace: 'nowrap',
    '& .MuiTypography-root': {
      fontSize: '1rem',
      fontWeight: 'bold',
      lineHeight: 1,
    },
  };

  const groupStyles = {
    ...commonListItemStyles,
    '&:hover': {
      ...commonListItemStyles['&:hover'],
      '& .MuiCollapse-root': {
        display: 'block',
        position: 'absolute',
        left: '100%',
        top: '0',
        width: 'auto',
        zIndex: 2,
        backgroundColor: '#FFFFFF',
        borderRadius: '0 4px 4px 0',
        border: '1px solid',
        borderImageSlice: 1,
        borderImageSource: 'linear-gradient(to right, #E0E0E0, #FFFFFF)',
        borderLeft: 'none',
      },
    },
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 70,
        flexShrink: 0,
        overflow: 'visible',
        [`& .MuiDrawer-paper`]: { 
          width: 70, 
          boxSizing: 'border-box', 
          overflow: 'visible',
          backgroundColor: '#FFFFFF',
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', py: 2, height: '90px', backgroundColor: '#FFFFFF' }}>
        &nbsp;
      </Box>
      <List sx={{ backgroundColor: '#FFFFFF', padding: 0 }}>
        {navGroups.map((group) => (
          <Box
            key={group.name}
            onMouseEnter={() => handleGroupHover(group.name)}
            onMouseLeave={handleGroupLeave}
            sx={{
              backgroundColor: '#FFFFFF'
            }}
          >
            <ListItem sx={groupStyles}>
              <Box className="nav-item-wrapper" sx={navItemWrapperStyles}>
                <ListItemIcon sx={iconStyles}>
                  <group.icon />
                </ListItemIcon>
                <ListItemText
                  primary={group.name}
                  sx={textStyles}
                />
              </Box>
            </ListItem>
            <Collapse in={openGroup === group.name} timeout="auto" unmountOnExit>
              <List component="div" disablePadding sx={{ backgroundColor: '#FFFFFF' }}>
                {group.items.map((item) => (
                  <ListItem
                    button
                    key={item.name}
                    onClick={item.action}
                    sx={commonListItemStyles}
                  >
                    <Box className="nav-item-wrapper" sx={navItemWrapperStyles}>
                      <ListItemIcon sx={iconStyles}>
                        <item.icon />
                      </ListItemIcon>
                      <ListItemText
                        primary={item.name}
                        sx={textStyles}
                      />
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </Box>
        ))}
        {otherNavItems.map((item) => (
          <ListItem
            button
            key={item.name}
            onClick={item.action}
            sx={commonListItemStyles}
          >
            <Box className="nav-item-wrapper" sx={navItemWrapperStyles}>
              <ListItemIcon sx={iconStyles}>
                <item.icon />
              </ListItemIcon>
              <ListItemText
                primary={item.name}
                sx={textStyles}
              />
            </Box>
          </ListItem>
        ))}
      </List>
      <DebugModal open={isDebugModalOpen} onClose={() => setIsDebugModalOpen(false)} />
    </Drawer>
  );
};

export default VerticalNavigation;