import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { AuthContextType } from '../types/types';

export const useCarbonClient = () => {
  const context = useContext(AuthContext) as AuthContextType;
  if (context === undefined) {
    throw new Error('useCarbonClient must be used within an AuthProvider');
  }
  return context.carbonClient;
};