import React from 'react'
import { Container, Paper, Box } from '@mui/material'
import LoginComponent from '../components/common/Login'
import { useRouter } from 'react-router5'
import { useAuth } from '../contexts/AuthContext'
import { useEffect } from 'react'

const Login: React.FC = () => {
  const { sessionInfo } = useAuth()
  const router = useRouter()

  // Redirect if user is already logged in
  useEffect(() => {
    if (sessionInfo) {
      router.navigate('dashboard')
    }
  }, [sessionInfo, router])

  // Render login form
  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper elevation={3} sx={{ padding: 4, width: '100%' }}>
          <LoginComponent />
        </Paper>
      </Box>
    </Container>
  )
}

export default Login